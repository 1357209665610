import { IAppState } from '@app/store';
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { Dispatch } from 'redux';
import { IFormValueCreateChild } from '../../../shared-components/create-parent/create-parent.types';
import { changeScreen, createChild } from '../workshop-enrolment.actions';
import { PATH } from '../workshop-enrolment.constants';
import { selectState } from '../workshop-enrolment.selectors';
import { IChangeScreen } from '../workshop-enrolment.types';
import withValidation from '../../../components/with-validation/with-validation.hoc';
import CreateChildForm from '../../../shared-components/create-child/create-child.component';
import { IParent } from 'shared-components/parent-search/parent-search.types';

interface IDispatchProps {
  changeScreen: (params: IChangeScreen) => void;
  createChild: (formValue: IFormValueCreateChild) => void;
}

interface IStateProps {
  isSubmitting: boolean;
  selectedParent: IParent;
}

class CreateChild extends Component<IDispatchProps & IStateProps> {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Create child - Workshop Enrolments - Booking Autopilot</title>
        </Helmet>
        <div className="d-flex flex-row align-items-center">
          <div className="d-flex flex-row align-items-center flex-grow-1 mt-3 mb-2">
            <h4 className="mr-1">New Child</h4>
          </div>
        </div>

        <CreateChildForm
          onCancel={() => this.props.changeScreen({ path: PATH.PARENT_DETAIL })}
          onCreateChild={this.props.createChild}
          selectedParent={this.props.selectedParent}
          isSubmitting={this.props.isSubmitting}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  changeScreen: (params) => dispatch(changeScreen(params)),
  createChild: (formValue) => dispatch(createChild(formValue)),
});

const mapStateToProps = (state: IAppState): IStateProps => {
  const screenState = selectState(state).screens.createChild;

  return {
    isSubmitting: screenState.isSubmitting,
    selectedParent: selectState(state).selectedParent,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withValidation(
    CreateChild,
    (props) => Boolean(props.selectedParent),
    () => <Redirect to={PATH.PARENT_SEARCH} />,
  ),
);
