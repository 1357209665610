import { formatCurrency } from '@app/helpers/number.helpers';
import { IAppState } from '@app/store';
import { IPayment, IWorkshop, IWorkshopSession } from '@app/types';
import React from 'react';
import { Button, InputGroup, Spinner, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { Dispatch } from 'redux';
import { PATH } from '../workshop-enrolment.constants';
import { selectState } from '../workshop-enrolment.selectors';
import withValidation from '../../../components/with-validation/with-validation.hoc';
import { IParent, IChild } from '../../../shared-components/parent-search/parent-search.types';
import { groupBy } from '@app/helpers/common.helpers';
import Moment from 'moment-timezone';
import { applyPromocode, removePromocode, startBooking } from '../workshop-enrolment.actions';

interface IDispatchProps {
  startBooking: () => void;
  applyPromocode: (promocode: string) => void;
  removePromocode: (promocode: string) => void;
}

interface IStateProps {
  selectedParent: IParent;
  selectedChild: IChild;
  selectedWorkshop: IWorkshop;
  selectedSessions: IWorkshopSession[],
  bookingLoading: boolean;
  appliedPromoCodes: string[];
  payment: IPayment;
  promocodeProcessing: boolean;
}

interface IState {
  promoCode: string;
}

class BookingSummary extends React.Component<IDispatchProps & IStateProps, IState> {
  state: IState = {
    promoCode: '',
  };

  componentDidMount() {
    this.props.startBooking();
  }

  handleChangePromoCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      promoCode: e.target.value,
    });
  }

  handleApplyPromoCode = () => {
    if (this.state.promoCode) {
      this.props.applyPromocode(this.state.promoCode);
    }
  }

  handleRemovePromoCode = () => {
    if (this.props.appliedPromoCodes.length) {
      this.props.removePromocode(this.props.appliedPromoCodes[0]);
      this.setState({
        promoCode: '',
      });
    }
  }

  renderLoading() {
    return (
      <div className="d-flex align-items-center justify-content-center h-100">
        <Spinner animation="border" />
      </div>
    );
  }

  renderPriceBreakdown() {
    const { payment, promocodeProcessing } = this.props;

    if (!payment) {
      return null;
    }

    return (
      <React.Fragment>
        <h5 className="text-uppercase">Price breakdown</h5>
        <Table>
          <thead>
            <tr className="bg-light">
              <th>Sessions</th>
              <th className="text-right">Price</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{payment.quantity}</td>
              <td className="text-right">{formatCurrency(payment.subtotal)}</td>
            </tr>
            {(Boolean(payment.discount) || this.props.appliedPromoCodes.length > 0) && (
              <tr>
                <td>Discount</td>
                <td className="text-right">{formatCurrency(payment.discount)}</td>
              </tr>
            )}
            <tr>
              <td className="font-weight-bold">Total</td>
              <td className="text-right font-weight-bold">{formatCurrency(payment.total - payment.paymentMethodFee)}</td>
            </tr>
          </tbody>
        </Table>
        {this.props.appliedPromoCodes.length === 0 && (
          <div className="d-flex flex-row align-items-center justify-content-between">
            <span>Do you have a promotional code?</span>
            <InputGroup style={{ maxWidth: 240 }}>
              <input
                className="form-control"
                value={this.state.promoCode}
                onChange={this.handleChangePromoCode}
              />
              <Button onClick={this.handleApplyPromoCode} disabled={promocodeProcessing}>
                {promocodeProcessing ? <Spinner size="sm" animation="border" /> : 'Apply'}
              </Button>
            </InputGroup>
          </div>
        )}
        {this.props.appliedPromoCodes.length > 0 && (
          <div className="d-flex flex-row align-items-center justify-content-between">
            <span className="text-success">Promocode applied</span>
            <InputGroup style={{ maxWidth: 240 }}>
              <input
                className="form-control"
                disabled
                value={this.props.appliedPromoCodes[0]}
              />
              <Button variant="danger" onClick={this.handleRemovePromoCode} disabled={promocodeProcessing}>
                {promocodeProcessing ? <Spinner size="sm" animation="border" /> : <span className="mdi mdi-close"></span>}
              </Button>
            </InputGroup>
          </div>
        )}
      </React.Fragment>
    );
  }

  render() {
    const { selectedParent, selectedChild, selectedWorkshop, selectedSessions, bookingLoading } = this.props;
    const sessionsByWeek = groupBy(selectedSessions, 'weekId');

    return (
      <React.Fragment>
        <div className="card card-body mt-3">
          <div className="row">
            <div className="col-md-6">
              <h5 className="text-uppercase">Booking summary</h5>
              <Table className="ml-n2">
                <tbody>
                  <tr>
                    <th className="border-top-0">Child</th>
                    <td className="border-top-0">{selectedChild.fullName}</td>
                  </tr>
                  <tr>
                    <th className="border-top-0">Parent</th>
                    <td className="border-top-0">{selectedParent.fullName}</td>
                  </tr>
                  <tr>
                    <th className="border-top-0">Workshop sessions</th>
                    <td className="border-top-0">
                      {selectedWorkshop.name}
                      {Object.keys(sessionsByWeek).map(weekId => {
                        const week = selectedWorkshop && selectedWorkshop.weeks.find(x => x.id === Number(weekId));
                        const sessions = sessionsByWeek[weekId];
                        return week ? <div>
                          <div><span>{week.name}</span> <span className='text-muted'>{`(${Moment(week.startDate).format('ll')} - ${Moment(week.endDate).format('ll')})`}</span></div>
                          <Table>
                            <tbody>
                              {sessions.map(session => <tr key={session.id}>
                                <td className="border-top-0 p-1">{Moment(session.start).format('ddd, Do')}</td>
                                <td className="border-top-0 p-1">{`${Moment(session.start).format('LT')} - ${Moment(session.end).format('LT')}`}</td>
                              </tr>)}
                            </tbody>
                          </Table>
                        </div> : null;
                      })}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <div className="col-md-6">
              {bookingLoading && this.renderLoading()}
              {!bookingLoading && this.renderPriceBreakdown()}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  startBooking: () => dispatch(startBooking()),
  applyPromocode: (promocode) => dispatch(applyPromocode(promocode)),
  removePromocode: (promocode) => dispatch(removePromocode(promocode)),
});

const mapStateToProps = (state: IAppState): IStateProps => ({
  selectedParent: selectState(state).selectedParent,
  selectedChild: selectState(state).selectedChild,
  selectedWorkshop: selectState(state).selectedWorkshop,
  selectedSessions: selectState(state).selectedSessions,
  bookingLoading: selectState(state).screens.bookingSummary.bookingLoading,
  appliedPromoCodes: (selectState(state).payment && selectState(state).payment.promoCodes) || [],
  payment: selectState(state).payment,
  promocodeProcessing: selectState(state).screens.bookingSummary.promocodeProcessing,
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withValidation(
    BookingSummary,
    (props) => Boolean(props.selectedParent) &&
      Boolean(props.selectedChild) &&
      Boolean(props.selectedWorkshop) &&
      Boolean(props.selectedSessions) && props.selectedSessions.length > 0,
    () => <Redirect to={PATH.PARENT_SEARCH} />,
  ),
);
