import { createAction } from 'redux-actions';

import {
  IFormValueParentSearch,
  IParent
} from './parent-search.types';

import { IPaginationResponse } from 'types/common';

const ACTION_PREFIX = '@shared/parent-search';
const getActionName = (name: string) => `${ACTION_PREFIX}/${name}`;

export const searchParent = createAction<IFormValueParentSearch>(getActionName('SEARCH_PARENT'));
export const searchParentSuccess = createAction<IPaginationResponse<IParent>>(getActionName('SEARCH_PARENT_SUCCESS'));
export const searchParentMore = createAction(getActionName('SEARCH_PARENT_MORE'));
export const searchParentMoreSuccess = createAction<IPaginationResponse<IParent>>(getActionName('SEARCH_PARENT_MORE_SUCCESS'));
