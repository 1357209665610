export const PATH = {
  PARENT_SEARCH: '/manual-enrolment',
  CREATE_PARENT: '/manual-enrolment/parent/create',
  PARENT_DETAIL: '/manual-enrolment/parent/:parentId',
  CREATE_CHILD: '/manual-enrolment/parent/:parentId/child/create',
  CHOOSE_CLASS: '/manual-enrolment/parent/:parentId/child/:childId/programs',
  LESSON_DATES: '/manual-enrolment/parent/:parentId/child/:childId/program/:programId',
  BOOKING_SUMMARY: '/manual-enrolment/parent/:parentId/child/:childId/program/:programId/:startDate/:paymentTypeId',
};

export const PATH_PARENT = {
  [PATH.PARENT_SEARCH]: null,
  [PATH.CREATE_PARENT]: PATH.PARENT_SEARCH,
  [PATH.PARENT_DETAIL]: PATH.PARENT_SEARCH,
  [PATH.CREATE_CHILD]: PATH.PARENT_DETAIL,
  [PATH.CHOOSE_CLASS]: PATH.PARENT_DETAIL,
  [PATH.LESSON_DATES]: PATH.CHOOSE_CLASS,
  [PATH.BOOKING_SUMMARY]: PATH.LESSON_DATES,
};
