import React, { Component } from 'react';
import { connect } from 'react-redux';
import { selectState } from './workshop-enrolment.selectors';
import { IAppState } from '@app/store';
import { Redirect, Route, Switch } from 'react-router-dom';
import createChildComponent from '../workshop-enrolment/create-child/create-child.component';
import createParentComponent from '../workshop-enrolment/create-parent/create-parent.component';
import chooseWorkshopComponent from './choose-workshop/choose-workshop.component';
import workshopSessionsComponent from './workshop-sessions/workshop-sessions.component';
import LoadingOverlay from '../../shared-components/loading-overlay/loading-overlay.component';
import { PATH } from './workshop-enrolment.constants';
import parentDetailComponent from './parent-details/parent-details.component';
import parentSearchComponent from './parent-search/parent-search.component';
import stepPaymentComponent from '../workshop-enrolment/step-payment/step-payment.component';
import withInitLoader from './with-init-loader/with-init-loader.hoc';
import Stepper from './stepper/stepper.component';

const ParentSearch = withInitLoader(parentSearchComponent);
const CreateParent = withInitLoader(createParentComponent);
const ParentDetail = withInitLoader(parentDetailComponent);
const CreateChild = withInitLoader(createChildComponent);
const ChooseWorkshop = withInitLoader(chooseWorkshopComponent);
const WorkshopSessions = withInitLoader(workshopSessionsComponent);
const BookingSummary = withInitLoader(stepPaymentComponent);

interface IStateProps {
  loading: boolean;
}

class WorkshopEnrolmentComponent extends Component<IStateProps> {
  render() {
    return (
      <div className="container-fluid pt-2 d-flex flex-column">
        <Stepper />
        <Switch>
          <Route exact path={PATH.PARENT_SEARCH} component={ParentSearch} />
          <Route exact path={PATH.CREATE_PARENT} component={CreateParent} />
          <Route exact path={PATH.PARENT_DETAIL} component={ParentDetail} />
          <Route exact path={PATH.CREATE_CHILD} component={CreateChild} />
          <Route exact path={PATH.CHOOSE_WORKSHOP} component={ChooseWorkshop} />
          <Route exact path={PATH.WORKSHOP_SESSIONS} component={WorkshopSessions} />
          <Route exact path={PATH.BOOKING_SUMMARY} component={BookingSummary} />
          <Route component={() => <Redirect to={PATH.PARENT_SEARCH} />} />
        </Switch>
        <LoadingOverlay visible={this.props.loading} />
      </div>
    );
  }
}

const mapStateToProps = (state: IAppState): IStateProps => ({
  loading: selectState(state).loading,
});

export default connect(mapStateToProps)(WorkshopEnrolmentComponent);
