import { fetchFranchisorSuccess as authFetchFranchisorSuccess } from 'actions/auth';
import { createAction } from 'redux-actions';
import { IAppData, IFetchFranchisorParam, IFranchisor } from 'types';

const ACTION_PREFIX = '@screen/login-organization';
const getActionName = (name: string) => `${ACTION_PREFIX}/${name}`;

export const fetchFranchisor = createAction<IFetchFranchisorParam>(getActionName('FETCH_FRANCHISOR'));
export const fetchFranchisorSuccess = createAction<IAppData<IFranchisor>>(authFetchFranchisorSuccess.toString());
export const fetchFranchisorFailed = createAction<string>(getActionName('FETCH_FRANCHISOR_FAILED'));
export const clearErrorMessage = createAction(getActionName('CLEAR_ERROR_MESSAGE'));
