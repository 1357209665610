import React, { Component } from 'react';

import { Badge, Button } from 'components';
import { Accordion } from 'react-bootstrap';
import styles from './program-attendance-list-item.module.scss';

type Icon = 'drop' | 'bus';

interface IProps {
  name: string;
  parentName: string;
  labels: string[];
  icons: Icon[];
  photoConsent: boolean;
  completed: boolean;
  onToggleAttendance?: () => void;
}

interface IState {
  expanded: boolean;
}

const labelVariantMap = {
  pending: 'warning',
  makeup: 'primary',
  new: 'warning',
  trial: 'primary',
  unpaid: 'danger',
};

const iconMap = {
  'drop': 'mdi mdi-water-outline',
  'bus': 'mdi mdi-bus-school',
};

class ProgramAttendanceListItem extends Component<IProps, IState> {
  defaultProps: IProps = {
    name: '',
    parentName: '',
    photoConsent: false,
    icons: [],
    labels: [],
    completed: false,
  };

  state: IState = {
    expanded: false,
  };

  handleToggle = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  }

  renderNameBlock() {
    const { name, labels } = this.props;

    return (
      <div className="d-flex col flex-column p-0">
        <p className="h4 m-0 font-weight-normal">{name}</p>
        <div>
          {labels.map(status => (
            <Badge variant={labelVariantMap[status.toLowerCase()]} className={`p-1 mr-1 ${styles.label}`} key={status}>{status}</Badge>
          ))}
        </div>
      </div>
    );
  }

  renderParentBlock() {
    const { parentName } = this.props;

    return (
      <div className="d-flex col flex-column p-0">
        <p className="h4 m-0 font-weight-normal">{parentName}</p>
        <div className="text-muted">Parent Name</div>
      </div>
    );
  }

  renderPhotoConsentBlock() {
    const { photoConsent } = this.props;

    return (
      <div className="d-flex col flex-column p-0">
        <p className="h4 m-0 font-weight-normal">{photoConsent ? 'Yes' : 'No'}</p>
        <div className="text-muted">Photo Consent</div>
      </div>
    );
  }

  renderIconBlock() {
    const { icons } = this.props;

    return (
      <div className="d-flex col p-0 flex-row align-items-center">
        {icons.map((icon, index) => (
          <p key={String(index)} className={`text-muted h2 ${iconMap[icon]}`}></p>
        ))}
      </div>
    );
  }

  render() {
    const { completed } = this.props;

    return (
      <div className="d-flex flex-row align-items-center mt-2 border rounded p-2 position-relative" style={{ overflow: 'hidden' }}>
        <div className="d-inline-flex flex-row align-items-center flex-wrap flex-md-nowrap col p-0">
          <div className="d-flex flex-row align-items-center col-md-6 col-xs-12 p-0">
            {this.renderNameBlock()}
            {this.renderParentBlock()}
          </div>
          <div className="flex-row align-items-center col-md-6 col-xs-12 p-0 mt-1 mt-md-0 d-none d-md-flex">
            {this.renderPhotoConsentBlock()}
            {this.renderIconBlock()}
          </div>
          <Accordion.Collapse eventKey={null} in={this.state.expanded} style={{ width: '100%' }}>
            <div className="flex-row align-items-center col-md-6 col-xs-12 p-0 mt-1 mt-md-0 d-flex d-md-none">
              {this.renderPhotoConsentBlock()}
              {this.renderIconBlock()}
            </div>
          </Accordion.Collapse>
        </div>
        <div className="d-flex align-self-start align-start-md-center">
          {!completed && (
            <Button variant="light" className={`rounded-circle ${styles.btnIconRound}`} onClick={this.props.onToggleAttendance}>
              <i className="h4 mdi mdi-account-outline m-0 text-primary"></i>
            </Button>
          )}
          {completed && (
            <Button variant="success" className={`rounded-circle ${styles.btnIconRound}`} onClick={this.props.onToggleAttendance}>
              <i className="h4 mdi mdi-check m-0"></i>
            </Button>
          )}
        </div>
        <Button
          variant="light"
          className={`d-flex align-items-center justify-content-center rounded-circle position-absolute d-md-none`}
          style={{ width: 40, height: 40, bottom: -20, left: 'calc(50% - 20px)', color: 'white' }}
          onClick={this.handleToggle}
        >
          <i className="h3 mdi mdi-chevron-down" style={{ margin: 0, marginTop: -16 }}></i>
        </Button>
      </div >
    );
  }
}

export default ProgramAttendanceListItem;
