import { DateType } from './types';

export interface IWorkshop {
  id: number;
  name: string;
  shortDescription: string;
  description: string;
  startDate: DateType;
  endDate: DateType,
  weeks: IWorkshopWeek[]
}

export interface IWorkshopWeek {
  id: number;
  name: string;
  startDate: DateType;
  endDate: DateType;
}

export interface IWorkshopSession {
  id: number;
  weekId: number;
  type: WorkshopSessionType;
  start: DateType;
  end: DateType;
  capacity: number;
  registeredChildrenCount: number;
  totalAttendingChildrenCount: number;
  totalCapacity: number
}

export enum WorkshopSessionType {
  Morning = 'Morning',
  Afternoon = 'Afternoon',
  FullDay = 'FullDay'
}

export enum Availability {
  Yes = 'Yes',
  No = 'No',
  Limited = 'Limited',
  LastSpot = 'LastSpot'
}