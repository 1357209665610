import ReducerFactory from '@app/reducers/ReducerFactory';
import { DateType, IPayment, IPaymentMethod, IWeeklyProgram } from '@app/types';
import { IChild, IChildrenPaymentType, IChildrenPaymentTypeDetail, IParent, IParentSummary, IProgramDate } from './manual-enrolment.types';

import { logout } from '@app/actions/auth';
import {
  applyPromocode,
  applyPromocodeFail,
  applyPromocodeSuccess,
  changeStep,
  createChild,
  // fetchBooking,
  // fetchBookingSuccess,
  createChildFailed,
  createChildSuccess,
  createParent,
  createParentFailed,
  createParentSuccess,
  fetchChilds,
  fetchChildsSuccess,
  fetchLessonDates,
  fetchLessonDatesSuccess,
  fetchPayment,
  fetchPaymentMethods,
  fetchPaymentMethodsSuccess,
  fetchPaymentSuccess,
  fetchWeeklyPrograms,
  fetchWeeklyProgramsSuccess,
  initLoadFail,
  initLoadSuccess,
  makePayment,
  makePaymentSuccess,
  removePromocode,
  removePromocodeFail,
  removePromocodeSuccess,
  selectChild,
  selectDate,
  selectParent,
  selectPaymentMethod,
  selectPaymentType,
  selectProgram,
  setChildAgeError,
  setLessonDatePaymentOptions,
  setLoaderVisibleState,
  setProgramAvailableState,
  setSelectedChild,
  setSelectedDate,
  setSelectedParent,
  setSelectedPaymentType,
  setSelectedProgram,
  startBooking,
  updateCurrentPath,
} from './manual-enrolment.actions';
import { completePaymentSuccess } from '../../pages/payment-callback/payment-callback.actions';

interface IScreenChooseClassState {
  weeklyProgramsLoading: boolean;
  weeklyPrograms: IWeeklyProgram[];
  childAgeError: string;
}

interface IScreenState {
  createParent: {
    isSubmitting: boolean;
  };
  createChild: {
    isSubmitting: boolean;
  };
  parentDetail: {
    loading: boolean;
    childs: IChild[];
    loadingEnrolments: boolean;
  };
  chooseClass: IScreenChooseClassState;
  chooseDate: {
    loading: boolean;
    lessonDates: IProgramDate[];
  };
  bookingSummary: {
    bookingLoading: boolean;
    childrenPaymentTypes: IChildrenPaymentType[];
    paymentMethodLoading: boolean;
    paymentMethods: IPaymentMethod[];
    promocodeProcessing: boolean;
  };
}

export interface IManualEnrolmentState {
  initLoaded: boolean;
  initLoadedFailed: boolean;
  step: number;
  path: string;
  // stepScreenName: StepScreenName;
  selectedParent: IParent;
  selectedParentSummary: IParentSummary;
  // currentEnrolments: IEnrolment[];
  selectedChild: IChild;
  selectedProgram: IWeeklyProgram;
  selectedDate: DateType;
  selectedPaymentType: IChildrenPaymentTypeDetail;
  selectedPaymentMethod: IPaymentMethod;
  programAvailableState?: boolean;

  screens: IScreenState;
  payment: IPayment;
  loading: boolean;
}

const initState: IManualEnrolmentState = {
  initLoaded: false,
  initLoadedFailed: false,
  step: -1,
  path: '',
  // stepScreenName: 'search-parent',
  selectedParent: null,
  selectedParentSummary: null,
  selectedChild: null,
  selectedProgram: null,
  selectedDate: null,
  selectedPaymentType: null,
  selectedPaymentMethod: null,
  programAvailableState: null,
  screens: {
    createParent: {
      isSubmitting: false,
    },
    createChild: {
      isSubmitting: false,
    },
    parentDetail: {
      childs: [],
      loading: false,
      loadingEnrolments: false,
    },
    chooseClass: {
      weeklyPrograms: [],
      weeklyProgramsLoading: false,
      childAgeError: '',
    },
    chooseDate: {
      lessonDates: [],
      loading: false,
    },
    bookingSummary: {
      bookingLoading: false,
      childrenPaymentTypes: [],
      paymentMethodLoading: false,
      paymentMethods: [],
      promocodeProcessing: false,
    },
  },
  payment: null,
  loading: false,
};

const reducer = new ReducerFactory(initState)
  .add(logout, (state, action) => {
    return initState;
  })
  .add(changeStep, (state, action) => {
    return {
      ...state,
      step: action.payload,
    };
  })
  .add(updateCurrentPath, (state, action) => {
    return {
      ...state,
      path: action.payload,
    };
  })
  // .add(changeScreen, (state, action) => {
  //   const screenStepMap: { [key in StepScreenName]: number } = {
  //     'search-parent': 0,
  //     'create-parent': 0,
  //     'create-child': 0,
  //     'parent-detail': 0,
  //     'choose-class': 1,
  //     'choose-date': 1,
  //     'booking-summary': 2,
  //     'card-payment': 2,
  //   };

  //   return {
  //     ...state,
  //     step: screenStepMap[action.payload],
  //     stepScreenName: action.payload,
  //   };
  // })
  .add(fetchWeeklyPrograms, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        chooseClass: {
          ...state.screens.chooseClass,
          weeklyProgramsLoading: true,
        },
      },
    };
  })
  .add(fetchWeeklyProgramsSuccess, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        chooseClass: {
          ...state.screens.chooseClass,
          weeklyPrograms: action.payload,
          weeklyProgramsLoading: false,
        },
      },
      selectedProgram: null,
    };
  })
  .add(fetchChilds, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        parentDetail: {
          ...state.screens.parentDetail,
          loading: true,
        },
      },
    };
  })
  .add(fetchChildsSuccess, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        parentDetail: {
          ...state.screens.parentDetail,
          loading: false,
          childs: action.payload,
        },
      },
    };
  })
  .add(selectParent, (state, action) => {
    return {
      ...state,
      selectedParent: action.payload,
      selectedChild: null,
      screens: {
        ...state.screens,
        parentDetail: {
          ...state.screens.parentDetail,
          childs: (action.payload.children || []).map(x => ({ ...x, parentId: action.payload.id })),
        },
      },
    };
  })
  .add(selectChild, (state, action) => {
    return {
      ...state,
      selectedChild: action.payload,
    };
  })
  .add(selectProgram, (state, action) => {
    return {
      ...state,
      selectedProgram: action.payload,
      programAvailableState: null,
    };
  })
  .add(setProgramAvailableState, (state, action) => {
    return {
      ...state,
      programAvailableState: action.payload,
    };
  })
  .add(selectDate, (state, action) => {
    return {
      ...state,
      selectedDate: action.payload,
    };
  })
  .add(selectPaymentMethod, (state, action) => {
    return {
      ...state,
      selectedPaymentMethod: action.payload,
      loading: true,
    };
  })
  .add(fetchPaymentMethods, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        bookingSummary: {
          ...state.screens.bookingSummary,
          paymentMethodLoading: true,
        },
      },
    };
  })
  .add(fetchPaymentMethodsSuccess, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        bookingSummary: {
          ...state.screens.bookingSummary,
          paymentMethodLoading: false,
          paymentMethods: action.payload,
        },
      },
    };
  })
  .add(fetchLessonDates, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        chooseDate: {
          ...state.screens.chooseDate,
          loading: true,
        },
      },
    };
  })
  .add(fetchLessonDatesSuccess, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        chooseDate: {
          ...state.screens.chooseDate,
          loading: false,
          lessonDates: action.payload,
        },
      },
    };
  })
  .add(createChild, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        createChild: {
          ...state.screens.createChild,
          isSubmitting: true,
        },
      },
    };
  })
  .add(createChildSuccess, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        createChild: {
          ...state.screens.createChild,
          isSubmitting: false,
        },
      },
      selectedChild: action.payload,
    };
  })
  .add(createChildFailed, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        createChild: {
          ...state.screens.createChild,
          isSubmitting: false,
        },
      },
    };
  })
  // .add(fetchBooking, (state, action) => {
  //   return {
  //     ...state,
  //     screens: {
  //       ...state.screens,
  //       bookingSummary: {
  //         ...state.screens.bookingSummary,
  //         bookingLoading: true,
  //         childrenPaymentTypes: [],
  //       },
  //     },
  //   };
  // })
  // .add(fetchBookingSuccess, (state, action) => {
  //   return {
  //     ...state,
  //     screens: {
  //       ...state.screens,
  //       bookingSummary: {
  //         ...state.screens.bookingSummary,
  //         bookingLoading: false,
  //         childrenPaymentTypes: action.payload,
  //       },
  //     },
  //   };
  // })
  .add(startBooking, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        bookingSummary: {
          ...state.screens.bookingSummary,
          bookingLoading: true,
        },
      },
    };
  })
  .add(fetchPayment, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  })
  .add(fetchPaymentSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      payment: action.payload,
      screens: {
        ...state.screens,
        bookingSummary: {
          ...state.screens.bookingSummary,
          bookingLoading: false,
        },
      },
    };
  })
  .add(makePayment, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  })
  .add(makePaymentSuccess, (state, action) => {
    return {
      ...initState,
    };
  })
  .add(completePaymentSuccess, (state, action) => {
    return {
      ...initState,
    };
  })
  .add(applyPromocode, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        bookingSummary: {
          ...state.screens.bookingSummary,
          promocodeProcessing: true,
        },
      },
    };
  })
  .add(applyPromocodeSuccess, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        bookingSummary: {
          ...state.screens.bookingSummary,
          promocodeProcessing: false,
        },
      },
      payment: {
        ...state.payment,
        promoCodes: [action.payload],
      },
    };
  })
  .add(applyPromocodeFail, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        bookingSummary: {
          ...state.screens.bookingSummary,
          promocodeProcessing: false,
        },
      },
    };
  })
  .add(removePromocode, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        bookingSummary: {
          ...state.screens.bookingSummary,
          promocodeProcessing: true,
        },
      },
    };
  })
  .add(removePromocodeSuccess, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        bookingSummary: {
          ...state.screens.bookingSummary,
          promocodeProcessing: false,
        },
      },
      payment: {
        ...state.payment,
        promoCodes: [],
      },
    };
  })
  .add(removePromocodeFail, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        bookingSummary: {
          ...state.screens.bookingSummary,
          promocodeProcessing: false,
        },
      },
    };
  })
  .add(setLoaderVisibleState, (state, action) => {
    return {
      ...state,
      loading: action.payload,
    };
  })
  .add(setLessonDatePaymentOptions, (state, action) => {
    const lessonDates = state.screens.chooseDate.lessonDates;

    const lessonDate = lessonDates.find(x => x.date === action.payload.date);
    lessonDate.childrenPaymentTypes = action.payload.childrenPaymentTypes;
    lessonDate.notAvailable = action.payload.notAvailable;
    lessonDate.message = action.payload.message;

    return {
      ...state,
      screens: {
        ...state.screens,
        chooseDate: {
          ...state.screens.chooseDate,
          lessonDates: [...lessonDates],
        },
      },
    };
  })
  .add(selectPaymentType, (state, action) => {
    return {
      ...state,
      selectedPaymentType: action.payload,
    };
  })
  .add(initLoadSuccess, (state, action) => {
    return {
      ...state,
      initLoaded: true,
      initLoadedFailed: false,
    };
  })
  .add(initLoadFail, (state, action) => {
    return {
      ...state,
      initLoadedFailed: true,
      selectedParent: null,
      selectedChild: null,
      selectedProgram: null,
      selectedDate: null,
      selectedPaymentType: null,
    };
  })
  .add(setSelectedParent, (state, action) => {
    return {
      ...state,
      selectedParent: action.payload,
    };
  })
  .add(setSelectedChild, (state, action) => {
    return {
      ...state,
      selectedChild: action.payload,
    };
  })
  .add(setSelectedProgram, (state, action) => {
    return {
      ...state,
      selectedProgram: action.payload,
    };
  })
  .add(setSelectedDate, (state, action) => {
    return {
      ...state,
      selectedDate: action.payload,
    };
  })
  .add(setSelectedPaymentType, (state, action) => {
    return {
      ...state,
      selectedPaymentType: action.payload,
    };
  })
  .add(createParent, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        createParent: {
          ...state.screens.createParent,
          isSubmitting: true,
        },
      },
    };
  })
  .add(createParentSuccess, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        createParent: {
          ...state.screens.createParent,
          isSubmitting: false,
        },
      },
    };
  })
  .add(createParentFailed, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        createParent: {
          ...state.screens.createParent,
          isSubmitting: false,
        },
      },
    };
  })
  .add(setChildAgeError, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        chooseClass: {
          ...state.screens.chooseClass,
          childAgeError: action.payload,
        },
      },
    };
  })
  .toReducer();

export default reducer;
