export const PATH = {
  PARENT_SEARCH: '/workshop-enrolment',
  CREATE_PARENT: '/workshop-enrolment/parent/create',
  PARENT_DETAIL: '/workshop-enrolment/parent/:parentId',
  CREATE_CHILD: '/workshop-enrolment/parent/:parentId/child/create',
  CHOOSE_WORKSHOP: '/workshop-enrolment/parent/:parentId/child/:childId/workshops',
  WORKSHOP_SESSIONS: '/workshop-enrolment/parent/:parentId/child/:childId/workshops/:workshopId/sessions',
  BOOKING_SUMMARY: '/workshop-enrolment/parent/:parentId/child/:childId/workshops/:workshopId/sessions/:sessionIds/summary',
};

export const PATH_PARENT = {
  [PATH.PARENT_SEARCH]: null,
  [PATH.CREATE_PARENT]: PATH.PARENT_SEARCH,
  [PATH.PARENT_DETAIL]: PATH.PARENT_SEARCH,
  [PATH.CREATE_CHILD]: PATH.PARENT_DETAIL,
  [PATH.CHOOSE_WORKSHOP]: PATH.PARENT_DETAIL,
  [PATH.WORKSHOP_SESSIONS]: PATH.CHOOSE_WORKSHOP,
  [PATH.BOOKING_SUMMARY]: PATH.WORKSHOP_SESSIONS,
};
