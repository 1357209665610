import React, { Component } from 'react';
import { connect } from 'react-redux';
import { selectState } from './manual-enrolment.selectors';
import { IAppState } from '@app/store';
import { Redirect, Route, Switch } from 'react-router-dom';
import chooseClassComponent from './choose-class/choose-class.component';
import createChildComponent from './create-child/create-child.component';
import createParentComponent from './create-parent/create-parent.component';
import lessonDatesComponent from './lesson-dates/lesson-dates.component';
import LoadingOverlay from '../../shared-components/loading-overlay/loading-overlay.component';
import { PATH } from './manual-enrolment.constants';
import parentDetailComponent from './parent-details/parent-details.component';
import parentSearchComponent from './parent-search/parent-search.component';
import stepPaymentComponent from './step-payment/step-payment.component';
import Stepper from './stepper/stepper.component';
import withInitLoader from './with-init-loader/with-init-loader.hoc';

const ParentSearch = withInitLoader(parentSearchComponent);
const CreateParent = withInitLoader(createParentComponent);
const ParentDetail = withInitLoader(parentDetailComponent);
const CreateChild = withInitLoader(createChildComponent);
const ChooseClass = withInitLoader(chooseClassComponent);
const LessonDates = withInitLoader(lessonDatesComponent);
const BookingSummary = withInitLoader(stepPaymentComponent);

interface IStateProps {
  loading: boolean;
}

class ManualEnrolmentComponent extends Component<IStateProps> {
  render() {
    return (
      <div className="container-fluid pt-2 d-flex flex-column">
        <Stepper />
        <Switch>
          <Route exact path={PATH.PARENT_SEARCH} component={ParentSearch} />
          <Route exact path={PATH.CREATE_PARENT} component={CreateParent} />
          <Route exact path={PATH.PARENT_DETAIL} component={ParentDetail} />
          <Route exact path={PATH.CREATE_CHILD} component={CreateChild} />
          <Route exact path={PATH.CHOOSE_CLASS} component={ChooseClass} />
          <Route exact path={PATH.LESSON_DATES} component={LessonDates} />
          <Route exact path={PATH.BOOKING_SUMMARY} component={BookingSummary} />
          <Route component={() => <Redirect to={PATH.PARENT_SEARCH} />} />
        </Switch>
        <LoadingOverlay visible={this.props.loading} />
      </div>
    );
  }
}

const mapStateToProps = (state: IAppState): IStateProps => ({
  loading: selectState(state).loading,
});

export default connect(mapStateToProps)(ManualEnrolmentComponent);
