import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Redirect } from 'react-router';
import { changeScreen, selectChild } from '../manual-enrolment.actions';
import analytics from '@app/helpers/analytics.helper';
import { IChangeScreen } from '../manual-enrolment.types';
import { IParent } from '../../../shared-components/parent-search/parent-search.types';
import { IChild } from '../../../shared-components/parent-details/parent-details.types';
import { selectState } from '../manual-enrolment.selectors';
import { PATH } from '../manual-enrolment.constants';
import { IAppState } from '@app/store';
import withValidation from '../../../components/with-validation/with-validation.hoc';

import ParentDetails from 'shared-components/parent-details/parent-details.component';

interface IDispatchProps {
    changeScreen: (params: IChangeScreen) => void;
    selectChild: (child: IChild) => void;
    createChild: () => void;
}

interface IStateProps {
    selectedParent: IParent;
}

class StepEnrolChild extends Component<IDispatchProps & IStateProps> {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Workshop Enrolments - Booking Autopilot</title>
                </Helmet>
                <div className="card card-body">
                    <ParentDetails selectedParent={this.props.selectedParent} onSelectChild={this.props.selectChild} onCreateChild={this.props.createChild} />
                </div>

            </React.Fragment>
        );
    }
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
    changeScreen: (params) => dispatch(changeScreen(params)),

    selectChild: (child: IChild) => {
        analytics.event('select_child', {
            childId: child.id,
        });
        dispatch(selectChild(child));
        dispatch(changeScreen({ path: PATH.CHOOSE_CLASS, params: { parentId: child.parentId, childId: child.id } }));
    },
    createChild: () => dispatch(changeScreen({ path: PATH.CREATE_CHILD }))
});

const mapStateToProps = (state: IAppState): IStateProps => {
    return {
        selectedParent: selectState(state).selectedParent,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(
    withValidation(
        StepEnrolChild,
        (props) => Boolean(props.selectedParent),
        () => <Redirect to={PATH.PARENT_SEARCH} />,
    ),
);
