import ReducerFactory from '@app/reducers/ReducerFactory';
import { IFormValueParentSearch, IParent } from './parent-search.types';
import { logout } from '@app/actions/auth';

import {
  searchParent,
  searchParentMore,
  searchParentMoreSuccess,
  searchParentSuccess,
} from './parent-search.actions';

export interface IParentSearchState {
  formValue: IFormValueParentSearch;
  loading: boolean;
  loadingMore: boolean;
  showResult: boolean;
  result: IParent[];
  total: number;
  currentPage: number;
}

const initState: IParentSearchState = {
  formValue: {
    fullName: '',
    phoneNumber: '',
    child: '',
  },
  loading: false,
  loadingMore: false,
  showResult: false,
  result: [],
  total: 0,
  currentPage: 0,
};

const reducer = new ReducerFactory(initState)
  .add(logout, (state, action) => {
    return initState;
  })
  .add(searchParent, (state, action) => {
    return {
      ...state,
      formValue: action.payload,
      loading: true,
    };
  })
  .add(searchParentSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      result: action.payload.data,
      showResult: true,
      currentPage: 1,
      total: action.payload.totalCount,
    };
  })
  .add(searchParentMore, (state, action) => {
    return {
      ...state,
      loadingMore: true,
    };
  })
  .add(searchParentMoreSuccess, (state, action) => {
    return {
      ...state,
      loadingMore: false,
      result: [...state.result, ...action.payload.data],
      currentPage: state.currentPage + 1,
      total: action.payload.totalCount,
    };
  })
  .toReducer();

export default reducer;
