import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { Dispatch } from 'redux';

import { selectIsAuthenticated } from 'selectors/auth';
import { IAppState } from 'store';

interface IStateProps {
  isAuthenticated: boolean;
}

const PrivateRoute = ({ isAuthenticated, component: Component, ...rest }: RouteProps & IStateProps) => {
  const render = (props: any) => {
    if (!isAuthenticated) {
      let redirectUrl = '/login';
      if (props.location.pathname !== '/' || props.location.search !== '') {
        redirectUrl += '?redirect=' +
          encodeURIComponent(props.location.pathname + props.location.search);
      }
      return <Redirect to={redirectUrl} />;
    }

    return <Component {...props} />;
  };

  return (
    <Route {...rest} render={render} />
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
});

const mapStateToProps = (state: IAppState): IStateProps => ({
  isAuthenticated: selectIsAuthenticated(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
