import { IAppState } from '@app/store';
import React, { useEffect } from 'react';
import { Card, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { RouteChildrenProps } from 'react-router';
import { Link } from 'react-router-dom';
import { compose, Dispatch } from 'redux';
import { changeStep, initLoad, updateCurrentPath } from '../workshop-enrolment.actions';
import { PATH } from '../workshop-enrolment.constants';
import { selectState } from '../workshop-enrolment.selectors';
import { IRouteParams } from '../workshop-enrolment.types';

interface IDispatchProps {
  changeStep: (step: number) => void;
  updateCurrentPath: (path: string) => void;
  initLoad: (param: IRouteParams) => void;
}

interface IStateProps {
  initLoaded: boolean;
  initLoadedFailed: boolean;
}

interface IParam {
  parentId?: string;
  childId?: string;
  workshopId?: string;
  sessionIds?: string;
}

const withInitLoader = (C: React.ComponentClass) => (props: RouteChildrenProps<IParam> & IDispatchProps & IStateProps) => {
  useEffect(() => {
    let step = 0;
    switch (props.match.path) {
      case PATH.PARENT_SEARCH:
      case PATH.CREATE_PARENT:
      case PATH.PARENT_DETAIL:
      case PATH.CREATE_CHILD:
        step = 0;
        break;
      case PATH.CHOOSE_WORKSHOP:
      case PATH.WORKSHOP_SESSIONS:
        step = 1;
        break;
      case PATH.BOOKING_SUMMARY:
        step = 2;
        break;
    }

    props.changeStep(step);
    props.updateCurrentPath(props.match.path);

    if (!props.initLoaded) {
      const { parentId, childId, workshopId, sessionIds } = props.match.params;
      props.initLoad({
        parentId: Number(parentId),
        childId: Number(childId),
        workshopId: Number(workshopId),
        sessionIds: sessionIds && sessionIds.split(',').map(x => Number(x))
      });
    }
  }, [props]);

  if (props.initLoadedFailed) {
    return (
      <Card>
        <Card.Body>
          <h3 className="text-uppercase text-danger">Loading failed</h3>
          <h5 className="text-danger mt-3">Something goes wrong, we are not able to load page at the moment. Try refresh or start new enrolment</h5>
          <div className="text-center">
            <Link to={PATH.PARENT_SEARCH} className="btn btn-info mt-5"><i className="mdi mdi-reply"></i> Start new enrolment</Link>
          </div>
        </Card.Body>
      </Card>
    );
  }

  if (!props.initLoaded) {
    return (
      <div className="card card-body d-flex align-items-center justify-content-center mt-3" style={{ minHeight: 360 }}>
        <Spinner animation="border" />
      </div>
    );
  }

  return (<C />);
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  changeStep: (step) => dispatch(changeStep(step)),
  updateCurrentPath: (path) => dispatch(updateCurrentPath(path)),
  initLoad: (param) => dispatch(initLoad(param)),
});

const mapStateToProps = (state: IAppState): IStateProps => ({
  initLoaded: selectState(state).initLoaded,
  initLoadedFailed: selectState(state).initLoadedFailed,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withInitLoader,
);
