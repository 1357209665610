import { ActionFunctions, handleActions, Reducer, ReducerMap } from 'redux-actions';

class ReducerFactory<S, Payload = never> {
  private reducerMap: ReducerMap<S, Payload>;
  initState: S;

  constructor(initState: S) {
    this.initState = initState;
    this.reducerMap = {};
  }

  public add<P>(actionTypeOrActionCreator: string | ActionFunctions<P>, reducer: Reducer<S, P>): ReducerFactory<S, P | Payload> {
    return this.addReducerInternal(actionTypeOrActionCreator, reducer);
  }

  private addReducerInternal<P>(actionTypeOrActionCreator: string | ActionFunctions<P>, reducer: Reducer<S, P>): ReducerFactory<S, P | Payload> {
    this.reducerMap[actionTypeOrActionCreator.toString()] = reducer as any;
    return this.asAllowingPayload();
  }

  protected asAllowingPayload<P>(): ReducerFactory<S, Payload | P> {
    return this;
  }

  public toReducer(): Reducer<S, Payload> {
    return handleActions<S, Payload>(this.reducerMap, this.initState);
  }
}

export default ReducerFactory;
