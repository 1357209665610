import { logout } from '@app/actions/auth';
import ReducerFactory from 'reducers/ReducerFactory';
import { IChildLesson, IWeeklyProgram } from 'types';
import { fetchAttendanceData, fetchAttendanceSuccess, fetchLessonDatesSuccess, fetchWeeklyProgramSuccess, setLoadingState, toggleAttendanceSuccess } from './manage-attendance.actions';
import { ILessonDates } from './manage-attendance.types';

export interface IManageAttendanceState {
  loading: boolean;
  program: IWeeklyProgram;
  attendance: IChildLesson[];
  lessonDates: ILessonDates;
}

const initState: IManageAttendanceState = {
  loading: true,
  program: null,
  attendance: [],
  lessonDates: null,
};

const reducer = new ReducerFactory(initState)
  .add(logout, (state, action) => {
    return initState;
  })
  .add(setLoadingState, (state, action) => {
    return {
      ...state,
      loading: action.payload,
    };
  })
  .add(fetchAttendanceData, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  })
  .add(fetchAttendanceSuccess, (state, action) => {
    return {
      ...state,
      attendance: action.payload,
    };
  })
  .add(fetchLessonDatesSuccess, (state, action) => {
    return {
      ...state,
      lessonDates: action.payload,
    };
  })
  .add(fetchWeeklyProgramSuccess, (state, action) => {
    return {
      ...state,
      program: action.payload,
    };
  })
  .add(toggleAttendanceSuccess, (state, action) => {
    const attendance = state.attendance;
    const item = attendance.find(x => x.id === action.payload.id);

    if (!item) {
      return state;
    }

    item.isAttended = !item.isAttended;

    return {
      ...state,
      attendance: [...attendance],
    };
  })
  .toReducer();

export default reducer;
