import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { IAppState } from 'store';

import { closeGlobalMessageBox } from 'actions/root';
import { selectGlobalMesasge } from 'selectors/root';
import MessageBox, { IDispatchProps, IStateProps } from './message-box.component';

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  onClose: () => dispatch(closeGlobalMessageBox()),
});

const mapStateToProps = (state: IAppState): IStateProps => ({
  data: selectGlobalMesasge(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageBox);
