import Moment from 'moment-timezone';
import React from 'react';
import { IProgram } from './lesson-attendance.types';

interface IProps {
  program: IProgram;
  onClick?: () => void;
  showRightArrow?: boolean;
}

const ProgramCard: React.FC<IProps> = (props) => {
  const { program, showRightArrow, onClick } = props;
  if (program.endAt.getTime() < Date.now()) {
    return (
      <button className="m-1 mt-2 card card-body flex-grow-0 bg-primary-lighten p-1 d-flex flex-row align-items-center justify-content-between" onClick={onClick}>
        <span className="text-primary">{Moment(program.startAt).format('HH:mm')}</span>
        <span className="badge badge-primary">{program.attendingChildrenCount}/{program.maxChildren || 0} Attended</span>
      </button>
    );
  }

  if (program.startAt.getTime() < Date.now() && program.endAt.getTime() > Date.now()) {
    return (
      <button className="m-1 mt-2 card card-body flex-grow-0 bg-primary text-light d-flex flex-row" onClick={onClick}>
        <div className="text-left flex-grow-1">
          <h4 className="font-weight-normal mt-0 mb-1">{Moment(program.startAt).format('HH:mm')} - {Moment(program.endAt).format('HH:mm')}</h4>
          <small>{program.grade.name}</small>
          <div className="mt-1">
            <span className="badge badge-success">{program.attendingChildrenCount}/{program.maxChildren || 0} Paid</span>&nbsp;
            <span className="badge badge-danger ml-1">{program.attendingTrialChildrenCount}/{program.maxTrialChildren || 0} Trial</span>&nbsp;
        </div>
        </div>
        {showRightArrow && (
          <div className="align-self-center">
            <span className="mdi mdi-chevron-right h2 mr-n3"></span>
          </div>
        )}
      </button>
    );
  }

  if (program.startAt.getTime() > Date.now()) {
    return (
      <button className="m-1 mt-2 card card-body flex-grow-0 text-secondary d-flex flex-row" onClick={onClick}>
        <div className="text-left flex-grow-1">
          <h4 className="font-weight-normal mt-0 mb-1">{Moment(program.startAt).format('HH:mm')} - {Moment(program.endAt).format('HH:mm')}</h4>
          <small>{program.grade.name}</small>
          <div className="mt-1">
            <span className="badge badge-success">{program.attendingChildrenCount}/{program.maxChildren || 0} Paid</span>&nbsp;
          <span className="badge badge-danger ml-1">{program.attendingTrialChildrenCount}/{program.maxTrialChildren || 0} Trial</span>&nbsp;
        </div>
        </div>
        {showRightArrow && (
          <div className="align-self-center">
            <span className="mdi mdi-chevron-right h2 mr-n3"></span>
          </div>
        )
        }
      </button >
    );
  }

  return null;
};

export default ProgramCard;
