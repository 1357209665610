import { call} from 'redux-saga/effects';
import { syncStateToLocalStorage } from '../helpers/window-storage.helper';
import { store } from './';

// We will write state to local storage on window reload.
function stateSync() {
    window.addEventListener('unload', () => {
        syncStateToLocalStorage(store.getState());
    });
}

export const stateSyncEffects = [
    call(stateSync),
];
