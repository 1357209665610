import createSagaMiddleware, { SagaMiddleware } from 'redux-saga';
import { all } from 'redux-saga/effects';

import appEffects from '../effects/app.effects';
import authEffects from '../effects/auth.effects';
import lessonAttendanceEffects from '../pages/lesson-attendance/lesson-attendance.effects';
import loginOrganizationEffects from '../pages/login-organization/login-organization.effects';
import loginEffects from '../pages/login/login.effects';
import manageAttendanceEffects from '../pages/manage-attendance/manage-attendance.effects';
import manualEnrolmentCompleteEffects from '../pages/manual-enrolment-complete/manual-enrolment-complete.effects';
import manualEnrolmentEffects from '../pages/manual-enrolment/manual-enrolment.effects';
import workshopEnrolmentEffects from '../pages/workshop-enrolment/workshop-enrolment.effects';
import workshopEnrolmentCompleteEffects from '../pages/workshop-enrolment-complete/workshop-enrolment-complete.effects';
import parentSearchEffects from '../shared-components/parent-search/parent-search.effects';
import parentDetailsEffects from '../shared-components/parent-details/parent-details.effects';
import paymentCallbackEffects from '../pages/payment-callback/payment-callback.effects';
import { stateSyncEffects } from './store.effect';

export const sagaMiddleware: SagaMiddleware = createSagaMiddleware();

/**
 * root saga
 */
export function* appSaga() {
    yield all([
        ...stateSyncEffects,
        ...appEffects,
        ...authEffects,
        ...loginEffects,
        ...loginOrganizationEffects,
        ...lessonAttendanceEffects,
        ...manageAttendanceEffects,
        ...manualEnrolmentEffects,
        ...manualEnrolmentCompleteEffects,
        ...workshopEnrolmentEffects,
        ...workshopEnrolmentCompleteEffects,
        ...parentSearchEffects,
        ...parentDetailsEffects,
        ...paymentCallbackEffects
    ]);
}
