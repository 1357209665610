import React from 'react';

import Config from '@app/config';
import { SVGImage } from 'components';
import { Link } from 'react-router-dom';

const NotFound = () => (
  <div className="authentication-bg">
    <div className="account-pages pt-5 pb-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-5">
            <div className="card">
              <div className="card-header pt-4 pb-4 text-center bg-primary">
                <Link to="/">
                  <SVGImage src={require('assets/images/logo.svg')} alt="" />
                </Link>
              </div>

              <div className="card-body p-4">
                <div className="text-center">
                  <h1 className="text-error">4<i className="mdi mdi-emoticon-sad"></i>4</h1>
                  <h4 className="text-uppercase text-danger mt-3">Page Not Found</h4>
                  <p className="text-muted mt-3">It's looking like you may have taken a wrong turn. Don't worry... it happens to the best of us. Here's a little tip that might help you get back on track.</p>
                  <Link to="/" className="btn btn-info mt-3"><i className="mdi mdi-reply"></i> Return Home</Link>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

    </div>
    <footer className="footer footer-alt">
      {Config.FOOTER_COPYRIGHT}
      <br />
      {Config.VERSION}
    </footer>
  </div>
);

export default NotFound;
