import { Action } from 'redux-actions';
import { put, takeLatest } from 'redux-saga/effects';
import { setGlobalMessageBox } from '@app/actions/root';
import Config from '@app/config';
import httpHelpers from '@app/helpers/http.helpers';
import { store } from '@app/store';
import {
  IPaginationResponse
} from '@app/types';
import { IChild, IParentSummary, IEnrolment } from './parent-details.types';
import { IFormValueParentSearch } from '../parent-search/parent-search.types';
import {
  fetchChilds,
  fetchChildsSuccess,
  fetchParentSummary,
  setSelectedParentSummary,
  fetchCurrentEnrolment,
  setCurrentEnrolment,
  setCurrentEnrolmentLoading,
} from './parent-details.actions';
import { selectState } from './parent-details.selectors';

function* fetchChildsSaga(action: Action<IFormValueParentSearch>) {
  try {
    const selectedParent = selectState(store.getState()).selectedParent;

    const result: IPaginationResponse<IChild> = yield httpHelpers.get(`${Config.SERVER_URL}/api/children?parentId=${selectedParent.id}&pageNumber=1&pageSize=10`);
    yield put(fetchChildsSuccess(result.data));
  } catch (err) {
    yield put(setGlobalMessageBox({ title: 'Error', message: err.message, type: 'error', isHTML: true }));
  }
}

function* fetchParentSummarySaga(action: Action<any>) {
  try {
    const selectedParent = selectState(store.getState()).selectedParent;

    const parentSummary: IParentSummary = yield httpHelpers.get(`${Config.SERVER_URL}/api/parents/${selectedParent.id}/summary`);
    yield put(setSelectedParentSummary(parentSummary));
  } catch (err) {
    // yield put(setGlobalMessageBox({ title: 'Error', message: err.message, type: 'error', isHTML: true }));
  }
}

function* fetchCurrentEnrolmentSaga(action: Action<any>) {
  try {
    yield put(setCurrentEnrolmentLoading(true));
    const selectedParent = selectState(store.getState()).selectedParent;

    const response: IPaginationResponse<IEnrolment> = yield httpHelpers.get(`${Config.SERVER_URL}/api/enrolments?parentId=${selectedParent.id}&pageSize=10&pageNumber=1`);
    yield put(setCurrentEnrolment(response.data));
    yield put(setCurrentEnrolmentLoading(false));
  } catch (err) {
    yield put(setCurrentEnrolmentLoading(false));
    // yield put(setGlobalMessageBox({ title: 'Error', message: err.message, type: 'error', isHTML: true }));
  }
}
export default [
  takeLatest(fetchChilds.toString(), fetchChildsSaga),
  takeLatest(fetchParentSummary.toString(), fetchParentSummarySaga),
  takeLatest(fetchCurrentEnrolment.toString(), fetchCurrentEnrolmentSaga)
];