import { getAvatarName } from '@app/helpers/common.helpers';
import { formatCurrency } from '@app/helpers/number.helpers';
import { IAppState } from '@app/store';
import classNames from 'classnames';
import Moment from 'moment-timezone';
import React from 'react';
import { Badge, Button, Card, Spinner } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import ContentLoader from 'react-content-loader';
import { setSelectedParent, fetchChilds, fetchCurrentEnrolment, fetchParentSummary } from './parent-details.actions';
import { getParentChilds, getSelectedParentSummary, selectState, getCurrentEnrolments } from './parent-details.selectors';
import { IParent } from '../parent-search/parent-search.types';
import { IChild, IParentSummary, IEnrolment } from './parent-details.types';

interface IProps {
  onSelectChild?: (child: IChild) => void;
  onCreateChild?: () => void;
}

interface IDispatchProps {
  setSelectedParent: (params: IParent) => void;
  fetchChilds: () => void;
  fetchParentSummary: () => void;
  fetchCurrentEnrolment: () => void;
}

interface IStateProps {
  selectedParent: IParent;
  selectedParentSummary: IParentSummary;
  selectedChild: IChild;
  loading: boolean;
  childs: IChild[];
  enrolments: IEnrolment[];
  loadingEnrolments: boolean;
}

class ParentDetail extends React.Component<IProps & IDispatchProps & IStateProps> {
  componentDidMount() {
    this.props.setSelectedParent(this.props.selectedParent);
    this.props.fetchParentSummary();
    this.props.fetchCurrentEnrolment();
    this.props.fetchChilds();
  }

  renderCurrentEnrolmentLoader() {
    return Array(2).fill(0).map((x, index) => (
      <div className="col-md-4" key={String(index)}>
        <div
          className={classNames(`card flex-column align-items-center justify-content-center text-secondary`)}
          style={{ width: '100%', height: 180 }}
        >
          <ContentLoader width={400} height={130}>
            <rect x="135" y="10" rx="5" ry="5" width="130" height="25" />
            <rect x="100" y="45" rx="5" ry="5" width="200" height="15" />
            <rect x="140" y="70" rx="5" ry="5" width="120" height="20" />
            <rect x="140" y="95" rx="5" ry="5" width="120" height="20" />
          </ContentLoader>
        </div>
      </div>
    ));
  }

  render() {
    const { selectedParent, selectedParentSummary, selectedChild, childs: childrens, enrolments, loading, loadingEnrolments } = this.props;
    const avatarColors = ['bg-success', 'bg-warning', 'bg-danger', 'bg-primary'];
    return (
      <React.Fragment>
        <Helmet>
          <title>Choose child - Manual Enrolments - Booking Autopilot</title>
        </Helmet>
        <Card className="bg-primary text-white">
          <Card.Body>
            <div className="d-flex flex-row align-items-center">
              <div className="rounded-circle bg-white" style={{ width: 72, height: 72, minWidth: 72 }}></div>
              <div className="d-flex flex-column ml-3">
                <div>
                  <div className="font-weight-bold">{selectedParent.fullName}</div>
                  {/* <small>John K Poppins</small> */}
                </div>
                <div className="d-flex flex-row mt-2" style={{ height: 42 }}>
                  {selectedParentSummary.parentId !== 0 && (
                    <React.Fragment>
                      <div>
                        <div className="font-weight-bold">{formatCurrency(selectedParentSummary.totalRevenue)}</div>
                        <small>Revenue</small>
                      </div>
                      <div className="ml-4">
                        <div className="font-weight-bold">{selectedParentSummary.numberOfSales}</div>
                        <small>Bookings</small>
                      </div>
                      <div className="ml-4">
                        <div className="font-weight-bold">{selectedParentSummary.bookedTrial > 0 ? 'Yes' : 'No'}</div>
                        <small>Booked trial</small>
                      </div>
                      <div className="ml-4">
                        <div className="font-weight-bold">{selectedParentSummary.bookedMakeupCurrentTerm}</div>
                        <small>Make-ups booked this term</small>
                      </div>
                      {Boolean(selectedParentSummary.firstTermAttended) && (
                        <div className="ml-4">
                          <div className="font-weight-bold">{selectedParentSummary.firstTermAttended.name}</div>
                          <small>Attending since</small>
                        </div>
                      )}
                      {Boolean(selectedParentSummary.lastTermAttended) && (
                        <div className="ml-4">
                          <div className="font-weight-bold">{selectedParentSummary.lastTermAttended.name}</div>
                          <small>Last attended</small>
                        </div>
                      )}
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
        {/* {enrolments.length > 0 && ( */}
        <React.Fragment>
          <div className="d-flex flex-row align-items-center flex-grow-1">
            <h4>Current enrolments</h4>
          </div>
          {!loadingEnrolments && enrolments.length === 0 && (
            <div className="mt-1">
              <h5 className="text-muted text-center mt-3 mb-3">No enrolment listed</h5>
            </div>
          )}
          {(loadingEnrolments || enrolments.length > 0) && (
            <div className="mt-1 row">
              {loadingEnrolments && this.renderCurrentEnrolmentLoader()}
              {!loadingEnrolments && enrolments.map(item => (
                <div className="col-md-4" key={String(item.id)}>
                  <div
                    className={classNames(`card flex-column align-items-center justify-content-center text-secondary`)}
                    style={{ width: '100%', height: 180 }}
                  >
                    <h4 className="font-weight-normal mt-0 mb-1">{Moment(item.startTime, 'HH:mm:ss').format('HH:mm')} - {Moment(item.endTime, 'HH:mm:ss').format('HH:mm')}</h4>
                    <small className="text-center">{item.grade.name}</small>
                    <Badge variant="primary" className="mt-1">{item.center}</Badge>
                    <Badge variant="success" className="mt-1">{item.term.name}</Badge>
                  </div>
                </div>
              ))}
            </div>
          )}
        </React.Fragment>
        {/* )} */}
        <div className="d-flex flex-row align-items-center flex-grow-1">
          <h4>Children {Boolean(childrens.length) ? ' (' + childrens.length + ')' : ''}</h4>
        </div>
        {loading && (
          <div className="d-flex align-items-center justify-content-center mt-3" style={{ minHeight: 360 }}>
            <Spinner animation="border" />
          </div>
        )}
        {!loading && (
          <React.Fragment>
            <div className="mt-1 row">
              {childrens.map(item => (
                <div className="col-md-4" key={String(item.id)}>
                  <button
                    className={classNames(`card flex-row position-relative`, { 'border border-success': selectedChild && item.id === selectedChild.id })}
                    style={{ width: '100%', height: 180 }}
                    onClick={() => this.props.onSelectChild(item)}
                  >
                    <div className="card-body d-flex flex-column align-items-center justify-content-center">
                      <div className={classNames('rounded-circle mb-2 d-flex align-items-center justify-content-center text-white', avatarColors[item.id % avatarColors.length])} style={{ width: 56, height: 56 }}>
                        <span className="h4 m-0 p-0 font-weight-normal">{getAvatarName(item.firstName, item.lastName)}</span>
                      </div>
                      <div className="text-secondary">{item.fullName} &nbsp; <span className={classNames({ 'mdi mdi-human-male': item.genderTypeId === 1, 'mdi mdi-human-female': item.genderTypeId === 2 })}></span></div>
                      <div className="mt-1 d-flex flex-column" style={{ height: 36 }}>
                        <small className="text-muted">{Boolean(item.dateOfBirth) && Moment(item.dateOfBirth).format('DD MMMM YYYY')}</small>
                      </div>
                    </div>
                    {Boolean(selectedChild) && item.id === selectedChild.id && (
                      <div className="text-white bg-success rounded-circle d-flex align-items-center justify-content-center position-absolute" style={{ width: 32, height: 32, bottom: -16, left: 'calc(50% - 16px)' }}>
                        <i className="h4 mdi mdi-check m-0"></i>
                      </div>
                    )}
                  </button>
                </div>
              ))}
            </div>

            <div className="mt-4 mb-2 d-flex flex-column align-items-center">
              <p className="h5 text-muted mb-3">Child isn't listed?</p>
              <Button variant="primary" onClick={this.props.onCreateChild}>Create Child</Button>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  setSelectedParent: (params) => dispatch(setSelectedParent(params)),
  fetchChilds: () => dispatch(fetchChilds()),
  fetchParentSummary: () => dispatch(fetchParentSummary()),
  fetchCurrentEnrolment: () => dispatch(fetchCurrentEnrolment()),
});

const mapStateToProps = (state: IAppState, ownProps): IStateProps => {
  return {
    selectedParent: ownProps.selectedParent,
    selectedParentSummary: getSelectedParentSummary(state),
    loading: selectState(state).loading,
    selectedChild: selectState(state).selectedChild,
    childs: getParentChilds(state),
    enrolments: getCurrentEnrolments(state),
    loadingEnrolments: selectState(state).loadingEnrolments,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ParentDetail);
