import ReducerFactory from '@app/reducers/ReducerFactory';

import { logout } from '@app/actions/auth';
import {
  selectParent,
  updateCurrentPath,
  changeStep,
  initLoadFail,
  initLoadSuccess,
  setLoaderVisibleState,
  setSelectedParent,
  selectChild,
  setSelectedChild,
  setSelectedWorkshop,
  setSelectedWorkshopSessions,
  selectWorkshop,
  selectWorkshopSession,
  unselectWorkshopSession,
  createParent,
  createParentSuccess,
  createParentFailed,
  createChild,
  createChildSuccess,
  createChildFailed,
  fetchWorkshops,
  fetchWorkshopsSuccess,
  fetchWorkshopsMore,
  fetchWorkshopsMoreSuccess,
  fetchWorkshopSessions,
  fetchWorkshopSessionsSuccess,
  fetchPaymentMethods,
  fetchPaymentMethodsSuccess,
  startBooking,
  fetchPayment,
  fetchPaymentSuccess,
  selectPaymentMethod,
  applyPromocode,
  applyPromocodeSuccess,
  applyPromocodeFail,
  removePromocode,
  removePromocodeSuccess,
  removePromocodeFail,
  makePayment,
  makePaymentSuccess
} from './workshop-enrolment.actions';
import { completePaymentSuccess } from '../../pages/payment-callback/payment-callback.actions';
import { IChild, IParent } from '../../shared-components/parent-search/parent-search.types';
import { IWorkshop, IWorkshopSession } from '../../types/IWorkshop';
import { IPayment, IPaymentMethod } from '@app/types';

interface IScreenState {
  parentDetail: {
    loading: boolean;
    childs: IChild[];
    loadingEnrolments: boolean;
  };
  createParent: {
    isSubmitting: boolean;
  };
  createChild: {
    isSubmitting: boolean;
  };
  chooseWorkshop: IChooseWorkshopScreenState,
  chooseSessions: {
    loading: boolean;
    workshopSessions: IWorkshopSession[];
  };
  bookingSummary: {
    bookingLoading: boolean;
    paymentMethodLoading: boolean;
    paymentMethods: IPaymentMethod[];
    promocodeProcessing: boolean;
  };
}

interface IChooseWorkshopScreenState {
  workshops: IWorkshop[];
  loading: boolean;
  loadingMore: boolean;
  total: number;
  currentPage: number;
}

export interface IWorkshopEnrolmentState {
  initLoaded: boolean;
  initLoadedFailed: boolean;
  step: number;
  path: string;
  selectedParent: IParent;
  selectedChild: IChild,
  selectedWorkshop: IWorkshop,
  selectedSessions: IWorkshopSession[],
  selectedPaymentMethod: IPaymentMethod;

  screens: IScreenState;
  payment: IPayment;
  loading: boolean;
}

const initState: IWorkshopEnrolmentState = {
  initLoaded: false,
  initLoadedFailed: false,
  step: -1,
  path: '',
  selectedParent: null,
  selectedChild: null,
  selectedWorkshop: null,
  selectedSessions: [],
  selectedPaymentMethod: null,

  screens: {
    parentDetail: {
      childs: [],
      loading: false,
      loadingEnrolments: false,
    },
    createParent: {
      isSubmitting: false,
    },
    createChild: {
      isSubmitting: false,
    },
    chooseWorkshop: {
      workshops: [],
      loading: false,
      loadingMore: false,
      total: 0,
      currentPage: 0
    },
    chooseSessions: {
      loading: false,
      workshopSessions: []
    },
    bookingSummary: {
      bookingLoading: false,
      paymentMethodLoading: false,
      paymentMethods: [],
      promocodeProcessing: false,
    }
  },
  payment: null,
  loading: false
};

const reducer = new ReducerFactory(initState)
  .add(logout, (state, action) => {
    return initState;
  })
  .add(changeStep, (state, action) => {
    return {
      ...state,
      step: action.payload,
    };
  })
  .add(updateCurrentPath, (state, action) => {
    return {
      ...state,
      path: action.payload,
    };
  })
  .add(initLoadSuccess, (state, action) => {
    return {
      ...state,
      initLoaded: true,
      initLoadedFailed: false,
    };
  })
  .add(initLoadFail, (state, action) => {
    return {
      ...state,
      initLoadedFailed: true,
      selectedParent: null,
      selectedChild: null,
      selectedWorkshop: null,
      selectedSessions: []
    };
  })
  .add(setSelectedParent, (state, action) => {
    return {
      ...state,
      selectedParent: action.payload,
    };
  })
  .add(selectChild, (state, action) => {
    return {
      ...state,
      selectedChild: action.payload,
    };
  })
  .add(setSelectedChild, (state, action) => {
    return {
      ...state,
      selectedChild: action.payload,
    };
  })
  .add(selectWorkshop, (state, action) => {
    return {
      ...state,
      selectedWorkshop: action.payload,
      selectedSessions: []
    };
  })
  .add(setSelectedWorkshop, (state, action) => {
    return {
      ...state,
      selectedWorkshop: action.payload,
    };
  })
  .add(selectWorkshopSession, (state, action) => {
    return {
      ...state,
      selectedSessions: [...state.selectedSessions, action.payload]
    }
  })
  .add(unselectWorkshopSession, (state, action) => {
    return {
      ...state,
      selectedSessions: [...state.selectedSessions.filter(x => x.id !== action.payload.id)]
    }
  })
  .add(setSelectedWorkshopSessions, (state, action) => {
    return {
      ...state,
      selectedSessions: action.payload
    }
  })
  .add(selectParent, (state, action) => {
    return {
      ...state,
      selectedParent: action.payload,
      selectedChild: null,
      screens: {
        ...state.screens,
        parentDetail: {
          ...state.screens.parentDetail,
          childs: (action.payload.children || []).map(x => ({ ...x, parentId: action.payload.id })),
        },
      },
    };
  })
  .add(createParent, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        createParent: {
          ...state.screens.createParent,
          isSubmitting: true,
        },
      },
    };
  })
  .add(createParentSuccess, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        createParent: {
          ...state.screens.createParent,
          isSubmitting: false,
        },
      },
    };
  })
  .add(createParentFailed, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        createParent: {
          ...state.screens.createParent,
          isSubmitting: false,
        },
      },
    };
  })
  .add(createChild, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        createChild: {
          ...state.screens.createChild,
          isSubmitting: true,
        },
      },
    };
  })
  .add(createChildSuccess, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        createChild: {
          ...state.screens.createChild,
          isSubmitting: false,
        },
      },
      selectedChild: action.payload,
    };
  })
  .add(createChildFailed, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        createChild: {
          ...state.screens.createChild,
          isSubmitting: false,
        },
      },
    };
  })
  .add(fetchWorkshops, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        chooseWorkshop: {
          ...state.screens.chooseWorkshop,
          loading: true,
        },
      },
    };
  })
  .add(fetchWorkshopsSuccess, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        chooseWorkshop: {
          ...state.screens.chooseWorkshop,
          workshops: action.payload.data,
          loading: false,
          currentPage: 1,
          total: action.payload.totalCount
        },
      },
      selectedWorkshop: null,
    };
  })
  .add(fetchWorkshopsMore, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        chooseWorkshop: {
          ...state.screens.chooseWorkshop,
          loadingMore: true,
        },
      },
    };
  })
  .add(fetchWorkshopsMoreSuccess, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        chooseWorkshop: {
          ...state.screens.chooseWorkshop,
          workshops: [...state.screens.chooseWorkshop.workshops, ...action.payload.data],
          loadingMore: false,
          currentPage: state.screens.chooseWorkshop.currentPage + 1,
          total: action.payload.totalCount
        },
      },
      selectedWorkshop: null,
    };
  })
  .add(fetchWorkshopSessions, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        chooseSessions: {
          ...state.screens.chooseSessions,
          loading: true,
        },
      },
    };
  })
  .add(fetchWorkshopSessionsSuccess, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        chooseSessions: {
          ...state.screens.chooseSessions,
          loading: false,
          workshopSessions: action.payload,
        },
      },
    };
  })
  .add(setLoaderVisibleState, (state, action) => {
    return {
      ...state,
      loading: action.payload,
    };
  })
  .add(fetchPaymentMethods, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        bookingSummary: {
          ...state.screens.bookingSummary,
          paymentMethodLoading: true,
        },
      },
    };
  })
  .add(fetchPaymentMethodsSuccess, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        bookingSummary: {
          ...state.screens.bookingSummary,
          paymentMethodLoading: false,
          paymentMethods: action.payload,
        },
      },
    };
  })
  .add(startBooking, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        bookingSummary: {
          ...state.screens.bookingSummary,
          bookingLoading: true,
        },
      },
    };
  })
  .add(fetchPayment, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  })
  .add(fetchPaymentSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      payment: action.payload,
      screens: {
        ...state.screens,
        bookingSummary: {
          ...state.screens.bookingSummary,
          bookingLoading: false
        },
      },
    };
  })
  .add(selectPaymentMethod, (state, action) => {
    return {
      ...state,
      selectedPaymentMethod: action.payload,
      loading: true,
    };
  })
  .add(applyPromocode, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        bookingSummary: {
          ...state.screens.bookingSummary,
          promocodeProcessing: true,
        },
      },
    };
  })
  .add(applyPromocodeSuccess, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        bookingSummary: {
          ...state.screens.bookingSummary,
          promocodeProcessing: false,
        },
      },
      payment: {
        ...state.payment,
        promoCodes: [action.payload],
      },
    };
  })
  .add(applyPromocodeFail, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        bookingSummary: {
          ...state.screens.bookingSummary,
          promocodeProcessing: false,
        },
      },
    };
  })
  .add(removePromocode, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        bookingSummary: {
          ...state.screens.bookingSummary,
          promocodeProcessing: true,
        },
      },
    };
  })
  .add(removePromocodeSuccess, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        bookingSummary: {
          ...state.screens.bookingSummary,
          promocodeProcessing: false,
        },
      },
      payment: {
        ...state.payment,
        promoCodes: [],
      },
    };
  })
  .add(removePromocodeFail, (state, action) => {
    return {
      ...state,
      screens: {
        ...state.screens,
        bookingSummary: {
          ...state.screens.bookingSummary,
          promocodeProcessing: false,
        },
      },
    };
  })
  .add(makePayment, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  })
  .add(makePaymentSuccess, (state, action) => {
    return {
      ...initState,
    };
  })
  .add(completePaymentSuccess, (state, action) => {
    return {
      ...initState,
    };
  })
  .toReducer();

export default reducer;
