import { Action } from 'redux-actions';
import { put, takeLatest } from 'redux-saga/effects';

import Config from '@app/config';
import httpHelpers from '@app/helpers/http.helpers';
import { store } from '@app/store';
import {
  IFormValueParentSearch,
  IParent
} from './parent-search.types';
import { IPaginationResponse } from 'types/common';

import { setGlobalMessageBox } from '@app/actions/root';
import analytics from '@app/helpers/analytics.helper';
import {
  searchParent,
  searchParentMore,
  searchParentMoreSuccess,
  searchParentSuccess,
} from './parent-search.actions';
import { selectState } from './parent-search.selectors';

function* searchParentSaga(action: Action<IFormValueParentSearch>) {
  analytics.event('parent_search');
  try {
    const fullName = action.payload.fullName;
    const phoneNumber = action.payload.phoneNumber;
    const childName = action.payload.child;

    const result: IPaginationResponse<IParent> = yield httpHelpers.get(`${Config.SERVER_URL}/api/parents/all?name=${encodeURIComponent(fullName)}&child=${encodeURIComponent(childName)}&phoneNumber=${encodeURIComponent(phoneNumber)}&pageSize=10&pageNumber=1`);
    yield put(searchParentSuccess(result));
  } catch (err) {
    yield put(setGlobalMessageBox({ title: 'Error', message: err.message, type: 'error', isHTML: true }));
  }
}

function* searchParentMoreSaga(action: Action<any>) {
  analytics.event('parent_search_more');
  try {
    const formValue = selectState(store.getState()).formValue;
    const currentPage = selectState(store.getState()).currentPage;
    const fullName = formValue.fullName;
    const phoneNumber = formValue.phoneNumber;

    const result: IPaginationResponse<IParent> = yield httpHelpers.get(`${Config.SERVER_URL}/api/parents/all?name=${encodeURIComponent(fullName)}&phoneNumber=${encodeURIComponent(phoneNumber)}&pageSize=10&pageNumber=${currentPage + 1}`);
    yield put(searchParentMoreSuccess(result));
  } catch (err) {
    yield put(setGlobalMessageBox({ title: 'Error', message: err.message, type: 'error', isHTML: true }));
  }
}

export default [
  takeLatest(searchParent.toString(), searchParentSaga),
  takeLatest(searchParentMore.toString(), searchParentMoreSaga)
];
