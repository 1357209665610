import ReducerFactory from 'reducers/ReducerFactory';

import { logout } from '@app/actions/auth';
import { clearErrorMessage, fetchFranchisor, fetchFranchisorFailed, fetchFranchisorSuccess } from './login-organization.actions';

export interface IScreenLoginOrganizationState {
  isFetchingFranchisor: boolean;
  errorMessage: string;
}

const initState: IScreenLoginOrganizationState = {
  isFetchingFranchisor: false,
  errorMessage: '',
};

const reducer = new ReducerFactory(initState)
  .add(logout, (state, action) => {
    return initState;
  })
  .add(fetchFranchisor, (state, action) => {
    return {
      ...state,
      isFetchingFranchisor: true,
    };
  })
  .add(fetchFranchisorSuccess, (state, action) => {
    return {
      ...state,
      isFetchingFranchisor: false,
    };
  })
  .add(fetchFranchisorFailed, (state, action) => {
    return {
      ...state,
      isFetchingFranchisor: false,
      errorMessage: action.payload,
    };
  })
  .add(clearErrorMessage, (state, action) => {
    return {
      ...state,
      errorMessage: '',
    };
  })
  .toReducer();

export default reducer;
