import { ILookup, LookupType } from '@app/types';
import { IAppState } from 'store';

export const selectRootState = (state: IAppState) => state.root;

export const selectGlobalMesasge = (state: IAppState) => selectRootState(state).globalMessage;

export const selectInitialized = (state: IAppState) => selectRootState(state).initialized;

export const selectLookup = (state: IAppState, lookupType: LookupType): ILookup[] => {
  const rootState = selectRootState(state);

  if (!rootState.lookups) {
    return [];
  }

  return rootState.lookups.data[lookupType] || [];
};
