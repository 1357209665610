import analytics from '@app/helpers/analytics.helper';
import { IAppState } from '@app/store';
import { Availability, IWorkshop, IWorkshopSession, IWorkshopWeek, WorkshopSessionType } from '@app/types';
import Moment from 'moment-timezone';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { Dispatch } from 'redux';
import { changeScreen, fetchWorkshopSessions, selectWorkshopSession, unselectWorkshopSession } from '../workshop-enrolment.actions';
import { PATH } from '../workshop-enrolment.constants';
import { selectState } from '../workshop-enrolment.selectors';
import withValidation from '../../../components/with-validation/with-validation.hoc';
import { groupBy } from '@app/helpers/common.helpers';
import classNames from 'classnames';
import { IChangeScreen } from '../workshop-enrolment.types';

interface IDispatchProps {
  changeScreen: (params: IChangeScreen) => void;
  fetchWorkshopSessions: () => void;
  selectWorkshopSession: (workshopSessions: IWorkshopSession) => void;
  unselectWorkshopSession: (workshopSessions: IWorkshopSession) => void;
}

interface IStateProps {
  loading: boolean;
  workshopSessions: IWorkshopSession[];
  selectedWorkshop: IWorkshop;
  selectedSessions: IWorkshopSession[];
}

const transformSessions = (sessions: IWorkshopSession[]) => {
  return sessions.sort((a, b) => Moment(a.start).diff(Moment(b.start))).map(x => {
    const availability = calculateAvailability(x);
    return {
      ...x,
      date: Moment(x.start).startOf('day'),
      description: `${Moment(x.start).format('LT')} - ${Moment(x.end).format('LT')}`,
      order: convertSessionTypeToOrder(x.type),
      availability,
      bgColor: convertAvailabilityToBgColor(availability)
    };
  });

}

const convertSessionTypeToOrder = (type: WorkshopSessionType): number => {
  switch (type) {
    case WorkshopSessionType.FullDay:
      return 1;
    case WorkshopSessionType.Morning:
      return 2;
    case WorkshopSessionType.Afternoon:
    default:
      return 3;
  }
}

const calculateAvailability = (session: IWorkshopSession): Availability => {
  if (session.totalCapacity === 0 || session.totalAttendingChildrenCount >= session.totalCapacity)
    return Availability.No;

  if (session.totalAttendingChildrenCount === session.totalCapacity - 1)
    return Availability.LastSpot;

  if (session.totalAttendingChildrenCount >= (session.totalCapacity / 2))
    return Availability.Limited;

  return Availability.Yes;
}

const convertAvailabilityToBgColor = (availability: Availability): string => {
  switch (availability) {
    case Availability.Yes:
      return '';
    case Availability.No:
      return '#f2dede';
    case Availability.Limited:
    case Availability.LastSpot:
      return '#fcf8e3';
  }
}

class WorkshopSessions extends React.Component<IDispatchProps & IStateProps> {
  componentDidMount() {
    this.props.fetchWorkshopSessions();
  }

  renderLoading() {
    return (
      <div className="card card-body d-flex align-items-center justify-content-center mt-3" style={{ minHeight: 360 }}>
        <Spinner animation="border" />
      </div>
    );
  }

  handleChangeSelection(session) {
    const { selectedSessions, selectWorkshopSession, unselectWorkshopSession } = this.props;

    const isSelected = selectedSessions && selectedSessions.some(x => x.id === session.id);

    if (!isSelected) {
      selectWorkshopSession(session);

      if (session.type === 'FullDay') {
        // remove selected half day sessions on same date
        const sameDateHalfDaySessions = selectedSessions.filter(x => x.type !== 'FullDay' && Moment(x.start).startOf('day').isSame(Moment(session.start).startOf('day')));
        sameDateHalfDaySessions.forEach(x => unselectWorkshopSession(x));
      } else {
        // remove selected full day session on same date
        const sameDateFullDaySession = selectedSessions.find(x => x.type === 'FullDay' && Moment(x.start).startOf('day').isSame(Moment(session.start).startOf('day')));
        if (sameDateFullDaySession) {
          unselectWorkshopSession(sameDateFullDaySession);
        }
      }
    } else {
      unselectWorkshopSession(session);
    }
  }

  renderSessionsInWeek(week: IWorkshopWeek, sessions: IWorkshopSession[]) {
    const transformedSessions = transformSessions(sessions);
    const sessionsByDate = groupBy(transformedSessions, 'date');

    return <React.Fragment>{Object.keys(sessionsByDate).map(date => {
      const sessions = sessionsByDate[date];

      return <div key={String(date)} className="d-flex flex-column justify-content-start bg-light-lighten flex-grow-1 ml-1 mr-1 rounded" style={{ minWidth: 180 }}>
        <div className="d-flex flex-row justify-content-center p-1">
          <h6 className="text-uppercase text-muted">{Moment(date).format('ddd, DD MMM')}</h6>
        </div>
        <hr className="m-0" />
        {sessions.sort((a: any, b: any) => a.order - b.order).map((session: any) => {
          const isSelected = this.props.selectedSessions && this.props.selectedSessions.some(x => x.id === session.id);
          return (
            <button
              key={String(session.id)}
              className={classNames(
                `m-1 mt-2 card card-body p-2 flex-grow-0 text-secondary d-flex flex-row position-relative border`,
                isSelected ? 'border-success' : 'border-white',
              )}
              style={{ backgroundColor: session.bgColor }}
              disabled={session.availability === Availability.No}
              onClick={() => this.handleChangeSelection(session)}
            >
              <div className="text-left flex-grow-1">
                <h4 className="font-weight-normal mt-0 mb-1">{Moment(session.start).format('HH:mm')} - {Moment(session.end).format('HH:mm')}</h4>
                <div className="mt-1">
                  <span className="badge badge-success">{session.totalAttendingChildrenCount}/{session.capacity || 0} Paid</span>&nbsp;
                  <small>{session.type}</small>
                </div>
              </div>
              {isSelected && (
                <div
                  className='text-white rounded-circle d-flex align-items-center justify-content-center position-absolute bg-success'
                  style={{ width: 24, height: 24, bottom: -12, left: 'calc(50% - 12px)' }}
                >
                  <i className="h4 mdi mdi-check m-0"></i>
                </div>
              )}
            </button >
          );
        })}
      </div>
    })}
    </React.Fragment>;
  }

  renderWeek(week: IWorkshopWeek) {
    const { workshopSessions } = this.props;

    const sessionsInWeek = workshopSessions.filter(x => x.weekId === week.id);

    return <div key={week.id} className="card card-body mt-1" style={{ overflowY: 'auto' }}>
      <h5 className="text-uppercase text-muted">
        {week.name}
        <span className='small ml-2'>{Moment(week.startDate).format('LL')} - {Moment(week.endDate).format('LL')}</span></h5>
      <div className="d-flex flex-row justify-content-between ml-n1 mr-n1m mt-1">
        {this.renderSessionsInWeek(week, sessionsInWeek)}
      </div>
    </div>
  }

  renderSessions() {
    const { selectedWorkshop } = this.props;
    return <React.Fragment>
      {selectedWorkshop && selectedWorkshop.weeks
        .sort((a, b) => Moment(a.startDate).diff(Moment(b.startDate)))
        .map(week => this.renderWeek(week))}
    </React.Fragment>;
  }

  renderConfirmSessionSelectionButton() {
    const { selectedSessions } = this.props;
    return <button className="btn btn-primary"
      disabled={!selectedSessions || selectedSessions.length === 0}
      onClick={() => this.handleConfirmSessionSelection()}>Continue with selected sessions</button>;
  }

  handleConfirmSessionSelection() {
    const { selectedSessions } = this.props;
    if (selectedSessions && selectedSessions.length > 0) {
      analytics.event('select_workshop_sessions', {
        sessionIds: selectedSessions.map(x => x.id)
      });
      this.props.changeScreen({ path: PATH.BOOKING_SUMMARY, params: { sessionIds: selectedSessions.map(x => x.id) } });
    }
  }

  render() {
    // tslint:disable-next-line: no-shadowed-variable
    const { loading, selectedWorkshop } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>Choose sessions - Workshop Enrolments - Booking Autopilot</title>
        </Helmet>
        <div className="d-flex flex-row flex-grow-1 mt-3 mb-2 align-items-center">
          <div className="d-flex flex-column mr-1 flex-grow-1">
            <h4 className="mr-1 flex-grow-1">{selectedWorkshop.name}</h4>
            <h6>{Moment(selectedWorkshop.startDate).format('LL')} - {Moment(selectedWorkshop.endDate).format('LL')}</h6>
          </div>
          {!loading && this.renderConfirmSessionSelectionButton()}
        </div>
        {loading ? this.renderLoading() : this.renderSessions()}
        {!loading && <div className="d-flex flex-column align-items-end">{this.renderConfirmSessionSelectionButton()}</div>}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  changeScreen: (params) => dispatch(changeScreen(params)),
  fetchWorkshopSessions: () => dispatch(fetchWorkshopSessions()),
  selectWorkshopSession: (session) => dispatch(selectWorkshopSession(session)),
  unselectWorkshopSession: (session) => dispatch(unselectWorkshopSession(session))
});

const mapStateToProps = (state: IAppState): IStateProps => {
  const screenState = selectState(state).screens.chooseSessions;

  return {
    loading: screenState.loading,
    workshopSessions: screenState.workshopSessions,
    selectedWorkshop: selectState(state).selectedWorkshop as IWorkshop,
    selectedSessions: selectState(state).selectedSessions as IWorkshopSession[]
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withValidation(
    WorkshopSessions,
    (props) => Boolean(props.selectedWorkshop),
    () => <Redirect to={PATH.CHOOSE_WORKSHOP} />,
  ),
);

