// number.helper.ts
import accounting from 'accounting';

let currencyOptions = {
  symbol: '€',
  decimal: ',',
  thousand: '.',
  precision: 2,
  format: '%v %s',
};

export const setCurrencyOptions = (value: any) => {
  const symbol = value.symbol;
  const clientSideFormatting: string = value.clientSideFormatting;
  const format = clientSideFormatting.startsWith(symbol + ' ') ? '%s %v' :
    clientSideFormatting.startsWith(symbol) ? '%s%v' :
      clientSideFormatting.endsWith(' ' + symbol) ? '%v %s' :
        clientSideFormatting.endsWith(symbol) ? '%v%s' : '%v %s';

  // eslint-disable-next-line
  const thousand = clientSideFormatting.match(/[\.\,]/)[0];
  const decimal = thousand === ',' ? '.' : ',';

  currencyOptions = {
    symbol,
    decimal,
    thousand,
    precision: 2,
    format,
  };
};

export const formatCurrency = (input: number) => {
  if (input === undefined || input === null) {
    return null;
  }

  return accounting.formatMoney(input, currencyOptions);
};
