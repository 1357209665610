import Config from '@app/config';
import analytics from '@app/helpers/analytics.helper';
import { selectFranchisor } from '@app/selectors/auth';
import { IAppState } from '@app/store';
import { IFranchisor } from '@app/types';
import { SVGImage } from 'components';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { forgotPasswordAPI } from './forgot-password.helper';

const initialValues = {
  username: '',
};

const formValidationSchema = yup.object().shape({
  username: yup.string().required(),
});

interface IStateProps {
  franchisor: IFranchisor;
}

const ForgotPassword: React.FC<IStateProps> = (props) => {
  const [sentEmail, setSentEmail] = useState('');
  const [error, setError] = useState('');

  if (!props.franchisor) {
    return (
      <Redirect to="/login" />
    );
  }

  return (
    <div className="authentication-bg">
      <div className="account-pages pt-5 pb-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5">
              <div className="card">
                <div className="card-header pt-4 pb-4 text-center bg-primary">
                  <a href="/">
                    <span>
                      <SVGImage src={require('assets/images/logo.svg')} alt="" id="side-main-logo" />
                    </span>
                  </a>
                </div>

                <div className="card-body p-4">

                  <div className="text-center w-75 m-auto">
                    <h4 className="text-dark-50 text-center mt-0 font-weight-bold">Forgot your password?</h4>
                    <p className="text-muted mb-4">Enter your username below to reset your password.</p>
                  </div>

                  {Boolean(error) && (
                    <Alert variant="danger">
                      {error}
                    </Alert>
                  )}
                  
                  {Boolean(sentEmail) && (
                    <Alert variant="success">
                      We have sent a password reset link to {sentEmail}. Please use the link in the next 24 hours to reset your password.
                    </Alert>
                  )}

                  <Formik
                    initialValues={initialValues}
                    validationSchema={formValidationSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                      setSentEmail('');
                      setError('');
                      try {
                        analytics.event('forgot_password');
                        const email = await forgotPasswordAPI(values.username);
                        setSentEmail(email);
                      } catch (error) {
                        setError(error.message);
                      }

                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      handleChange,
                      handleSubmit,
                      isValid,
                      isSubmitting,
                    }) => (
                        <form onSubmit={handleSubmit}>

                          <div className="form-group">
                            <label>Username</label>
                            <input
                              className="form-control"
                              name="username"
                              value={values.username}
                              onChange={handleChange}
                            />
                          </div>

                          <div className="mt-4 d-flex align-items-center flex-row justify-content-between">
                            <Link to="/login" className="btn btn-light">Back</Link>
                            <button className="btn btn-danger d-flex flex-row align-items-center" type="submit" disabled={isSubmitting || !isValid}>
                              {!isSubmitting ? 'Send' : 'Sending....'}
                            </button>
                          </div>

                        </form>
                      )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>

      <footer className="footer footer-alt">
        {Config.FOOTER_COPYRIGHT}
        <br />
        {Config.VERSION}
      </footer>
    </div>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => ({
  franchisor: selectFranchisor(state),
});

export default connect(mapStateToProps)(ForgotPassword);
