import { IAppState } from 'store';

export const selectState = (state: IAppState) => state.lessonAttendance;

export const selectLessonAttendance = (state: IAppState) => selectState(state).weeklyPrograms || [];
export const selectViewMode = (state: IAppState) => selectState(state).viewMode || 'weekly';
export const selectedProgram = (state: IAppState) => selectState(state).selectedProgram;
export const selectAttendanceLoading = (state: IAppState) => selectState(state).attendanceLoading;
export const selectAttendance = (state: IAppState) => selectState(state).attendance;
export const selectLoading = (state: IAppState) => selectState(state).weeklyProgramsLoading;
