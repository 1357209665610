import { Button } from '@app/components';
import { getAvatarName } from '@app/helpers/common.helpers';
import { IAppState } from '@app/store';
import classNames from 'classnames';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { searchParentMore } from './parent-search.actions';
import { selectState } from './parent-search.selectors';
import { IParent } from './parent-search.types';

interface IProps {
  onSelectParent?: (parent: IParent) => void;
  selectedParent: IParent,
  onCreateNewParent?: () => void;
}

interface IDispatchProps {
  loadMore: () => void;
}

interface IStateProps {
  showResult: boolean;
  parents: IParent[];
  loadingMore: boolean;
  total: number;
}

const ParentSearchResult: React.FC<IProps & IDispatchProps & IStateProps> = (props) => {
  const { parents, showResult, selectedParent } = props;

  if (!showResult) {
    return null;
  }

  const avatarColors = ['bg-success', 'bg-warning', 'bg-danger', 'bg-primary'];

  return (
    <React.Fragment>
      <div className="d-flex flex-row align-items-center">
        <div className="d-flex flex-row align-items-center flex-grow-1 mt-3 mb-2">
          <h4 className="mr-1">Search Result ({parents.length})</h4>
        </div>
      </div>

      <div className="mt-1 row">
        {parents.map(item => (
          <div className="col-md-4 pb-2" key={String(item.id)}>
            <button
              className={classNames(`card flex-row position-relative`, { 'border border-success': selectedParent && item.id === selectedParent.id })}
              style={{ width: '100%', height: 190 }}
              onClick={() => props.onSelectParent(item)}
            >
              <div className="card-body d-flex flex-column align-items-center justify-content-center overflow-hidden">
                <div className={classNames('rounded-circle mb-2 d-flex align-items-center justify-content-center text-white', avatarColors[item.id % avatarColors.length])} style={{ width: 56, height: 56 }}>
                  <span className="h4 m-0 p-0 font-weight-normal">{getAvatarName(item.firstName, item.lastName)}</span>
                </div>
                <div className="text-secondary">{item.fullName}</div>
                <div className="d-flex flex-row align-items-center w-100 overflow-auto justify-content-center">
                  {item.children.length === 0 ? <small>&nbsp;</small> : null}
                  {item.children.map((c, index) => (<small key={c.id} className="text-muted" style={{ whiteSpace: 'nowrap' }}>{index > 0 ? ', ' : ''}{c.fullName}</small>))}
                </div>
                <div className="mt-1 d-flex flex-column" style={{ height: 36 }}>
                  <small className="text-muted">{item.phoneNumber}</small>
                  <small className="text-muted">{item.email}</small>
                </div>
              </div>
              {Boolean(selectedParent) && item.id === selectedParent.id && (
                <div className="text-white bg-success rounded-circle d-flex align-items-center justify-content-center position-absolute" style={{ width: 32, height: 32, bottom: -16, left: 'calc(50% - 16px)' }}>
                  <i className="h4 mdi mdi-check m-0"></i>
                </div>
              )}
            </button>
          </div>
        ))}
      </div>

      {props.total > props.parents.length && (
        <div className="mt-4 mb-2 d-flex flex-column align-items-center">
          <Button variant="light" onClick={props.loadMore} disabled={props.loadingMore}>
            {props.loadingMore ? (
              <>
                <Spinner size="sm" animation="border" className="align-middle mr-1" />
                Loading more
              </>
            ) : 'Load more'}
          </Button>
        </div>
      )}

      <div className="mt-4 mb-2 d-flex flex-column align-items-center">
        {parents.length > 0 && <p className="h5 text-muted mb-3">Parent isn't listed?</p>}
        {parents.length === 0 && <p className="h5 text-muted mb-3">No Result found</p>}
        <Button variant="primary" onClick={props.onCreateNewParent}>Create Parent</Button>
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  loadMore: () => dispatch(searchParentMore())
});

const mapStateToProps = (state: IAppState): IStateProps => {
  const screenState = selectState(state);

  return {
    showResult: screenState.showResult,
    parents: screenState.result,
    loadingMore: screenState.loadingMore,
    total: screenState.total,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ParentSearchResult);
