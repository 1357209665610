import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { changeFranchisor, login } from 'actions/auth';
import { selectFranchisor, selectIsAuthenticating } from 'selectors/auth';
import { IAppState } from 'store';
import { ILoginParam } from 'types/common';
import { IDispatchProps, IStateProps } from './types';

import LoginComponent from './login.component';

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  login: (user: ILoginParam) => dispatch(login(user)),
  changeFranchisor: () => dispatch(changeFranchisor()),
});

const mapStateToProps = (state: IAppState): IStateProps => ({
  errorMessage: '',
  isAuthenticating: selectIsAuthenticating(state),
  franchisor: selectFranchisor(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);
