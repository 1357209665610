import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { IAppState } from 'store';

import { selectCenters, selectedCenter } from 'selectors/auth';
import { changeCenter, changeViewMode, fetchWeeklyPrograms, setSelectedProgram, toggleAttendance } from './lesson-attendance.actions';
import LessionAttendanceComponent, { IDispatchProps, IStateProps } from './lesson-attendance.component';
import { selectAttendance, selectAttendanceLoading, selectedProgram, selectLessonAttendance, selectLoading, selectViewMode } from './lesson-attendance.selectors';

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  fetchPrograms: () => {
    dispatch(fetchWeeklyPrograms());
  },
  changeViewMode: (viewMode) => dispatch(changeViewMode(viewMode)),
  setSelectedProgram: (program) => dispatch(setSelectedProgram(program)),
  onToggleAttendance: (item) => dispatch(toggleAttendance(item)),
  changeCenter: (id: number) => dispatch(changeCenter(id)),
});

const mapStateToProps = (state: IAppState): IStateProps => ({
  weeklyProgramsLoading: selectLoading(state),
  weeklyPrograms: selectLessonAttendance(state),
  viewMode: selectViewMode(state),
  selectedProgram: selectedProgram(state),
  attendanceLoading: selectAttendanceLoading(state),
  attendance: selectAttendance(state),
  centers: selectCenters(state),
  selectedCenter: selectedCenter(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(LessionAttendanceComponent);
