import analytics from '@app/helpers/analytics.helper';
import { selectedTerm } from '@app/selectors/auth';
import { IAppState } from '@app/store';
import { DateType, ITerm } from '@app/types';
import Moment from 'moment-timezone';
import { IProgram } from 'pages/manage-attendance/manage-attendance.types';
import React from 'react';
import { Badge, Card, Spinner, Table } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { Dispatch } from 'redux';
import { changeScreen, fetchLessonDates, selectDate, selectPaymentType } from '../manual-enrolment.actions';
import { PATH } from '../manual-enrolment.constants';
import { selectState } from '../manual-enrolment.selectors';
import { IChildrenPaymentTypeDetail, IProgramDate } from '../manual-enrolment.types';
import withValidation from '../../../components/with-validation/with-validation.hoc';
import LessonDateRow from './lesson-date-row.component';

interface IDispatchProps {
  fetchLessonDates: () => void;
  selectDate: (date: DateType, paymentType: IChildrenPaymentTypeDetail) => void;
}

interface IStateProps {
  loading: boolean;
  lessonDates: IProgramDate[];
  selectedProgram: IProgram;
  selectedTerm: ITerm;
}

class LessonDates extends React.Component<IDispatchProps & IStateProps> {
  componentDidMount() {
    this.props.fetchLessonDates();
  }

  renderLoading() {
    return (
      <div className="card card-body d-flex align-items-center justify-content-center mt-3" style={{ minHeight: 360 }}>
        <Spinner animation="border" />
      </div>
    );
  }

  renderDates() {
    const { lessonDates, selectedProgram } = this.props;
    return (
      <Card>
        <Table>
          <thead className="bg-light">
            <tr>
              <th>Date</th>
              <th>Booking Type</th>
              <th className="text-right">Actions</th>
            </tr>
          </thead>
          <tbody>
            {lessonDates.map((item, index) => (
              <LessonDateRow
                key={String(index)}
                program={selectedProgram}
                term={this.props.selectedTerm}
                date={item.date}
                paymentTypes={item.childrenPaymentTypes.length ? (item.childrenPaymentTypes[0].paymentTypes || []) : []}
                onSelect={(date, paymentType) => this.props.selectDate(date, paymentType)}
                notAvailable={item.notAvailable}
                message={item.message}
              />
            ))}
          </tbody>
        </Table>
      </Card>
    );
  }

  render() {
    // tslint:disable-next-line: no-shadowed-variable
    const { loading, selectedProgram, selectedTerm } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>Choose timetable - Manual Enrolments - Booking Autopilot</title>
        </Helmet>
        <div className="d-flex flex-column flex-grow-1 mt-3 mb-2">
          <h4 className="mr-1 flex-grow-1">{selectedProgram.grade.name}</h4>
          <h6>{Moment(selectedProgram.startAt).format('HH:mm')} - {Moment(selectedProgram.endAt).format('HH:mm')} <Badge variant="success">{selectedTerm.name}</Badge></h6>
        </div>
        {loading ? this.renderLoading() : this.renderDates()}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  fetchLessonDates: () => dispatch(fetchLessonDates()),
  selectDate: (date, paymentType) => {
    analytics.event('select_time', {
      date,
    });
    dispatch(selectDate(date));
    dispatch(selectPaymentType(paymentType));
    dispatch(changeScreen({ path: PATH.BOOKING_SUMMARY, params: { paymentTypeId: paymentType.id, startDate: date } }));
  },
});

const mapStateToProps = (state: IAppState): IStateProps => {
  const screenState = selectState(state).screens.chooseDate;

  return {
    loading: screenState.loading,
    lessonDates: screenState.lessonDates,
    selectedProgram: selectState(state).selectedProgram as IProgram,
    selectedTerm: selectedTerm(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withValidation(
    LessonDates,
    (props) => Boolean(props.selectedProgram),
    () => <Redirect to={PATH.PARENT_SEARCH} />,
  ),
);
