import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { IAppState } from 'store';

import { fetchAttendanceData, toggleAttendance } from './manage-attendance.actions';
import LessionAttendanceComponent, { IDispatchProps, IStateProps } from './manage-attendance.component';
import { selectState } from './manage-attendance.selectors';

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  fetchAttendanceData: (programId: number) => dispatch(fetchAttendanceData(programId)),
  onToggleAttendance: (item) => dispatch(toggleAttendance(item)),
});

const mapStateToProps = (state: IAppState): IStateProps => ({
  loading: selectState(state).loading,
  program: selectState(state).program,
  attendance: selectState(state).attendance,
  lessonDates: selectState(state).lessonDates,
});

export default connect(mapStateToProps, mapDispatchToProps)(LessionAttendanceComponent);
