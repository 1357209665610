import React from 'react';

import { withLayout } from '@app/layouts';
import { Redirect } from 'react-router';

const HomeComponent = () => {
  return <Redirect to="/manual-enrolment" />;
  // return <p>Home page</p>;
};

export default withLayout(HomeComponent);
