import { createAction } from 'redux-actions';

import { ILessonPayment } from '@app/types';

const ACTION_PREFIX = '@screen/manual-enrolment-complete';
const getActionName = (name: string) => `${ACTION_PREFIX}/${name}`;

export const fetchPayment = createAction<number>(getActionName('FETCH_PAYMENT'));
export const fetchPaymentSuccess = createAction<ILessonPayment>(getActionName('FETCH_PAYMENT_SUCCESS'));
export const fetchPaymentFail = createAction(getActionName('FETCH_PAYMENT_FAIL'));
