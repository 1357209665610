import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { IAppState } from 'store';

import { IFetchFranchisorParam } from 'types';
import { fetchFranchisor } from './login-organization.actions';
import LoginOrganizationComponent, { IDispatchProps, IStateProps } from './login-organization.component';

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  fetchFranchisor: (params: IFetchFranchisorParam) => dispatch(fetchFranchisor(params)),
});

const mapStateToProps = (state: IAppState): IStateProps => {
  const screenState = state.screen.loginOrganization;
  return {
    isFetchingFranchisor: screenState.isFetchingFranchisor,
    errorMessage: screenState.errorMessage,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginOrganizationComponent);
