import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';

interface IProps {
  children: React.ReactElement;
  title: string;
  position?: 'top' | 'bottom' | 'left' | 'right';
}

class Tooltip extends React.Component<IProps> {
  static defaultProps = {
    position: 'top',
  };

  componentDidMount() {
    $(ReactDOM.findDOMNode(this)).tooltip();
  }

  render() {
    const { children, position, title } = this.props;
    const childrenProps = children.props;

    const tooltipAttributes = {
      'data-toggle': 'tooltip',
      'data-placement': position,
      'data-original-title': title,
    };

    return React.cloneElement(this.props.children, { ...childrenProps, ...tooltipAttributes });
  }
}

export default Tooltip;
