import React, { Component } from 'react';
import { Provider } from 'react-redux';

import { configureStore, store } from 'store';
import { appSaga, sagaMiddleware } from 'store/effects';

import './polyfill';
import './scss/index.scss';
import './vendor';

import { appendScripts } from './helpers/script.helper';
import Root from './pages';
import MessageBox from './shared-components/message-box';

appendScripts([
  'https://js.stripe.com/v3/',
// tslint:disable-next-line:no-console
]).catch(error => console.log(error));

interface IState {
  loading: boolean;
  loadingFailed: boolean;
}

class App extends Component<any, IState> {
  state: IState = {
    loading: true,
    loadingFailed: false,
  };

  async componentDidMount() {
    try {
      configureStore(sagaMiddleware);
      sagaMiddleware.run(appSaga);

      this.setState({
        loading: false,
      });
    } catch {
      this.setState({
        loading: false,
        loadingFailed: true,
      });
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <p>Loading...</p>
      );
    }

    if (this.state.loadingFailed) {
      return (
        <p>Loading failed!</p>
      );
    }

    return (
      <Provider store={store}>
        <Root />
        <MessageBox />
      </Provider>
    );
  }
}

export default App;
