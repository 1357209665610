import Config from '@app/config';
import httpHelpers from '@app/helpers/http.helpers';

export const forgotPasswordAPI = async (username: string): Promise<string> => {
  try {
    const result = await httpHelpers.post(`${Config.SERVER_URL}/api/passwordReset`, {
      username,
    });

    return result.email;
  } catch (error) {
    if (error.data && error.data.modelState) {
      const messages = Object.keys(error.data.modelState).map(key => error.data.modelState[key]);
      throw new Error(messages[0]);
    } else {
      throw error;
    }
  }
};
