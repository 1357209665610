import { refreshAuthInfo } from '@app/actions/auth';
import { IAuthInfo } from '@app/types';
import { IAuthState } from '../reducers/auth';
import { IRootState } from '../reducers/root';
import { IAppState, store } from '../store';

export const STATE_KEY = 'bookingautopilot_state';
export const AUTH_KEY = 'bookingautopilot_auth';
let localState: ILocalStorageSync = null;

interface ILocalStorageSync {
    auth: IAuthState;
    root: IRootState;
}

// writing some redux state data to local storage.
export const syncStateToLocalStorage = (state: IAppState) => {
    const localStorageState: ILocalStorageSync = {
        auth: {
            ...state.auth,
            authInfo: null,
            isAuthenticating: false,
        },
        root: {
            ...state.root,
            initialized: false,
            globalMessage: null,
        },
    };
    window.localStorage.setItem(STATE_KEY, JSON.stringify(localStorageState));
};

export const syncAuthToLocalStorage = (state: IAuthInfo) => {
    window.localStorage.setItem(AUTH_KEY, JSON.stringify(state));
};

const getLocalStorageStorage = window.localStorage.getItem(STATE_KEY);

// Getting local state from local storage
export const getStateFromLocalStorage = (): ILocalStorageSync => {
    if (localState) {
        return localState;
    }
    try {
        localState = JSON.parse(getLocalStorageStorage);
        return localState;
    } catch (err) {
        // tslint:disable-next-line: no-console
        console.error('error while parsing loca lstorage', err);
        return undefined;
    }
};

const getAuthInfoFromLocalStorage = (): IAuthInfo => {
    const dataString = window.localStorage.getItem(AUTH_KEY);

    try {
        if (dataString) {
            return JSON.parse(dataString);
        }
    } catch {
        return null;
    }
};

export const getAuthState = (initialState: IAuthState): IAuthState => {
    const state = getStateFromLocalStorage();
    const authInfo = getAuthInfoFromLocalStorage();
    if (state) {
        return {
            ...state.auth,
            authInfo,
            authenticated: Boolean(authInfo),
            isAuthenticating: false,
        };
    }
    return initialState;
};

export const getRootState = (initialState: IRootState): IRootState => {
    const state = getStateFromLocalStorage();
    if (state) {
        return {
            ...state.root,
        };
    }
    return initialState;
};

setInterval(() => {
    // One way -> local storage to redux only
    const authInfo = getAuthInfoFromLocalStorage();
    const reduxAuthInfo = store.getState().auth.authInfo;

    if (JSON.stringify(authInfo) !== JSON.stringify(reduxAuthInfo)) {
        store.dispatch(refreshAuthInfo(authInfo));
    }

}, 1000);
