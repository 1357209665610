import { Action } from 'redux-actions';
import { put, takeLatest } from 'redux-saga/effects';

import { changeCenter as authChangeCenter } from 'actions/auth';
import Config from 'config';
import httpHelpers from 'helpers/http.helpers';
import { store } from 'store';
import { IChildLesson, IWeeklyProgram } from 'types';
import { changeCenter, fetchAttendanceSuccess, fetchWeeklyPrograms, fetchWeeklyProgramsSuccess, setSelectedProgram, toggleAttendance, toggleAttendanceSuccess } from './lesson-attendance.actions';

function* fetchWeeklyProgramsSaga() {
  try {
    const center = store.getState().auth.selectedCenter;
    const term = store.getState().auth.selectedTerm;
    const programs: IWeeklyProgram[] = yield httpHelpers.get<IWeeklyProgram[]>(`${Config.SERVER_URL}/api/weeklyPrograms/unpaged?termId=${term.id}&centerId=${center.id}`);
    yield put(fetchWeeklyProgramsSuccess(programs));
  } catch (err) {
    //
  }
}

function* setSelectedProgramSaga(action: Action<IWeeklyProgram>) {
  try {
    if (!action.payload) {
      return;
    }

    const attedance: IChildLesson[] = yield httpHelpers.get<IChildLesson[]>(`${Config.SERVER_URL}/api/childLessons/unpaged?weeklyProgramId=${action.payload.id}&canceled=false`);
    yield put(fetchAttendanceSuccess(attedance));
  } catch (err) {
    //
  }
}

function* toggleAttendanceSaga(action: Action<IChildLesson>) {
  try {
    yield httpHelpers.put<any, IChildLesson>(`${Config.SERVER_URL}/api/childLessons/${action.payload.id}`, {
      ...action.payload,
      isAttended: !action.payload.isAttended,
    });
    yield put(toggleAttendanceSuccess(action.payload));
  } catch (err) {
    //
  }
}

function* changeCenterSaga(action: Action<any>) {
  try {
    yield put(authChangeCenter(action.payload));
    yield put(fetchWeeklyPrograms());
  } catch (err) {
    //
  }
}

export default [
  takeLatest(fetchWeeklyPrograms.toString(), fetchWeeklyProgramsSaga),
  takeLatest(setSelectedProgram.toString(), setSelectedProgramSaga),
  takeLatest(toggleAttendance.toString(), toggleAttendanceSaga),
  takeLatest(changeCenter.toString(), changeCenterSaga),
];
