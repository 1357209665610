import { formatCurrency } from '@app/helpers/number.helpers';
import { IPayment } from '@app/types';
import React from 'react';
import { Table } from 'react-bootstrap';

interface IProps {
  payment: IPayment;
}

const PaymentInfo: React.FC<IProps> = (props) => {
  const { payment } = props;
  return (
    <div className="card card-body">
      <h5 className="text-uppercase mt-0">Order summary</h5>
      <Table className="mt-1">
        <thead>
          <tr className="bg-light">
            <th>Description</th>
            <th className="text-right">Price</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Lessons</td>
            <td className="text-right">{formatCurrency(payment.subtotal)}</td>
          </tr>
          {Boolean(payment.discount) && (
            <tr>
              <td>Discount</td>
              <td className="text-right">{formatCurrency(payment.discount)}</td>
            </tr>
          )}
          {Boolean(payment.paymentMethodFee) && (
            <tr>
              <td>Payment fee</td>
              <td className="text-right">{formatCurrency(payment.paymentMethodFee)}</td>
            </tr>
          )}
          <tr>
            <td className="font-weight-bold">Total</td>
            <td className="text-right font-weight-bold">{formatCurrency(payment.total)}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default PaymentInfo;
