import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';

import { Button, ButtonGroup, Card, View } from 'components';
import { IChildLesson } from 'types';

import ProgramAttendanceListItem from './program-attendance-list-item.component';

// const data = [{
//   name: 'John Smith',
//   parentName: 'Will Smith',
//   photoConsent: false,
//   status: ['Pending'],
//   icons: ['drop', 'bus'],
//   done: false,
// }, {
//   name: 'John Smith',
//   parentName: 'Will Smith',
//   photoConsent: false,
//   status: ['Makeup'],
//   icons: ['drop'],
//   done: true,
// }, {
//   name: 'John Smith',
//   parentName: 'Will Smith',
//   photoConsent: false,
//   status: ['Makeup', 'New'],
//   icons: [],
//   done: false,
// }, {
//   name: 'John Smith',
//   parentName: 'Will Smith',
//   photoConsent: true,
//   status: ['Trial'],
//   icons: ['drop', 'bus'],
//   done: false,
// }, {
//   name: 'John Smith',
//   parentName: 'Will Smith',
//   photoConsent: false,
//   status: ['Pending', 'Unpaid'],
//   icons: ['drop', 'bus'],
//   done: false,
// }];

interface IProps {
  isLoading: boolean;
  attendance: IChildLesson[];
  onToggleAttendance: (item: IChildLesson) => void;
}

interface IState {
  viewMode: ViewMode;
}

type ViewMode = 'attendance' | 'notes';

class ProgramAttendace extends Component<IProps, IState> {
  state: IState = {
    viewMode: 'attendance',
  };

  handleChangeViewMode = (viewMode: ViewMode) => {
    this.setState({
      viewMode,
    });
  }

  renderAttendanceList() {
    // console.log(this.props.attendance);
    const { attendance } = this.props;

    return (
      <View>
        {attendance.map((item, index) => (
          <ProgramAttendanceListItem
            key={String(index)}
            // name={item.name}
            // parentName={item.parentName}
            // icons={item.icons as any[]}
            // labels={item.status}
            // photoConsent={item.photoConsent}
            // completed={item.done}
            name={item.child.name}
            parentName={item.child.parent.name}
            icons={[]}
            labels={['Pending']}
            photoConsent={false}
            completed={item.isAttended}
            onToggleAttendance={() => this.props.onToggleAttendance(item)}
          />
        ))}
      </View>
    );
  }

  renderNotes() {
    return (
      <div>
        <div className="form-group">
          <label>Attendance Notes</label>
          <textarea
            className="form-control"
            required
            placeholder="Write something here"
            rows={6}
          ></textarea>
        </div>
        <div className="text-right">
          <Button variant="light" className="ml-2">Cancel</Button>
          <Button className="ml-2">Create</Button>
        </div>
      </div>
    );
  }

  renderLoading() {
    return (
      <View flexGrow alignItems="center" className="justify-content-center">
        <Spinner animation="border" />
      </View>
    );
  }

  renderBody() {
    const { viewMode } = this.state;
    const { isLoading } = this.props;

    if (isLoading) {
      return this.renderLoading();
    }

    if (viewMode === 'notes') {
      return this.renderNotes();
    }

    return this.renderAttendanceList();
  }

  render() {
    const { viewMode } = this.state;

    return (
      <Card.Body className="d-flex flex-column flex-grow-1">
        <View flexDirection="row" className="mb-2">
          <View>
            <ButtonGroup>
              <Button
                variant={viewMode === 'attendance' ? 'primary' : 'light'}
                className={viewMode === 'attendance' ? 'rounded' : 'text-muted'}
                onClick={() => this.handleChangeViewMode('attendance')}
              >
                Attendance
              </Button>
              <Button
                variant={viewMode === 'notes' ? 'primary' : 'light'}
                className={viewMode === 'notes' ? 'rounded' : 'text-muted'}
                onClick={() => this.handleChangeViewMode('notes')}
              >
                Notes
              </Button>
            </ButtonGroup>
          </View>
          <View flexGrow />
          <View flexDirection="row">
            <Button variant="light" className="ml-1"><i className="h4 mdi mdi-lock-open-outline m-0 text-muted"></i></Button>
            <Button className="ml-1">Manage</Button>
          </View>
        </View>
        <Card.Text className="text-muted">
          Unlock the attendance list to active the attendance mode. To mark the attendance, tap the icon on the right.
        </Card.Text>
        {this.renderBody()}
      </Card.Body>
    );
  }
}

export default ProgramAttendace;
