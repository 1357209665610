import React from 'react';
import { Spinner } from 'react-bootstrap';

interface IProps {
  visible: boolean;
}

const LoadingOverlay: React.FC<IProps> = (props) => props.visible ? (
  <div
    className="align-items-center bg-secondary-lighten d-flex justify-content-center vh-100 vw-100 position-fixed"
    style={{ left: 0, right: 0, top: 0, bottom: 0 }}
  >
    <Spinner animation="border" />
  </div>
) : null;

export default LoadingOverlay;
