import { createAction } from 'redux-actions';
import { IAppData, IAuthInfo, ICenter, IFranchisor, ILoginParam, ITerm } from 'types';
import history from '../helpers/history.helper';

export const login = createAction<ILoginParam>('@auth/LOGIN');
export const loginSuccess = createAction<IAuthInfo>('@auth/LOGIN_SUCCESS');
export const loginFail = createAction('@auth/LOGIN_FAILED');
export const setIsAuthenticating = createAction<boolean>('@auth/SET_IS_AUTHENTICATING');
export const logout = createAction('@auth/LOGOUT', (keepRedirectUrl: boolean) => {
  if (keepRedirectUrl && history.location.pathname !== '/') {
    history.replace(`/login?redirect=${encodeURIComponent(history.location.pathname)}`);
  } else {
    history.replace('/login');
  }
});
export const tokenRefreshed = createAction<IAuthInfo>('@auth/TOKEN_REFRESHED');
export const refreshAuthInfo = createAction<IAuthInfo>('@auth/REFRESH_AUTH_INFO');

export const fetchFranchisorSuccess = createAction<IAppData<IFranchisor>>('@auth/FETCH_FRANCHISOR_SUCCESS');

export const changeFranchisor = createAction('@auth/CHANGE_FRANCHISOR');

export const fetchCenters = createAction('@auth/FETCH_CENTERS');
export const fetchCentersSuccess = createAction<IAppData<ICenter[]>>('@auth/FETCH_CENTERS_SUCCESS');
export const fetchTerms = createAction('@auth/FETCH_TERMS');
export const fetchTermsSuccess = createAction<IAppData<ITerm[]>>('@auth/FETCH_TERMS_SUCCESS');
export const changeCenter = createAction('@auth/CHANGE_CENTER');
export const changeTerm = createAction('@auth/CHANGE_TERM');
