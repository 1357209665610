import { IAppState } from '@app/store';
import classNames from 'classnames';
import React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { changeScreen } from '../workshop-enrolment.actions';
import { PATH_PARENT } from '../workshop-enrolment.constants';
import { selectState } from '../workshop-enrolment.selectors';
import { IChangeScreen } from '../workshop-enrolment.types';

interface IStateProps {
  step: number;
  path: string;
}

interface IDispatchProps {
  changeScreen: (params: IChangeScreen) => void;
}

const Stepper: React.FC<IStateProps & IDispatchProps> = (props) => {
  const { step } = props;

  return (
    <div className="d-flex flex-row align-items-start flex-grow-1 mt-4">
      <div style={{ width: 48, height: 48 }} className="d-flex flex-row align-items-center">
        {Boolean(PATH_PARENT[props.path]) && (
          <Button variant="info" className="rounded-circle font-weight-bold" style={{ width: 48, height: 48 }} onClick={() => props.changeScreen({ path: PATH_PARENT[props.path], replace: true })}>
            <i className="mdi mdi-arrow-left h4"></i>
          </Button>
        )}
      </div>
      <div className="d-flex flex-grow-1 flex-basis-0 position-relative">
        <div className="d-flex flex-column align-items-center flex-grow-1">
          <Button variant={step < 0 ? 'light' : step > 0 ? 'success' : 'primary'} className={classNames('rounded-circle font-weight-bold', { 'text-muted': step < 0 })} disabled={step < 0} style={{ width: 48, height: 48 }}>
            1
          </Button>
          <p className="text-muted text-center mt-2">Find Parent</p>
        </div>
      </div>
      <div className="d-flex flex-grow-1 flex-basis-0 position-relative">
        <div className="flex-fill" style={{ top: 24, left: 'calc(-50% + 40px)', right: 'calc(50% + 40px)', position: 'absolute' }}>
          <hr className={classNames('mt-0', step < 1 ? 'border-light' : step > 1 ? 'border-success' : 'border-primary')} style={{ borderWidth: 2 }} />
        </div>
        <div className="d-flex flex-column align-items-center flex-grow-1">
          <Button variant={step < 1 ? 'light' : step > 1 ? 'success' : 'primary'} className={classNames('rounded-circle font-weight-bold', { 'text-muted': step < 1 })} disabled={step < 1} style={{ width: 48, height: 48 }}>
            2
          </Button>
          <p className="text-muted text-center mt-2">Select workshop</p>
        </div>
      </div>
      <div className="d-flex flex-grow-1 flex-basis-0 position-relative">
        <div className="flex-fill" style={{ top: 24, left: 'calc(-50% + 40px)', right: 'calc(50% + 40px)', position: 'absolute' }}>
          <hr className={classNames('mt-0', step < 2 ? 'border-light' : step > 2 ? 'border-success' : 'border-primary')} style={{ borderWidth: 2 }} />
        </div>
        <div className="d-flex flex-column align-items-center flex-grow-1">
          <Button variant={step < 2 ? 'light' : step > 2 ? 'success' : 'primary'} className={classNames('rounded-circle font-weight-bold', { 'text-muted': step < 2 })} disabled={step < 2} style={{ width: 48, height: 48 }}>
            3
          </Button>
          <p className="text-muted text-center mt-2">Confirm booking</p>
        </div>
      </div>
      <div style={{ width: 40, height: 40 }} />
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  changeScreen: (params) => dispatch(changeScreen(params)),
});

const mapStateToProps = (state: IAppState): IStateProps => ({
  step: selectState(state).step,
  path: selectState(state).path,
});

export default connect(mapStateToProps, mapDispatchToProps)(Stepper);
