import { createAction } from 'redux-actions';
import { IChildLesson, IWeeklyProgram } from 'types';
import { ILessonDates } from './manage-attendance.types';

const ACTION_PREFIX = '@screen/manage-attendance';
const getActionName = (name: string) => `${ACTION_PREFIX}/${name}`;

export const setLoadingState = createAction<boolean>(getActionName('SET_LOADING_STATE'));

export const fetchAttendanceData = createAction<number>(getActionName('FETCH_ATTENDANCE_DATA'));

export const fetchWeeklyProgramSuccess = createAction<IWeeklyProgram>(getActionName('FETCH_WEEKLY_PROGRAM_SUCCESS'));
export const fetchLessonDatesSuccess = createAction<ILessonDates>(getActionName('FETCH_LESSON_DATES_SUCCESS'));

export const fetchAttendanceSuccess = createAction<IChildLesson[]>(getActionName('FETCH_ATTENDANCE_SUCCESS'));

export const toggleAttendance = createAction<IChildLesson>(getActionName('TOGGLE_ATTENDANCE'));
export const toggleAttendanceSuccess = createAction<IChildLesson>(getActionName('TOGGLE_ATTENDANCE_SUCCESS'));
