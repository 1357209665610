import { createAction } from 'redux-actions';
import { IChild, IParentSummary, IEnrolment } from './parent-details.types';

const ACTION_PREFIX = '@screen/parent-details';
const getActionName = (name: string) => `${ACTION_PREFIX}/${name}`;

export const selectChild = createAction<IChild>(getActionName('SELECT_CHILD'));
export const setSelectedChild = createAction<IChild>(getActionName('SET_SELECTED_CHILD'));

export const fetchChilds = createAction(getActionName('FETCH_CHILDS'));
export const fetchChildsSuccess = createAction(getActionName('FETCH_CHILDS_SUCCESS'));

export const setSelectedParent = createAction(getActionName('SET_SELECTED_PARENT'));
export const fetchParentSummary = createAction(getActionName('FETCH_PARENT_SUMMARY'));
export const setSelectedParentSummary = createAction<IParentSummary>(getActionName('SET_SELECTED_PARENT_SUMMARY'));

export const fetchCurrentEnrolment = createAction(getActionName('FETCH_CURRENT_ENROLMENT'));
export const setCurrentEnrolment = createAction<IEnrolment[]>(getActionName('SET_CURRENT_ENROLMENT'));
export const setCurrentEnrolmentLoading = createAction<boolean>(getActionName('SET_CURRENT_ENROLMENT_LOADING'));


