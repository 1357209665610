/* eslint-disable import/first */
import $ from 'jquery';
import 'bootstrap';
window.jQuery = $;
window.$ = $;
global.jQuery = $;

// import 'jquery-slimscroll';

const slimscroll = require('jquery-slimscroll');

export {
  slimscroll,
}
