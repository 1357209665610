import { FormikErrors, FormikTouched } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { IFormValueCreateParent } from './create-parent.types';

export const shouldControlShowError = <T extends {}>(errors: FormikErrors<T>, touched: FormikTouched<T>, submitCount: number, fieldName: keyof T) => {
  return Boolean(errors) && Boolean(errors[fieldName]) && (submitCount > 0 || (touched && touched[fieldName]));
};

export const shouldChildControlShowError = <T extends {}>(index: number, childErrors: Array<FormikErrors<T>>, childTouched: Array<FormikTouched<T>>, submitCount: number, fieldName: keyof T) => {
  if (!childErrors) {
    return false;
  }

  return shouldControlShowError(childErrors[index] || {}, (childTouched || [])[index] || {}, submitCount, fieldName);
};

export const ControlError: React.FC<{ errorMessage: string }> = ({ errorMessage }) => (
  <span className="invalid-feedback">{errorMessage}</span>
);

interface IChildControlErrosProps<T> {
  childs: Array<FormikErrors<T>>;
  index: number;
  fieldName: keyof T;
}

export const ChildControlError = <T extends {}>({ childs, index, fieldName }: IChildControlErrosProps<T>): React.ReactElement => {
  if (!childs || !childs[index] || !childs[index][fieldName]) {
    return null;
  }

  return <ControlError errorMessage={childs[index][fieldName] as string} />;
};

export const initChildValue = {
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  genderTypeId: 1,
};

export const initFormValue: IFormValueCreateParent = {
  firstName: '',
  lastName: '',
  centerId: 0,
  address: '',
  city: '',
  postcode: '',
  childRelationship: '',
  email: '',
  phoneNumber: '',
  childs: [{ ...initChildValue }],
};

export const formValidationSchema = Yup.object().shape<IFormValueCreateParent>({
  firstName: Yup.string().required('Please enter firstname'),
  lastName: Yup.string().required('Please enter lastname'),
  centerId: Yup.number().positive('Please select center'),
  city: Yup.string().required('Please enter city'),
  postcode: Yup.string().required('Please enter postcode'),
  email: Yup.string().email('Please enter valid email').required('Please enter email'),
  phoneNumber: Yup.string().required('Please enter phone number'),
  childs: Yup.array().of(
    Yup.object({
      firstName: Yup.string().required('Please enter firstname'),
      lastName: Yup.string().required('Please enter firstname'),
      dateOfBirth: Yup.string().required('Please enter firstname'),
      genderTypeId: Yup.number().required('Please enter firstname'),
    }),
  ),
});
