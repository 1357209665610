import Config from '@app/config';
import httpHelpers from '@app/helpers/http.helpers';
import { ILessonPayment } from '@app/types';
import { Action } from 'redux-actions';
import { put, takeLatest } from 'redux-saga/effects';
import { fetchPayment, fetchPaymentFail, fetchPaymentSuccess } from './manual-enrolment-complete.actions';

function* fetchPaymentSaga(action: Action<number>) {
  try {
    const payment: ILessonPayment = yield httpHelpers.get(`${Config.SERVER_URL}/api/lessonPayments/${action.payload}`);

    if (payment && (payment.status.id === 1 || payment.status.id === 6)) {
      const paymentMethods = yield httpHelpers.get(`${Config.SERVER_URL}/api/booking/payment?centerId=${payment.centerId}`);
      payment.paymentMethod = paymentMethods.find(x => x.name === payment.paymentMethod.name);
    }

    yield put(fetchPaymentSuccess(payment));
  } catch (err) {
    yield put(fetchPaymentFail());
  }
}

export default [
  takeLatest(fetchPayment.toString(), fetchPaymentSaga),
];
