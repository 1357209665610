import ReducerFactory from '@app/reducers/ReducerFactory';
import { IChild, IParentSummary, IEnrolment } from './parent-details.types';
import { IParent } from '../parent-search/parent-search.types';
import { logout } from '@app/actions/auth';

import {
    fetchChilds,
    fetchChildsSuccess,
    selectChild,
    setCurrentEnrolment,
    setCurrentEnrolmentLoading,
    setSelectedChild,
    setSelectedParentSummary,
    setSelectedParent
} from './parent-details.actions';

export interface IParentDetailsState {
    selectedParent: IParent;
    selectedParentSummary: IParentSummary;
    selectedChild: IChild;
    childs: IChild[];
    currentEnrolments: IEnrolment[];
    loadingEnrolments: boolean;
    loading: boolean;
};

const initState: IParentDetailsState = {
    selectedParent: null,
    selectedParentSummary: null,
    selectedChild: null,
    childs: [],
    currentEnrolments: [],
    loadingEnrolments: false,
    loading: false,
};

const reducer = new ReducerFactory(initState)
    .add(logout, (state, action) => {
        return initState;
    })
    .add(fetchChilds, (state, action) => {
        return {
            ...state,
            loading: true
        };
    })
    .add(fetchChildsSuccess, (state, action) => {
        return {
            ...state,
            loading: false,
            childs: action.payload
        };
    })
    .add(selectChild, (state, action) => {
        return {
            ...state,
            selectedChild: action.payload,
        };
    })
    .add(setSelectedChild, (state, action) => {
        return {
            ...state,
            selectedChild: action.payload,
        };
    })
    .add(setSelectedParentSummary, (state, action) => {
        return {
            ...state,
            selectedParentSummary: action.payload,
        };
    })
    .add(setSelectedParent, (state, action) => {
        return {
            ...state,
            selectedParent: action.payload,
        };
    })
    .add(setCurrentEnrolment, (state, action) => {
        return {
            ...state,
            currentEnrolments: action.payload,
        };
    })
    .add(setCurrentEnrolmentLoading, (state, action) => {
        return {
            ...state,
            loadingEnrolments: action.payload,
        };
    })
    .toReducer();

export default reducer;
