import { SVGImage } from 'components';
import React from 'react';

const LoadingApp: React.FC = () => (
  <div className="p-1 d-flex flex-column align-items-center justify-content-center vw-100 vh-100">
    <SVGImage src={require('assets/images/logo-blue.svg')} height={72} />
    <div className="spinner-grow text-primary mt-5" role="status"></div>
  </div>
);

export default LoadingApp;
