import { createAction } from 'redux-actions';

import {
  IChangeScreen, IRouteParams,
} from './workshop-enrolment.types';
import { IChild, IParent } from '../../shared-components/parent-search/parent-search.types';
import { IWorkshop, IWorkshopSession } from 'types/IWorkshop';
import {
  IFormValueCreateChild,
  IFormValueCreateParent
} from '../../shared-components/create-parent/create-parent.types';
import { IPaginationResponse } from 'types/common';
import { IMakePayment, IPayment, IPaymentMethod } from '@app/types';

const ACTION_PREFIX = '@screen/workshop-enrolment';
const getActionName = (name: string) => `${ACTION_PREFIX}/${name}`;

export const changeStep = createAction<number>(getActionName('CHANGE_STEP'));
export const updateCurrentPath = createAction<string>(getActionName('UPDATE_CURRENT_PATH'));
export const changeScreen = createAction<IChangeScreen>(getActionName('CHANGE_SCREEN'));

export const selectParent = createAction<IParent>(getActionName('SELECT_PARENT'));
export const selectChild = createAction<IChild>(getActionName('SELECT_CHILD'));
export const selectWorkshop = createAction<IWorkshop>(getActionName('SELECT_WORKSHOP'));
export const selectWorkshopSession = createAction<IWorkshopSession>(getActionName('SELECT_WORKSHOP_SESSION'));
export const unselectWorkshopSession = createAction<IWorkshopSession>(getActionName('UNSELECT_WORKSHOP_SESSION'));

export const setLoaderVisibleState = createAction<boolean>(getActionName('SET_LOADER_VISIBLE_STATE'));

export const initLoad = createAction<IRouteParams>(getActionName('INIT_LOAD'));
export const initLoadSuccess = createAction(getActionName('INIT_LOAD_SUCCESS'));
export const initLoadFail = createAction(getActionName('INIT_LOAD_FAIL'));

export const setSelectedParent = createAction<IParent>(getActionName('SET_SELECTED_PARENT'));
export const setSelectedChild = createAction<IChild>(getActionName('SET_SELECTED_CHILD'));
export const setSelectedWorkshop = createAction<IWorkshop>(getActionName('SET_SELECTED_WORKSHOP'));
export const setSelectedWorkshopSessions = createAction<IWorkshopSession[]>(getActionName('SET_SELECTED_WORKSHOP_SESSIONS'));

export const createChild = createAction<IFormValueCreateChild>(getActionName('CREATE_CHILD'));
export const createChildSuccess = createAction<IChild>(getActionName('CREATE_CHILD_SUCCESS'));
export const createChildFailed = createAction(getActionName('CREATE_CHILD_FAILED'));

export const createParent = createAction<IFormValueCreateParent>(getActionName('CREATE_PARENT'));
export const createParentSuccess = createAction(getActionName('CREATE_PARENT_SUCCESS'));
export const createParentFailed = createAction(getActionName('CREATE_PARENT_FAILED'));

export const changeCenter = createAction(getActionName('CHANGE_CENTER'));

export const fetchWorkshops = createAction(getActionName('FETCH_WORKSHOPS'));
export const fetchWorkshopsSuccess = createAction<IPaginationResponse<IWorkshop>>(getActionName('FETCH_WORKSHOPS_SUCCESS'));
export const fetchWorkshopsMore = createAction(getActionName('FETCH_WORKSHOPS_MORE'));
export const fetchWorkshopsMoreSuccess = createAction<IPaginationResponse<IWorkshop>>(getActionName('FETCH_WORKSHOPS_MORE_SUCCESS'));

export const fetchWorkshopSessions = createAction(getActionName('FETCH_WORKSHOP_SESSIONS'));
export const fetchWorkshopSessionsSuccess = createAction<IWorkshopSession[]>(getActionName('FETCH_WORKSHOP_SESSIONS_SUCCESS'));

export const fetchPaymentMethods = createAction(getActionName('FETCH_PAYMENT_METHODS'));
export const fetchPaymentMethodsSuccess = createAction<IPaymentMethod[]>(getActionName('FETCH_PAYMENT_METHODS_SUCCESS'));

export const startBooking = createAction(getActionName('START_BOOKING'));
export const selectPaymentMethod = createAction<IPaymentMethod>(getActionName('SELECT_PAYMENT_METHOD'));
export const fetchPayment = createAction<number>(getActionName('FETCH_PAYMENT'));
export const fetchPaymentSuccess = createAction<IPayment>(getActionName('FETCH_PAYMENT_SUCCESS'));

export const applyPromocode = createAction<string>(getActionName('APPLY_PROMOCODE'));
export const applyPromocodeSuccess = createAction<string>(getActionName('APPLY_PROMOCODE_SUCCESS'));
export const applyPromocodeFail = createAction(getActionName('APPLY_PROMOCODE_FAIL'));
export const removePromocode = createAction<string>(getActionName('REMOVE_PROMOCODE'));
export const removePromocodeSuccess = createAction<string>(getActionName('REMOVE_PROMOCODE_SUCCESS'));
export const removePromocodeFail = createAction(getActionName('REMOVE_PROMOCODE_FAIL'));

export const makePayment = createAction<IMakePayment>(getActionName('MAKE_PAYMENT'));
export const makePaymentSuccess = createAction(getActionName('MAKE_PAYMENT_SUCCESS'));

