import { createAction } from 'redux-actions';

import { DateType, IPayment, IPaymentMethod, IWeeklyProgram, IMakePayment } from '@app/types';
import {
  IChangeScreen,
  IChild,
  IChildrenPaymentTypeDetail,
  IParent,
  IProgramDate,
  IRouteParams,
  ISetLessonDatePaymentOptionsParam,
} from './manual-enrolment.types';
import {
  IFormValueCreateParent,
  IFormValueCreateChild,
} from '../../shared-components/create-parent/create-parent.types';

const ACTION_PREFIX = '@screen/manual-enrolment';
const getActionName = (name: string) => `${ACTION_PREFIX}/${name}`;

export const changeStep = createAction<number>(getActionName('CHANGE_STEP'));
export const updateCurrentPath = createAction<string>(getActionName('UPDATE_CURRENT_PATH'));
export const changeScreen = createAction<IChangeScreen>(getActionName('CHANGE_SCREEN'));

export const fetchWeeklyPrograms = createAction(getActionName('FETCH_WEEKLY_PROGRAMS'));
export const fetchWeeklyProgramsSuccess = createAction<IWeeklyProgram[]>(getActionName('FETCH_WEEKLY_PROGRAMS_SUCCESS'));

export const selectParent = createAction<IParent>(getActionName('SELECT_PARENT'));
export const selectChild = createAction<IChild>(getActionName('SELECT_CHILD'));
export const changeCenter = createAction(getActionName('CHANGE_CENTER'));
export const changeTerm = createAction(getActionName('CHANGE_TERM'));
export const selectProgram = createAction<IWeeklyProgram>(getActionName('SELECT_PROGRAM'));
export const selectDate = createAction<DateType>(getActionName('SELECT_DATE'));
export const selectPaymentType = createAction<IChildrenPaymentTypeDetail>(getActionName('SELECT_PAYMENT_TYPE'));
export const selectPaymentMethod = createAction<IPaymentMethod>(getActionName('SELECT_PAYMENT_METHOD'));
export const setProgramAvailableState = createAction<boolean>(getActionName('SET_PROGRAM_AVAILABLE_STATE'));

export const setChildAgeError = createAction<string>(getActionName('SET_CHILD_AGE_ERROR'));
export const ignoreChildAgeWarning = createAction('IGNORE_CHILD_AGE_WARNING');

export const setSelectedParent = createAction<IParent>(getActionName('SET_SELECTED_PARENT'));
export const setSelectedChild = createAction<IChild>(getActionName('SET_SELECTED_CHILD'));
export const setSelectedProgram = createAction<IWeeklyProgram>(getActionName('SET_SELECTED_PROGRAM'));
export const setSelectedDate = createAction<DateType>(getActionName('SET_SELECTED_DATE'));
export const setSelectedPaymentType = createAction<IChildrenPaymentTypeDetail>(getActionName('SET_SELECTED_PAYMENT_TYPE'));

export const fetchPaymentMethods = createAction(getActionName('FETCH_PAYMENT_METHODS'));
export const fetchPaymentMethodsSuccess = createAction<IPaymentMethod[]>(getActionName('FETCH_PAYMENT_METHODS_SUCCESS'));

export const fetchChilds = createAction(getActionName('FETCH_CHILDS'));
export const fetchChildsSuccess = createAction(getActionName('FETCH_CHILDS_SUCCESS'));

export const fetchLessonDates = createAction(getActionName('FETCH_LESSON_DATES'));
export const fetchLessonDatesSuccess = createAction<IProgramDate[]>(getActionName('FETCH_LESSON_DATES_SUCCESS'));
export const setLessonDatePaymentOptions = createAction<ISetLessonDatePaymentOptionsParam>(getActionName('SET_LESSON_DATE_PAYMENT_OPTIONS'));

export const createChild = createAction<IFormValueCreateChild>(getActionName('CREATE_CHILD'));
export const createChildSuccess = createAction<IChild>(getActionName('CREATE_CHILD_SUCCESS'));
export const createChildFailed = createAction(getActionName('CREATE_CHILD_FAILED'));

export const createParent = createAction<IFormValueCreateParent>(getActionName('CREATE_PARENT'));
export const createParentSuccess = createAction(getActionName('CREATE_PARENT_SUCCESS'));
export const createParentFailed = createAction(getActionName('CREATE_PARENT_FAILED'));

// export const fetchBooking = createAction(getActionName('FETCH_BOOKING'));
// export const fetchBookingSuccess = createAction<IChildrenPaymentType[]>(getActionName('FETCH_BOOKING_SUCCESS'));
export const startBooking = createAction(getActionName('START_BOOKING'));
export const fetchPayment = createAction<number>(getActionName('FETCH_PAYMENT'));
export const fetchPaymentSuccess = createAction<IPayment>(getActionName('FETCH_PAYMENT_SUCCESS'));
export const makePayment = createAction<IMakePayment>(getActionName('MAKE_PAYMENT'));
export const makePaymentSuccess = createAction(getActionName('MAKE_PAYMENT_SUCCESS'));
export const applyPromocode = createAction<string>(getActionName('APPLY_PROMOCODE'));
export const applyPromocodeSuccess = createAction<string>(getActionName('APPLY_PROMOCODE_SUCCESS'));
export const applyPromocodeFail = createAction(getActionName('APPLY_PROMOCODE_FAIL'));
export const removePromocode = createAction<string>(getActionName('REMOVE_PROMOCODE'));
export const removePromocodeSuccess = createAction<string>(getActionName('REMOVE_PROMOCODE_SUCCESS'));
export const removePromocodeFail = createAction(getActionName('REMOVE_PROMOCODE_FAIL'));

export const setLoaderVisibleState = createAction<boolean>(getActionName('SET_LOADER_VISIBLE_STATE'));

export const initLoad = createAction<IRouteParams>(getActionName('INIT_LOAD'));
export const initLoadSuccess = createAction(getActionName('INIT_LOAD_SUCCESS'));
export const initLoadFail = createAction(getActionName('INIT_LOAD_FAIL'));
