import Config from '@app/config';
import classNames from 'classnames';
import { SVGImage } from 'components';
import { Formik, FormikErrors, FormikTouched } from 'formik';
import queryString from 'query-string';
import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';
import { Redirect, RouteChildrenProps } from 'react-router';
import * as yup from 'yup';
import { resetPasswordAPI } from './reset-password.helper';

const initialValues = {
  password: '',
  confirmPassword: '',
};

const formValidationSchema = yup.object().shape({
  password: yup.string().min(6, 'Must be at least 6 characters').required('New password is required'),
  confirmPassword: yup.string().required('Confirm password is required').oneOf([yup.ref('password'), null], 'Confirm passwords must match'),
});

const shouldControlShowError = <T extends {}>(errors: FormikErrors<T>, touched: FormikTouched<T>, submitCount: number, fieldName: keyof T) => {
  return Boolean(errors[fieldName]) && (submitCount > 0 || touched[fieldName]);
};

const ResetPassword: React.FC<RouteChildrenProps> = (props) => {
  const [resetSuccess, setResetSuccess] = useState(false);
  const [error, setError] = useState('');

  const resetToken = queryString.parse(window.location.search).passwordResetToken;

  if (resetSuccess || !resetToken) {
    return (
      <Redirect to="/login" />
    );
  }

  return (
    <div className="authentication-bg">
      <div className="account-pages pt-5 pb-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5">
              <div className="card">
                <div className="card-header pt-4 pb-4 text-center bg-primary">
                  <a href="/">
                    <span>
                      <SVGImage src={require('assets/images/logo.svg')} alt="" id="side-main-logo" />
                    </span>
                  </a>
                </div>

                <div className="card-body p-4">

                  <div className="text-center w-75 m-auto">
                    <h4 className="text-dark-50 text-center mt-0 font-weight-bold">Reset password</h4>
                    {/* <p className="text-muted mb-4">Enter your username below to reset your password.</p> */}
                  </div>

                  <Formik
                    initialValues={initialValues}
                    validationSchema={formValidationSchema}
                    isInitialValid={false}
                    onSubmit={async (values, { setSubmitting }) => {
                      setError('');
                      try {
                        await resetPasswordAPI({
                          ...values,
                          passwordResetToken: resetToken,
                        });
                        setResetSuccess(true);
                      } catch (error) {
                        setError(error.message);
                      }

                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      submitCount,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isValid,
                      isSubmitting,
                    }) => (
                        <form onSubmit={handleSubmit}>

                          <div className="form-group">
                            <label>New password</label>
                            <input
                              className={classNames('form-control', { 'is-invalid': shouldControlShowError(errors, touched, submitCount, 'password') })}
                              name="password"
                              type="password"
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <span className="invalid-feedback">{errors.password}</span>
                          </div>

                          <div className="form-group">
                            <label>Confirm password</label>
                            <input
                              className={classNames('form-control', { 'is-invalid': shouldControlShowError(errors, touched, submitCount, 'confirmPassword') })}
                              name="confirmPassword"
                              type="password"
                              value={values.confirmPassword}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <span className="invalid-feedback">{errors.confirmPassword}</span>
                          </div>

                          {Boolean(error) && (
                            <Alert variant="danger">
                              {error}
                            </Alert>
                          )}

                          <div className="mt-4 d-flex align-items-center flex-row justify-content-end">
                            <button className="btn btn-danger d-flex flex-row align-items-center" type="submit" disabled={isSubmitting || (!isValid && submitCount > 0)}>
                              Reset password
                            </button>
                          </div>

                        </form>
                      )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>

      <footer className="footer footer-alt">
        {Config.FOOTER_COPYRIGHT}
        <br />
        {Config.VERSION}
      </footer>
    </div>
  );
};

export default ResetPassword;
