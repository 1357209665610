import { IAppState } from '@app/store';
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import CreateParentForm from '../../../shared-components/create-parent/create-parent.component';
import { IFormValueCreateParent } from '../../../shared-components/create-parent/create-parent.types';
import { changeScreen, createParent } from '../manual-enrolment.actions';
import { PATH } from '../manual-enrolment.constants';
import { selectState } from '../manual-enrolment.selectors';
import { IChangeScreen } from '../manual-enrolment.types';

interface IDispatchProps {
  changeScreen: (params: IChangeScreen) => void;
  createParent: (formValue: IFormValueCreateParent) => void;
}

interface IStateProps {
  isSubmitting: boolean;
}

class CreateParent extends Component<IDispatchProps & IStateProps> {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Create parent - Manual Enrolments - Booking Autopilot</title>
        </Helmet>
        <div className="d-flex flex-row align-items-center">
          <div className="d-flex flex-row align-items-center flex-grow-1 mt-3 mb-2">
            <h4 className="mr-1">New Parent</h4>
          </div>
        </div>

        <CreateParentForm
          onCancel={() => this.props.changeScreen({ path: PATH.PARENT_SEARCH })}
          onCreateParent={this.props.createParent}
          isSubmitting={this.props.isSubmitting}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  changeScreen: (params) => dispatch(changeScreen(params)),
  createParent: (formValue) => dispatch(createParent(formValue)),
});

const mapStateToProps = (state: IAppState): IStateProps => ({
  isSubmitting: selectState(state).screens.createParent.isSubmitting,
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateParent);
