import * as Sentry from '@sentry/browser';
import Config from '../config';

const noop = () => null;

let init: (options: Sentry.BrowserOptions) => void = noop;
let captureMessage: (message: string, level?: Sentry.Severity) => string = noop;
let captureException: (exception: any) => string = noop;

if (!Config.DEBUG && Config.SENTRY_URL) {
  init = Sentry.init;
  captureMessage = Sentry.captureMessage;
  captureException = Sentry.captureException;
}

export default {
  init,
  captureMessage,
  captureException,
};
