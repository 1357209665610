export const appendScript = (src: string, async: boolean = true): Promise<string> => {
  const scriptElement = document.createElement('script');
  scriptElement.async = async;
  scriptElement.src = src;

  const promise: Promise<string> = new Promise((resolve, reject) => {
    scriptElement.addEventListener('load', () => resolve(src));
    scriptElement.addEventListener('error', e => reject(e));
  });

  document.body.appendChild(scriptElement);
  return promise;
};

export const appendScripts = async (links: string[], async: boolean = true) => {
  for (const src of links) {
    await appendScript(src, async);
  }
};
