import { Button, ButtonGroup, Card } from '@app/components';
import { selectCenters, selectedCenter } from '@app/selectors/auth';
import { selectLookup } from '@app/selectors/root';
import { IAppState } from '@app/store';
import { ICenter, ILookup } from '@app/types';
import classNames from 'classnames';
import { FieldArray, Formik } from 'formik';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IFormValueCreateParent } from './create-parent.types';
import { ChildControlError, ControlError, formValidationSchema, initChildValue, initFormValue, shouldChildControlShowError, shouldControlShowError } from './create-parent.helper';

interface IProps {
  onCancel: () => void;
  onCreateParent: (formValue: IFormValueCreateParent) => void;
  isSubmitting: boolean;
}

interface IDispatchProps { }

interface IStateProps {
  genders: ILookup[];
  centers: ICenter[];
  defaultSelectedCenter: ICenter;
}

class CreateParent extends Component<IProps & IDispatchProps & IStateProps> {
  render() {
    const { genders, isSubmitting } = this.props;
    return (
      <Formik
        initialValues={{ ...initFormValue, ...{ centerId: this.props.defaultSelectedCenter.id } }}
        validationSchema={formValidationSchema}
        onSubmit={(values) => this.props.onCreateParent(values)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          submitCount,
          setFieldValue,
          isValid,
          dirty,
          error,
        }) => (
          <form onSubmit={handleSubmit}>
            <Card>
              <Card.Body>
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <label>First Name</label>
                    <input
                      className={classNames('form-control', { 'is-invalid': shouldControlShowError(errors, touched, submitCount, 'firstName') })}
                      name="firstName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.firstName}
                    />
                    <ControlError errorMessage={errors.firstName} />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Last Name</label>
                    <input
                      className={classNames('form-control', { 'is-invalid': shouldControlShowError(errors, touched, submitCount, 'lastName') })}
                      name="lastName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.lastName}
                    />
                    <ControlError errorMessage={errors.lastName} />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Center</label>
                    <select
                      className={classNames('form-control', { 'is-invalid': shouldControlShowError(errors, touched, submitCount, 'centerId') })}
                      name="centerId"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.centerId}
                    >
                      {this.props.centers.map((center) => (
                        <option key={String(center.id)} value={center.id}>{center.name}</option>
                      ))}
                    </select>
                    <ControlError errorMessage={errors.centerId} />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Street Address</label>
                    <input
                      className={classNames('form-control', { 'is-invalid': shouldControlShowError(errors, touched, submitCount, 'address') })}
                      name="address"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.address}
                    />
                    <ControlError errorMessage={errors.address} />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>City</label>
                    <input
                      className={classNames('form-control', { 'is-invalid': shouldControlShowError(errors, touched, submitCount, 'city') })}
                      name="city"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.city}
                    />
                    <ControlError errorMessage={errors.city} />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Post Code</label>
                    <input
                      className={classNames('form-control', { 'is-invalid': shouldControlShowError(errors, touched, submitCount, 'postcode') })}
                      name="postcode"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.postcode}
                    />
                    <ControlError errorMessage={errors.postcode} />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Email</label>
                    <input
                      className={classNames('form-control', { 'is-invalid': shouldControlShowError(errors, touched, submitCount, 'email') })}
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                    />
                    <ControlError errorMessage={errors.email} />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Phone Number</label>
                    <input
                      className={classNames('form-control', { 'is-invalid': shouldControlShowError(errors, touched, submitCount, 'phoneNumber') })}
                      name="phoneNumber"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.phoneNumber}
                    />
                    <ControlError errorMessage={errors.phoneNumber} />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Relationship with Child</label>
                    <input
                      className={classNames('form-control', { 'is-invalid': shouldControlShowError(errors, touched, submitCount, 'postcode') })}
                      name="childRelationship"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.childRelationship}
                    />
                    <ControlError errorMessage={errors.childRelationship} />
                  </div>
                </div>
              </Card.Body>
            </Card>

            <FieldArray
              name="childs"
              render={({ handlePush, handleRemove }) => (
                <React.Fragment>
                  {values.childs.map((child, index) => (
                    <Card key={String(index)}>
                      <Card.Body>
                        <Card.Title>Child {index + 1}</Card.Title>
                        <div className="row mt-2">
                          <div className="col-md-4 mb-3">
                            <label>First Name</label>
                            <input
                              className={classNames('form-control', { 'is-invalid': shouldChildControlShowError(index, errors.childs, touched.childs, submitCount, 'firstName') })}
                              name={`childs[${index}].firstName`}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={child.firstName}
                            />
                            <ChildControlError childs={errors.childs} index={index} fieldName="firstName" />
                          </div>
                          <div className="col-md-4 mb-3">
                            <label>Last Name</label>
                            <input
                              className={classNames('form-control', { 'is-invalid': shouldChildControlShowError(index, errors.childs, touched.childs, submitCount, 'lastName') })}
                              name={`childs[${index}].lastName`}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={child.lastName}
                            />
                            <ChildControlError childs={errors.childs} index={index} fieldName="lastName" />
                          </div>
                          <div className="col-md-4 mb-3">
                            <label>Child Birth Date</label>
                            <input
                              className={classNames('form-control', { 'is-invalid': shouldChildControlShowError(index, errors.childs, touched.childs, submitCount, 'dateOfBirth') })}
                              type="date"
                              name={`childs[${index}].dateOfBirth`}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={child.dateOfBirth as string}
                            />
                            <ChildControlError childs={errors.childs} index={index} fieldName="dateOfBirth" />
                          </div>
                          <div className="col-md-4 mb-3">
                            <label>Gender</label>
                            <ButtonGroup className="d-block">
                              {genders.map((gender) => (
                                <Button
                                  key={String(gender.id)}
                                  onClick={() => setFieldValue(`childs[${index}].genderTypeId`, gender.id)}
                                  variant={child.genderTypeId === gender.id ? 'primary' : 'light'}
                                >
                                  {gender.displayName}
                                </Button>
                              ))}
                            </ButtonGroup>
                          </div>
                        </div>
                        <div className="mt-2 d-flex flex-row">
                          {/* <Button variant="primary">Enrol to class</Button> */}
                          <div className="flex-grow-1" />
                          <Button variant="danger" onClick={handleRemove(index)}>Remove</Button>
                        </div>
                      </Card.Body>
                    </Card>
                  ))}
                  <Card style={{ cursor: 'pointer' }} onClick={handlePush({ ...initChildValue })}>
                    <Card.Body>
                      <div className="text-primary">
                        <i className="mdi mdi-plus-circle-outline"></i>
                        &nbsp;&nbsp;Add Child
                      </div>
                    </Card.Body>
                  </Card>
                </React.Fragment>
              )}
            />

            <div className="mt-2 mb-3 d-flex flex-row align-items-center">
              <p className="text-danger m-0">{error}</p>
              <div className="flex-grow-1" />
              <Button
                variant="light"
                className="ml-2"
                disabled={isSubmitting}
                onClick={this.props.onCancel}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="primary"
                className="ml-2"
                disabled={!dirty || isSubmitting || (submitCount > 0 && !isValid)}
              >
                {isSubmitting && (
                  <>
                    <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                    Creating...
                  </>
                )}
                {!isSubmitting && 'Create'}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
});

const mapStateToProps = (state: IAppState): IStateProps => ({
  genders: selectLookup(state, 'genderTypes'),
  centers: selectCenters(state),
  defaultSelectedCenter: selectedCenter(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateParent);
