import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { paymentGatewaySuccess } from './payment-callback.actions';

interface IDispatchProps {
  paymentGatewaySuccess: () => void;
}

class Success extends Component<IDispatchProps> {
  componentDidMount() {
    this.props.paymentGatewaySuccess();
  }

  render() {
    return (
      <div className="d-flex align-items-center justify-content-center" style={{ height: 'calc(100vh - 200px)' }}>
        <Spinner animation="border" />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  paymentGatewaySuccess: () => dispatch(paymentGatewaySuccess()),
});

export default connect(null, mapDispatchToProps)(Success);
