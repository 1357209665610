import Config from '@app/config';
import httpHelpers from '@app/helpers/http.helpers';

export const resetPasswordAPI = async (data: any): Promise<void> => {
  try {
    await httpHelpers.put(`${Config.SERVER_URL}/api/passwordReset`, data);
  } catch (error) {
    if (error.data && error.data.modelState) {
      const messages = Object.keys(error.data.modelState).map(key => error.data.modelState[key]);
      throw new Error(messages[0]);
    } else {
      throw error;
    }
  }
};
