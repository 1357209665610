import Config from 'config';
import { applyMiddleware, combineReducers, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { SagaMiddleware } from 'redux-saga';
import { loggerMiddleware } from './logger';

import lessonAttendanceReducer, { ILessionAttendanceState } from 'pages/lesson-attendance/lesson-attendance.reducer';
import screenLoginOrganizationReducer, { IScreenLoginOrganizationState } from 'pages/login-organization/login-organization.reducer';
import screenManageAttendanceReducer, { IManageAttendanceState } from 'pages/manage-attendance/manage-attendance.reducer';
import screenManualEnrolmentCompleteReducer, { IManualEnrolmentCompleteState } from 'pages/manual-enrolment-complete/manual-enrolment-complete.reducer';
import screenManualEnrolmentReducer, { IManualEnrolmentState } from 'pages/manual-enrolment/manual-enrolment.reducer';
import screenWorkshopEnrolmentReducer, { IWorkshopEnrolmentState } from 'pages/workshop-enrolment/workshop-enrolment.reducer';
import screenWorkshopEnrolmentCompleteReducer, { IWorkshopEnrolmentCompleteState } from 'pages/workshop-enrolment-complete/workshop-enrolment-complete.reducer';
import parentSearchReducer, { IParentSearchState } from 'shared-components/parent-search/parent-search.reducer';
import parentDetailsReducer, { IParentDetailsState } from 'shared-components/parent-details/parent-details.reducer';
import authReducer, { IAuthState } from 'reducers/auth';
import rootReducer, { IRootState } from 'reducers/root';

export interface IScreenState {
  loginOrganization: IScreenLoginOrganizationState;
  manageAttendance: IManageAttendanceState;
  manualEnrolment: IManualEnrolmentState;
  manualEnrolmentComplete: IManualEnrolmentCompleteState;
  workshopEnrolment: IWorkshopEnrolmentState,
  workshopEnrolmentComplete: IWorkshopEnrolmentCompleteState,
  parentSearch: IParentSearchState;
  parentDetails: IParentDetailsState;
}

export interface IAppState {
  root: IRootState;
  auth: IAuthState;
  lessonAttendance: ILessionAttendanceState;
  screen: IScreenState;
}

const appReducer = combineReducers<IAppState>({
  root: rootReducer,
  auth: authReducer,
  lessonAttendance: lessonAttendanceReducer,
  screen: combineReducers<IScreenState>({
    loginOrganization: screenLoginOrganizationReducer,
    manageAttendance: screenManageAttendanceReducer,
    manualEnrolment: screenManualEnrolmentReducer,
    manualEnrolmentComplete: screenManualEnrolmentCompleteReducer,
    workshopEnrolment: screenWorkshopEnrolmentReducer,
    workshopEnrolmentComplete: screenWorkshopEnrolmentCompleteReducer,
    parentSearch: parentSearchReducer,
    parentDetails: parentDetailsReducer
  }),
});

export let store: Store<IAppState>;

export const configureStore = (sagaMiddleWare: SagaMiddleware) => {
  const middleware = Config.DEBUG ? composeWithDevTools(applyMiddleware(...loggerMiddleware, sagaMiddleWare)) : applyMiddleware(...loggerMiddleware, sagaMiddleWare);
  store = createStore(
    appReducer,
    middleware,
  );
};
