import { IAppData } from '../types';

let dataExpireBreakpoint = 0;

export const getExpirableData = <T>(data: T, duration: number, durationType?: 'ms' | 's' | 'm' | 'h'): IAppData<T> => {
  let multiplier = 1;

  if (durationType === 's') {
    multiplier = 1000;
  } else if (durationType === 'm') {
    multiplier = 1000 * 60;
  } else if (durationType === 'h') {
    multiplier = 1000 * 60 * 60;
  }

  const appData: IAppData<T> = {
    createdAt: Date.now(),
    expireAt: Date.now() + (duration * multiplier),
    data,
  };

  return appData;
};

export const getIsDataExpired = <T>(appData: IAppData<T>): boolean => {
  if (!appData) {
    return true;
  }

  if (appData.expireAt < Date.now() || !appData.createdAt || appData.createdAt < dataExpireBreakpoint) {
    return true;
  }

  return false;
};

export const changeDataExpireBreakpoint = (value: number) => {
  dataExpireBreakpoint = value;
};

export const getAvatarName = (firstName: string, lastName: string) => {
  if (!firstName && !lastName) {
    return '';
  }

  if (firstName && !lastName) {
    return firstName.substr(0, 2).toUpperCase();
  }

  return (firstName[0] + lastName[0]).toUpperCase();
};

export const groupBy = (array: any[], prop: string) => {
  return array.reduce((groups, item) => {
    const val = item[prop];
    groups[val] = groups[val] || [];
    groups[val].push(item);
    return groups;
  }, {});
};
