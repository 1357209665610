import { createBrowserHistory } from 'history';
import analytics from './analytics.helper';

const history = createBrowserHistory();

history.listen(location => {
  analytics.set({ page: location.pathname });
  analytics.pageview(location.pathname);
});

analytics.set({ page: window.location.pathname });
analytics.pageview(window.location.pathname);

export default history;
