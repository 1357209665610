import React from 'react';

interface IProps {
  src: string | React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  color?: string;
  width?: number;
  height?: number;
  style?: React.CSSProperties;
  className?: string;
  alt?: string;
  id?: string;
}

const SVGImage: React.FC<IProps> = (props) => {
  const style: React.CSSProperties = {};

  if (props.color) {
    style.color = props.color;
  }

  if (props.width) {
    style.width = props.width;
  }

  if (props.height) {
    style.height = props.height;
  }

  if (typeof props.src === 'string') {
    return (
      <img src={props.src} style={{ ...style, ...props.style }} className={props.className} alt={props.alt} id={props.id} />
    );
  }

  const Image = props.src;

  return (
    <Image />
  );
};

export default SVGImage;
