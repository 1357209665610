import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { paymentGatewayFail } from './payment-callback.actions';

interface IDispatchProps {
  paymentGatewayFail: () => void;
}

class Failed extends Component<IDispatchProps> {
  componentDidMount() {
    this.props.paymentGatewayFail();
  }
  render() {
    return (
      <p>Payment failed</p>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  paymentGatewayFail: () => dispatch(paymentGatewayFail()),
});

export default connect(null, mapDispatchToProps)(Failed);
