import Config from '@app/config';

const DEBUG = Config.DEBUG;

const loadGA = (trackingId: string) => {
  const gaAddress = `https://www.googletagmanager.com/gtag/js?id=${trackingId}}`;

  const scriptElement = document.createElement('script');
  const firstScriptElement = document.getElementsByTagName('script')[0];
  scriptElement.async = true;
  scriptElement.src = gaAddress;
  firstScriptElement.parentNode.insertBefore(scriptElement, firstScriptElement);
};

const noop = () => null;
const $window = window as any;
$window.dataLayer = $window.dataLayer || [];
function gtag(...args: any[]) { $window.dataLayer.push(arguments); }

let setUser: ({ userId }: { userId: string | number }) => void = noop;
let event: (eventName: string, params?: any) => void = noop;
let set: (values: { [key: string]: string }) => void = noop;
let pageview: (path: string) => void = noop;

if (!DEBUG && Config.GA_TRACKING_ID) {
  loadGA(Config.GA_TRACKING_ID);
  gtag('js', new Date());
  gtag('config', Config.GA_TRACKING_ID, {
    'app_name': 'bookingautopilot',
    'app_version': Config.VERSION,
  });

  event = (eventName, params) => gtag('event', eventName, params);
  set = (values) => gtag('set', values);
  pageview = (path) => gtag('event', 'screen_view', {
    'app_name': 'bookingautopilot',
    'screen_name': path,
  });

  setUser = ({ userId }) => set({
    'user_id': userId ? String(userId) : '',
  });
}

const analytics = {
  setUser,
  event,
  set,
  pageview,
};

export default analytics;
