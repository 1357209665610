import { logout } from '@app/actions/auth';
import ReducerFactory from 'reducers/ReducerFactory';
import { IChildLesson } from 'types';
import { IWeeklyProgram } from 'types/IWeeklyProgram';
import { changeViewMode, fetchAttendanceSuccess, fetchWeeklyPrograms, fetchWeeklyProgramsSuccess, setSelectedProgram, toggleAttendanceSuccess } from './lesson-attendance.actions';
import { ViewMode } from './lesson-attendance.types';

export interface ILessionAttendanceState {
  weeklyProgramsLoading: boolean;
  weeklyPrograms: IWeeklyProgram[];
  viewMode: ViewMode;
  selectedProgram: IWeeklyProgram;
  attendanceLoading: boolean;
  attendance: IChildLesson[];
}

const initState: ILessionAttendanceState = {
  weeklyProgramsLoading: false,
  weeklyPrograms: [],
  viewMode: 'weekly',
  selectedProgram: null,
  attendanceLoading: true,
  attendance: [],
};

const reducer = new ReducerFactory(initState)
  .add(logout, (state, action) => {
    return initState;
  })
  .add(fetchWeeklyPrograms, (state, action) => {
    return {
      ...state,
      weeklyProgramsLoading: true,
    };
  })
  .add(fetchWeeklyProgramsSuccess, (state, action) => {
    return {
      ...state,
      weeklyProgramsLoading: false,
      weeklyPrograms: action.payload,
    };
  })
  .add(changeViewMode, (state, action) => {
    return {
      ...state,
      viewMode: action.payload,
    };
  })
  .add(setSelectedProgram, (state, action) => {
    return {
      ...state,
      selectedProgram: action.payload,
      attendanceLoading: true,
    };
  })
  .add(fetchAttendanceSuccess, (state, action) => {
    return {
      ...state,
      attendanceLoading: false,
      attendance: action.payload,
    };
  })
  .add(toggleAttendanceSuccess, (state, action) => {
    const attendance = state.attendance;
    const item = attendance.find(x => x.id === action.payload.id);

    if (!item) {
      return state;
    }

    item.isAttended = !item.isAttended;

    return {
      ...state,
      attendance: [...attendance],
    };
  })
  .toReducer();

export default reducer;
