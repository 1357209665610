import { formatCurrency } from '@app/helpers/number.helpers';
import { selectFranchisor } from '@app/selectors/auth';
import { IAppState } from '@app/store';
import { IFranchisor, ILessonPayment } from '@app/types';
import Moment from 'moment-timezone';
import React, { Component } from 'react';
import { Card, Spinner, Table } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { RouteChildrenProps } from 'react-router';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { fetchPayment } from './manual-enrolment-complete.actions';
import { selectState } from './manual-enrolment-complete.selectors';

interface IDispatchProps {
  fetchPayment: (paymentId: number) => void;
}

interface IStateProps {
  loading: boolean;
  loadingFailed: boolean;
  payment: ILessonPayment;
  franchisor: IFranchisor;
}

interface IParams {
  paymentId: string;
}

class ManualEnrolmentComplete extends Component<IDispatchProps & IStateProps & RouteChildrenProps<IParams>> {
  async componentDidMount() {
    this.props.fetchPayment(Number(this.props.match.params.paymentId));
  }

  renderLoading() {
    return (
      <div className="card card-body d-flex align-items-center justify-content-center mt-3" style={{ minHeight: 360 }}>
        <Spinner animation="border" />
      </div>
    );
  }

  renderLoadingFail() {
    return (
      <Card className="mt-3">
        <Card.Body>
          <Card.Title className="text-danger font-18">Loading failed</Card.Title>
          <Card.Text>We are not able to load this page at the moment</Card.Text>
        </Card.Body>
      </Card>
    );
  }

  renderNotFound() {
    const { paymentId } = this.props.match.params;

    return (
      <Card className="mt-3">
        <Card.Body>
          <Card.Title className="text-danger font-18">Order not found</Card.Title>
          <Card.Text>Order #{paymentId} not available</Card.Text>
        </Card.Body>
      </Card>
    );
  }

  renderHowToPay() {
    const { payment } = this.props;
    switch (payment.paymentMethod.name.toLowerCase()) {
      case 'banktransfer':
        return this.renderHowToPayBankTransfer();
      case 'easypay':
        return this.renderHowToPayEasypay();
    }

    return null;
  }

  renderHowToPayBankTransfer() {
    const { payment, franchisor } = this.props;
    const configuration = payment.paymentMethod.configuration;
    return (
      <React.Fragment>
        <p>
          <u className="font-weight-bold">How to pay</u>
          <br />
          Please transfer the amount due to the following bank account and include the order number in the description.
        </p>
        <Table size="sm" className="table-responsive font-italic" borderless>
          <tbody>
            <tr>
              <td>Description:</td>
              <td>Order number: {payment.id}</td>
            </tr>
            <tr>
              <td>Amount:</td>
              <td>{formatCurrency(payment.total)}</td>
            </tr>
            {franchisor.isIbanBankTransfer &&
              <tr>
                <td>IBAN:</td>
                <td>{Boolean(configuration) && configuration.iban}</td>
              </tr>}
            {!franchisor.isIbanBankTransfer &&
              <tr>
                <td>Account number:</td>
                <td>{Boolean(configuration) && configuration.accountNumber}</td>
              </tr>}
            {!franchisor.isIbanBankTransfer &&
              <tr>
                <td>Sort code:</td>
                <td>{Boolean(configuration) && configuration.sortCode}</td>
              </tr>}
            <tr>
              <td>Account name:</td>
              <td>{Boolean(configuration) && configuration.accountHolderName}</td>
            </tr>
            <tr>
              <td>Bank:</td>
              <td>{Boolean(configuration) && configuration.bankName}</td>
            </tr>
          </tbody>
        </Table>
      </React.Fragment>
    );
  }

  renderHowToPayEasypay() {
    const { payment } = this.props;
    return (
      <React.Fragment>
        <p>
          <u className="font-weight-bold">How to pay</u>
          <br />
          Please visit an <a href="https://www.easypay.bg/site/?p=offices" target="_blank" rel="noopener noreferrer">EasyPay</a> branch and provide them with the following reference code to make your payment.
        </p>
        <Table size="sm" className="table-responsive font-italic" borderless>
          <tbody>
            <tr>
              <td>EasyPay Code:	</td>
              <td>{payment.paymentCode}</td>
            </tr>
          </tbody>
        </Table>
      </React.Fragment>
    );
  }

  _renderFooter() {
    return (
      <React.Fragment>
        <Card.Text>
          If you have any questions or require assistance, please message us on facebook or send an email to office@gymbaroo.com.au.
        </Card.Text>
        <Card.Text>
          If you would like to enrol another child, please <Link to="/manual-enrolment">click here</Link>.
        </Card.Text>
      </React.Fragment>
    );
  }

  renderPaymentPending() {
    const { payment } = this.props;
    return (
      <Card className="mt-3">
        <Card.Body>
          <Card.Title className="text-warning font-18">Order #{payment.id} waiting for payment!</Card.Title>
          <Card.Text>
            Please be aware that your order and spot in the class is not confirmed until payment has been made so please pay promptly to avoid class filling up!
          </Card.Text>
          <Card.Text>
            <u className="font-weight-bold">Order details</u>
            <br />
            Child: {payment.child.fullname}
            <br />
            Class: {payment.grade}
            <br />
            Date: {Moment(payment.startDate).format('DD MMM YYYY')}
            <br />
            Number of lessons: {payment.quantity}
            <br />
          </Card.Text>
          {this.renderHowToPay()}
          {this._renderFooter()}
        </Card.Body>
      </Card>
    );
  }

  renderPaymentPendingApproval() {
    const { payment } = this.props;
    return (
      <Card className="mt-3">
        <Card.Body>
          <Card.Title className="text-warning font-18">Order #{payment.id} waiting for payment!</Card.Title>
          <Card.Text>
            Your spot is reserved but please pay promptly to the details below.
          </Card.Text>
          <Card.Text>
            <u className="font-weight-bold">Order details</u>
            <br />
            Child: {payment.child.fullname}
            <br />
            Class: {payment.grade}
            <br />
            Date: {Moment(payment.startDate).format('DD MMM YYYY')}
            <br />
            Number of lessons: {payment.quantity}
            <br />
          </Card.Text>
          {this.renderHowToPay()}
          {this._renderFooter()}
        </Card.Body>
      </Card>
    );
  }

  renderPaymentPaid() {
    const { payment } = this.props;
    return (
      <Card className="mt-3">
        <Card.Body>
          <Card.Title className="text-success font-18">Thank you</Card.Title>
          <Card.Text>
            Your order {payment.id} was completed successfully.
            <br />
            Order details will be sent to your registered email {payment.parent.email}.
          </Card.Text>
          {this._renderFooter()}
        </Card.Body>
      </Card>
    );
  }

  renderPageTitle() {
    return (
      <Helmet>
        <title>Order detail - Manual Enrolments - Booking Autopilot</title>
      </Helmet>
    );
  }

  renderBody() {
    const { payment, loading, loadingFailed } = this.props;

    if (loading) {
      return this.renderLoading();
    }

    if (loadingFailed) {
      return this.renderLoadingFail();
    }

    if (!payment) {
      return this.renderNotFound();
    }

    if (payment.status.id === 1) {
      return this.renderPaymentPending();
    }

    if (payment.status.id === 6) {
      return this.renderPaymentPendingApproval();
    }

    if (payment.status.id === 2) {
      return this.renderPaymentPaid();
    }

    return this.renderNotFound();
  }

  render() {
    return (
      <React.Fragment>
        {this.renderPageTitle()}
        {this.renderBody()}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  fetchPayment: (paymentId) => dispatch(fetchPayment(paymentId)),
});

const mapStateToProps = (state: IAppState): IStateProps => ({
  loading: selectState(state).loading,
  loadingFailed: selectState(state).loadingFailed,
  payment: selectState(state).payment,
  franchisor: selectFranchisor(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ManualEnrolmentComplete);
