import { createAction } from 'redux-actions';
import { IChildLesson, IWeeklyProgram } from 'types';
import { ViewMode } from './lesson-attendance.types';

const ACTION_PREFIX = '@screen/lesson-attendance';
const getActionName = (name: string) => `${ACTION_PREFIX}/${name}`;

export const fetchWeeklyPrograms = createAction(getActionName('FETCH_WEEKLY_PROGRAMS'));
export const fetchWeeklyProgramsSuccess = createAction<IWeeklyProgram[]>(getActionName('FETCH_WEEKLY_PROGRAMS_SUCCESS'));

export const changeViewMode = createAction<ViewMode>(getActionName('CHANGE_VIEW_MODE'));

export const setSelectedProgram = createAction<IWeeklyProgram>(getActionName('SET_SELECTED_PROGRAM'));
export const fetchAttendanceSuccess = createAction(getActionName('FETCH_ATTENDANCE_SUCCESS'));
export const toggleAttendance = createAction<IChildLesson>(getActionName('TOGGLE_ATTENDANCE'));
export const toggleAttendanceSuccess = createAction<IChildLesson>(getActionName('TOGGLE_ATTENDANCE_SUCCESS'));
export const changeCenter = createAction(getActionName('CHANGE_CENTER'));
