import Moment from 'moment-timezone';
import React, { Component } from 'react';
import { Badge, Button, Card, Spinner, Table } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router';
import { IChild, IChildLesson } from 'types';
import { ILessonDates } from './manage-attendance.types';

export interface IDispatchProps {
  fetchAttendanceData: (programId: number) => void;
  onToggleAttendance: (item: IChildLesson) => void;
}

export interface IStateProps {
  loading: boolean;
  program: any;
  attendance: IChildLesson[];
  lessonDates: ILessonDates;
}

interface IParams {
  programId: string;
}

class LessionAttendanceComponent extends Component<IDispatchProps & IStateProps & RouteComponentProps<IParams>> {
  componentDidMount() {
    const { programId } = this.props.match.params;
    this.props.fetchAttendanceData(Number(programId));
  }

  renderLoading() {
    return (
      <div className="card card-body d-flex align-items-center justify-content-center" style={{ minHeight: 360 }}>
        <Spinner animation="border" />
      </div>
    );
  }

  renderAttendanceStatus(child: IChild, date: string) {
    const { attendance } = this.props;

    const childAttendance = attendance.find(x => x.child.id === child.id && Moment(x.lessonDate).format('YYYY-MM-DD') === Moment(date).format('YYYY-MM-DD'));

    if (!childAttendance) {
      return null;
    }

    const isAttended = childAttendance.isAttended;

    return (
      <Button
        variant={isAttended ? 'success' : 'danger'}
        className="rounded-circle d-flex align-items-center justify-content-center"
        style={{ width: 36, height: 36 }}
        onClick={() => this.props.onToggleAttendance(childAttendance)}
      >
        {isAttended && <i className="h4 mdi mdi-check m-0"></i>}
        {!isAttended && <i className="h4 mdi mdi-close m-0"></i>}
      </Button>
    );
  }

  renderAttendance() {
    const { lessonDates, program } = this.props;
    const childs = this.props.attendance.reduce((previousValue: IChild[], currentValue) => {
      if (previousValue.findIndex(x => x.id === currentValue.child.id) === -1) {
        return [...previousValue, currentValue.child];
      }

      return previousValue;
    }, []);
    return (
      <Card className="overflow-auto">
        <Card.Body>
          <Table>
            <thead>
              <tr className="bg-light">
                <td style={{ minWidth: 240 }}></td>
                {lessonDates.lessonDates.map(item => {
                  return (
                    <td key={item} className="text-center" style={{ minWidth: 160 }}>
                      <span className="h6">{Moment(item).format('DD MMMM YYYY')}</span>
                      <div className="mt-1">
                        <Badge variant="success">{program.attendingChildrenCount}/{program.maxChildren || 0} Paid</Badge>
                        <Badge variant="danger" className="ml-1">{program.attendingTrialChildrenCount}/{program.maxTrialChildren || 0} Trial</Badge>
                      </div>
                    </td>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {childs.map(child => {
                return (
                  <tr key={String(child.id)}>
                    <td>
                      <div className="d-flex flex-column text-muted">
                        <div className="d-flex flex-row">
                          <span>{child.name}</span>
                        </div>
                        <small className="text-muted">{child.parent.name}</small>
                        <div className="d-flex flex-row mt-1">
                          <Badge variant="warning" className="text-white">Pending</Badge>
                          <Badge variant="danger" className="ml-1">No Photo Consent</Badge>
                        </div>
                      </div>
                    </td>
                    {lessonDates.lessonDates.map(item => {
                      return (
                        <td key={item} className="text-center align-middle">
                          <div className="d-flex align-items-center justify-content-center">
                            {this.renderAttendanceStatus(child, item)}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    );
  }

  render() {
    const { loading } = this.props;
    return (
      <div className="container-fluid pt-2">
        <div className="d-flex flex-row align-items-center">
          <div className="d-flex flex-row align-items-center flex-grow-1 mt-3 mb-2">
            <h4 className="mr-1">Manage lesson attendance</h4>
          </div>
        </div>
        {loading && this.renderLoading()}
        {!loading && this.renderAttendance()}
      </div>
    );
  }
}

export default LessionAttendanceComponent;
