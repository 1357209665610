import React from 'react';

import { Button, ButtonGroup, Card, View } from 'components';

import AttendanceListItem from './AttendanceListItem';

const data = [{
  name: 'John Smith',
  parentName: 'Will Smith',
  photoConsent: false,
  status: ['Pending'],
  icons: ['drop', 'bus'],
  done: false,
}, {
  name: 'John Smith',
  parentName: 'Will Smith',
  photoConsent: false,
  status: ['Makeup'],
  icons: ['drop'],
  done: true,
}, {
  name: 'John Smith',
  parentName: 'Will Smith',
  photoConsent: false,
  status: ['Makeup', 'New'],
  icons: [],
  done: false,
}, {
  name: 'John Smith',
  parentName: 'Will Smith',
  photoConsent: true,
  status: ['Trial'],
  icons: ['drop', 'bus'],
  done: false,
}, {
  name: 'John Smith',
  parentName: 'Will Smith',
  photoConsent: false,
  status: ['Pending', 'Unpaid'],
  icons: ['drop', 'bus'],
  done: false,
}];

const AttendaceCardDemo = () => {
  return (
    <Card>
      <Card.Body>
        <View flexDirection="row" className="mb-2">
          <View>
            <ButtonGroup>
              <Button className="rounded-right">Attendance</Button>
              <Button variant="light" className="text-muted">Note</Button>
            </ButtonGroup>
          </View>
          <View flexGrow />
          <View flexDirection="row">
            <Button variant="light" className="ml-1"><i className="h4 mdi mdi-lock-open-outline m-0 text-muted"></i></Button>
            <Button className="ml-1">Manage</Button>
          </View>
        </View>
        <Card.Text className="text-muted">
          Unlock the attendance list to active the attendance mode. To mark the attendance, tap the icon on the right.
          </Card.Text>
        <View>
          {data.map((item, index) => (
            <AttendanceListItem
              key={String(index)}
              name={item.name}
              parentName={item.parentName}
              icons={item.icons as any[]}
              labels={item.status}
              photoConsent={item.photoConsent}
              completed={item.done}
            />
          ))}
        </View>
      </Card.Body>
    </Card>
  );
};

export default AttendaceCardDemo;
