import { Action } from 'redux-actions';
import { put, takeLatest } from 'redux-saga/effects';

import Config from 'config';
import httpHelpers from 'helpers/http.helpers';
import { IChildLesson, IWeeklyProgram } from 'types';
import { fetchAttendanceData, fetchAttendanceSuccess, fetchLessonDatesSuccess, fetchWeeklyProgramSuccess, setLoadingState, toggleAttendance, toggleAttendanceSuccess } from './manage-attendance.actions';
import { ILessonDates } from './manage-attendance.types';

function* fetchAttendanceDataSaga(action: Action<number>) {
  try {
    const programId = action.payload;
    const program: IWeeklyProgram = yield httpHelpers.get<IWeeklyProgram>(`${Config.SERVER_URL}/api/weeklyPrograms/${programId}`);
    yield put(fetchWeeklyProgramSuccess(program));

    const lessonDates: ILessonDates = yield httpHelpers.get<ILessonDates>(`${Config.SERVER_URL}/api/weeklyPrograms/lessonDates?id=${programId}`);
    yield put(fetchLessonDatesSuccess(lessonDates));

    const attedance: IChildLesson[] = yield httpHelpers.get<IChildLesson[]>(`${Config.SERVER_URL}/api/childLessons/unpaged?weeklyProgramId=${programId}&canceled=false`);
    yield put(fetchAttendanceSuccess(attedance));

    yield put(setLoadingState(false));
  } catch (err) {
    //
  }
}

function* toggleAttendanceSaga(action: Action<IChildLesson>) {
  try {
    yield httpHelpers.put<any, IChildLesson>(`${Config.SERVER_URL}/api/childLessons/${action.payload.id}`, {
      ...action.payload,
      isAttended: !action.payload.isAttended,
    });
    yield put(toggleAttendanceSuccess(action.payload));
  } catch (err) {
    //
  }
}

export default [
  takeLatest(fetchAttendanceData.toString(), fetchAttendanceDataSaga),
  takeLatest(toggleAttendance.toString(), toggleAttendanceSaga),
];
