import { IMakePayment, IPayment, IPaymentMethod } from '@app/types';
import React, { Component } from 'react';
import { Button, Card } from 'react-bootstrap';
import PaymentInfo from '../payment-info.component';

interface IProps {
  payment: IPayment;
  selectedPaymentMethod: IPaymentMethod;
  onMakePayment: (payment: IMakePayment) => void;
}

class GeneralPayment extends Component<IProps> {
  submit = () => {
    this.props.onMakePayment({
      paymentId: this.props.payment.id,
      paymentMethodName: this.props.payment.paymentMethod.name
    });
  }

  render() {
    const { payment, selectedPaymentMethod } = this.props;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-8">
            <Card>
              <Card.Body>
                <Card.Title>{selectedPaymentMethod.friendlyName}</Card.Title>
                <p dangerouslySetInnerHTML={{ __html: selectedPaymentMethod.description }} />
              </Card.Body>
              <Card.Footer className="text-right">
                <Button onClick={this.submit}>Book</Button>
              </Card.Footer>
            </Card>
          </div>
          <div className="col-md-4">
            <PaymentInfo payment={payment} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default GeneralPayment;
