import React from 'react';
import AttendaceCardDemo from './AttendaceCardDemo';

import { withLayout } from 'layouts';

const Test = () => {
  return (
    <div className="container-fluid pt-3">
      <AttendaceCardDemo />
    </div>
  );
};

export default withLayout(Test);
