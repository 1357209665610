import { appInitializeSuccess, changeDataExpireBreakpoint, changeLanguage, closeGlobalMessageBox, fetchLanguagesSuccess, fetchLookupsSuccess, init, setGlobalMessageBox, setSelectedLanguage } from 'actions/root';
import { IAppData, IAppLookups, IGlobalMessageBox, ILanguage } from 'types';
import * as common from '../helpers/common.helpers';
import { getRootState } from '../helpers/window-storage.helper';
import ReducerFactory from './ReducerFactory';

export interface IRootState {
  version: number;
  dataExpireBreakpoint: number;
  initialized: boolean;
  globalMessage: IGlobalMessageBox;
  languages: IAppData<ILanguage[]>;
  lookups: IAppData<IAppLookups>;
  selectedLanguage: ILanguage;
}

const initState: IRootState = {
  version: 0,
  dataExpireBreakpoint: 0,
  initialized: false,
  globalMessage: null,
  languages: null,
  lookups: null,
  selectedLanguage: null,
};

const initRootState = getRootState(initState);

common.changeDataExpireBreakpoint(initRootState.dataExpireBreakpoint);

const reducer = new ReducerFactory(initRootState)
  .add(init, (state, action) => {
    return {
      ...state,
      version: action.payload.version,
    };
  })
  .add(setGlobalMessageBox, (state, action) => {
    return {
      ...state,
      globalMessage: action.payload,
    };
  })
  .add(closeGlobalMessageBox, (state) => {
    return {
      ...state,
      globalMessage: null,
    };
  })
  .add(appInitializeSuccess, (state) => {
    return {
      ...state,
      initialized: true,
    };
  })
  .add(fetchLanguagesSuccess, (state, action) => {
    return {
      ...state,
      languages: action.payload,
    };
  })
  .add(setSelectedLanguage, (state, action) => {
    return {
      ...state,
      selectedLanguage: action.payload,
    };
  })
  .add(fetchLookupsSuccess, (state, action) => {
    return {
      ...state,
      lookups: action.payload,
    };
  })
  .add(changeLanguage, (state, action) => {
    return {
      ...state,
      selectedLanguage: action.payload,
    };
  })
  .add(changeDataExpireBreakpoint, (state, action) => {
    common.changeDataExpireBreakpoint(action.payload);
    return {
      ...state,
      dataExpireBreakpoint: action.payload,
    };
  })
  .toReducer();

export default reducer;
