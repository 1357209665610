import { Action } from 'redux-actions';
import { put, takeLatest } from 'redux-saga/effects';

import analytics from '@app/helpers/analytics.helper';
import history from '@app/helpers/history.helper';
import { setCurrencyOptions } from '@app/helpers/number.helpers';
import Config from 'config';
import { getExpirableData } from 'helpers/common.helpers';
import httpHelpers from 'helpers/http.helpers';
import { IFranchisor } from 'types';
import { IFetchFranchisorParam } from '../../types/common';
import { fetchFranchisor, fetchFranchisorFailed, fetchFranchisorSuccess } from './login-organization.actions';

function* fetchFranchisorSaga(action: Action<IFetchFranchisorParam>) {
  try {
    const result: IFranchisor = yield httpHelpers.get(`${Config.SERVER_URL}/api/franchisors?domain=${encodeURIComponent(action.payload.domain)}`);
    analytics.event('select_organisation', {
      domain: action.payload.domain,
    });
    yield put(fetchFranchisorSuccess(getExpirableData({
      ...result,
      domain: action.payload.domain,
    }, 1, 'h')));
    setCurrencyOptions(result.currency);
    history.replace('/login');
  } catch (err) {
    yield put(fetchFranchisorFailed('We could not find your organisation'));
  }
}

export default [
  takeLatest(fetchFranchisor.toString(), fetchFranchisorSaga),
];
