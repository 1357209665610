import { logout } from '@app/actions/auth';
import ReducerFactory from '@app/reducers/ReducerFactory';
import { ILessonPayment } from '@app/types';
import { fetchPayment, fetchPaymentFail, fetchPaymentSuccess } from './manual-enrolment-complete.actions';

export interface IManualEnrolmentCompleteState {
  loading: boolean;
  loadingFailed: boolean;
  payment: ILessonPayment;
}

const initState: IManualEnrolmentCompleteState = {
  loading: true,
  loadingFailed: false,
  payment: null,
};

const reducer = new ReducerFactory(initState)
  .add(logout, (state, action) => {
    return initState;
  })
  .add(fetchPayment, (state, action) => {
    return {
      ...state,
      loading: true,
      loadingFailed: false,
    };
  })
  .add(fetchPaymentSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      payment: action.payload,
    };
  })
  .add(fetchPaymentFail, (state, action) => {
    return {
      ...state,
      loading: false,
      loadingFailed: true,
    };
  })
  .toReducer();

export default reducer;
