import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { changeScreen, selectParent } from '../manual-enrolment.actions';
import analytics from '@app/helpers/analytics.helper';
import { IChangeScreen } from '../manual-enrolment.types';
import { IParent } from '../../../shared-components/parent-search/parent-search.types';
import { selectState } from '../manual-enrolment.selectors';
import { PATH } from '../manual-enrolment.constants';
import { IAppState } from '@app/store';

import ParentSearchForm from '../../../shared-components/parent-search/parent-search-form.component';
import ParentSearchResult from '../../../shared-components/parent-search/parent-search-result.component';

interface IDispatchProps {
  changeScreen: (params: IChangeScreen) => void;
  selectParent: (parent: IParent) => void;
}

interface IStateProps {
  selectedParent: IParent;
}

const StepEnrolParent: React.FC<IDispatchProps & IStateProps> = (props) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Manual Enrolments - Booking Autopilot</title>
      </Helmet>
      <div className="card card-body">
        <ParentSearchForm />
      </div>

      <ParentSearchResult
        selectedParent={props.selectedParent}
        onSelectParent={props.selectParent}
        onCreateNewParent={() => props.changeScreen({ path: PATH.CREATE_PARENT })}
      />
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  changeScreen: (params) => dispatch(changeScreen(params)),
  selectParent: (parent: IParent) => {
    analytics.event('select_parent', {
      parentId: parent.id,
    });
    dispatch(selectParent(parent));
    dispatch(changeScreen({ path: PATH.PARENT_DETAIL, params: { parentId: parent.id } }));
  },
});

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    selectedParent: selectState(state).selectedParent,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepEnrolParent);
