import React from 'react';

const withValidation = <P extends {}>(C: React.ComponentClass<P>, validator?: (props: P) => boolean, FailedComponent?: React.ComponentType) => (props: P) => {
  if (validator && !validator(props)) {
    return <FailedComponent />;
  }

  return (<C {...props} />);
};

export default withValidation;
