import classNames from 'classnames';
import { ContentPosition, FlexDirectionProperty } from 'csstype';
import React from 'react';

import styles from './View.module.scss';

interface IProps {
  flexGrow?: boolean;
  flexDirection?: FlexDirectionProperty;
  alignItems?: ContentPosition;
  style?: React.CSSProperties;
  className?: string;
}

const View: React.FC<IProps> = (props) => {
  const viewStyles: React.CSSProperties = {};

  if (props.flexDirection) {
    viewStyles.flexDirection = props.flexDirection;
  }

  if (props.alignItems) {
    viewStyles.alignItems = props.alignItems;
  }

  return (
    <div
      className={classNames(styles.root, {
        [styles.flexGrow]: props.flexGrow,
      }, props.className)}
      style={{ ...props.style, ...viewStyles }}
    >
      {props.children}
    </div>
  );
};

export default View;
