import { DateType, ITerm } from '@app/types';
import Moment from 'moment-timezone';
import { IProgram } from 'pages/lesson-attendance/lesson-attendance.types';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { IChildrenPaymentTypeDetail } from '../manual-enrolment.types';

interface IProps {
  program: IProgram;
  date: DateType;
  paymentTypes: IChildrenPaymentTypeDetail[];
  onSelect: (date: DateType, paymentType: IChildrenPaymentTypeDetail) => void;
  term: ITerm;
  notAvailable: boolean;
  message?: string;
}

interface IState {
  paymentType: IChildrenPaymentTypeDetail;
}

class LessonDateRow extends Component<IProps, IState> {
  state: IState = {
    paymentType: null,
  };

  handleChangePaymentType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    const value = Number(e.target.value);

    const paymentType = this.props.paymentTypes.find(x => x.id === value);
    this.setState({
      paymentType,
    });
  }

  handleSelect = () => {
    if (!this.state.paymentType) {
      alert('Please select booking type');
      return;
    }

    this.props.onSelect(this.props.date, this.state.paymentType);
  }

  render() {
    const { date, paymentTypes, notAvailable, message } = this.props;
    return (
      <tr>
        <td className="align-middle">{Moment(date).format('DD MMMM, YYYY')}</td>
        <td className="align-middle" style={{ width: 400 }}>
          {notAvailable && (message || 'Already enroled in this class')}
          {!notAvailable && (
            <select className="form-control" value={this.state.paymentType ? this.state.paymentType.id : ''} onChange={this.handleChangePaymentType}>
              {paymentTypes.length === 0 ? <option value={0}>Loading...</option> : (
                <React.Fragment>
                  <option value={0}>Select booking type</option>
                  {paymentTypes.map((type) => (
                    <option key={String(type.id)} value={type.id}>{`${type.name} - ${type.quantity} class(es) for ${type.total}`}</option>
                  ))}
                </React.Fragment>
              )}
            </select>
          )}
        </td>
        <td className="align-middle text-right" style={{ width: 120 }}><Button disabled={notAvailable} onClick={this.handleSelect}>Select</Button></td>
      </tr>
    );
  }
}

export default LessonDateRow;
