import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import { Dispatch } from 'redux';

import history from '@app/helpers/history.helper';

import PrivateRoute from './PrivateRoute';

import { appInitialize } from 'actions/root';
import { selectInitialized } from 'selectors/root';
import { IAppState } from 'store';
import ForgotPassword from './forgot-password/forgot-password.component';
import Home from './home';
import LessonAttendance from './lesson-attendance';
import LoadingApp from './LoadingApp';
import Login from './login';
import LoginOrganization from './login-organization';
import ManageAttendance from './manage-attendance';
import ManualEnrolment from './manual-enrolment';
import ManualEnrolmentComplete from './manual-enrolment-complete';
import { PaymentFailedCallback, PaymentSuccessCallback } from './payment-callback';
import NotFound from './NotFound';
import ResetPassword from './reset-password/reset-password.component';
import Test from './Test';
import WorkshopEnrolment from './workshop-enrolment';
import WorkshopEnrolmentComplete from './workshop-enrolment-complete';

interface IDispatchProps {
  appInitialize: () => void;
}

interface IStateProps {
  initialized: boolean;
}

class Root extends Component<IDispatchProps & IStateProps> {
  componentDidMount() {
    this.props.appInitialize();
  }

  render() {
    if (!this.props.initialized) {
      return (
        <LoadingApp />
      );
    }

    return (
      <Router history={history}>
        <Switch>
          <PrivateRoute exact path="/" component={Home} />
          <PrivateRoute exact path="/lesson-attendance" component={LessonAttendance} />
          <PrivateRoute exact path="/manage-attendance/:programId" component={ManageAttendance} />
          <PrivateRoute path="/manual-enrolment/complete/:paymentId" component={ManualEnrolmentComplete} />
          <PrivateRoute path="/manual-enrolment" component={ManualEnrolment} />
          <PrivateRoute path="/workshop-enrolment/complete/:paymentId" component={WorkshopEnrolmentComplete} />
          <PrivateRoute path="/workshop-enrolment" component={WorkshopEnrolment} />
          <PrivateRoute path="/payment-callback/success" component={PaymentSuccessCallback} />
          <PrivateRoute path="/payment-callback/success/:orderId" component={PaymentSuccessCallback} />
          <PrivateRoute path="/payment-callback/failed/:orderID" component={PaymentFailedCallback} />
          <PrivateRoute path="/payment-callback/failed" component={PaymentFailedCallback} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/organisation/:organisation" component={LoginOrganization} />
          <Route exact path="/organisation" component={LoginOrganization} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route exact path="/test" component={Test} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  appInitialize: () => dispatch(appInitialize()),
});

const mapStateToProps = (state: IAppState): IStateProps => ({
  initialized: selectInitialized(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Root);
