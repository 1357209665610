import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';

interface IProps {
  children: React.ReactElement;
  title?: string;
  content: string;
  position?: 'top' | 'bottom' | 'left' | 'right';
  trigger?: 'focus' | 'hover';
}

class Tooltip extends React.Component<IProps> {
  static defaultProps = {
    position: 'top',
  };

  componentDidMount() {
    $(ReactDOM.findDOMNode(this)).popover();
  }

  render() {
    const { children, position, title, content, trigger } = this.props;
    const childrenProps = children.props;

    const tooltipAttributes: any = {
      'data-container': 'body',
      'data-toggle': 'popover',
      'data-placement': position,
      'data-original-title': title,
      'data-content': content,
    };

    if (trigger) {
      tooltipAttributes['data-trigger'] = trigger;
    }

    return React.cloneElement(this.props.children, { ...childrenProps, ...tooltipAttributes });
  }
}

export default Tooltip;
