import { changeCenter, changeFranchisor, changeTerm, fetchCentersSuccess, fetchFranchisorSuccess, fetchTermsSuccess, login, loginFail, loginSuccess, logout, refreshAuthInfo, setIsAuthenticating, tokenRefreshed } from '../actions/auth';
import { getAuthState, syncAuthToLocalStorage } from '../helpers/window-storage.helper';
import ReducerFactory from './ReducerFactory';

import analytics from '@app/helpers/analytics.helper';
import { IAppData, IAuthInfo, ICenter, IFranchisor, ITerm } from 'types';

export interface IAuthState {
  isAuthenticating: boolean;
  authenticated: boolean;
  authInfo: IAuthInfo;
  franchisor: IAppData<IFranchisor>;
  centers: IAppData<ICenter[]>;
  terms: IAppData<ITerm[]>;
  selectedCenter: ICenter;
  selectedTerm: ITerm;
}

const initState: IAuthState = {
  isAuthenticating: false,
  authenticated: false,
  authInfo: null,
  franchisor: null,
  centers: null,
  terms: null,
  selectedCenter: null,
  selectedTerm: null,
};

const initAuthState = getAuthState(initState);

const reducer = new ReducerFactory(initAuthState)
  .add(login, (state, action) => {
    return {
      ...state,
      isAuthenticating: true,
    };
  })
  .add(loginSuccess, (state, action) => {
    syncAuthToLocalStorage(action.payload);
    return {
      ...state,
      authenticated: true,
      authInfo: action.payload,
    };
  })
  .add(setIsAuthenticating, (state, action) => {
    return {
      ...state,
      isAuthenticating: action.payload,
    };
  })
  .add(tokenRefreshed, (state, action) => {
    syncAuthToLocalStorage(action.payload);
    return {
      ...state,
      authenticated: true,
      authInfo: action.payload,
    };
  })
  .add(refreshAuthInfo, (state, action) => {
    // one way -> local storage to redux
    return {
      ...state,
      authenticated: Boolean(action.payload),
      authInfo: action.payload,
    };
  })
  .add(loginFail, (state, action) => {
    return {
      ...state,
      isAuthenticating: false,
      authenticated: false,
    };
  })
  .add(logout, (state, action) => {
    syncAuthToLocalStorage(null);
    return {
      ...state,
      authenticated: false,
      authInfo: null,
      centers: null,
      terms: null,
    };
  })
  .add(fetchFranchisorSuccess, (state, action) => {
    return {
      ...state,
      franchisor: action.payload,
    };
  })
  .add(changeFranchisor, (state, action) => {
    return {
      ...state,
      franchisor: null,
    };
  })
  .add(fetchCentersSuccess, (state, action) => {
    let selectedCenter: ICenter = state.selectedCenter;
    const centers = action.payload.data;

    if (!selectedCenter || centers.findIndex(x => x.id === selectedCenter.id) === -1) {
      selectedCenter = centers[0];
    }

    return {
      ...state,
      centers: action.payload,
      selectedCenter,
    };
  })
  .add(fetchTermsSuccess, (state, action) => {
    let selectedTerm: ITerm = state.selectedTerm;
    const terms = action.payload.data;

    if (!selectedTerm || terms.findIndex(x => x.id === selectedTerm.id) === -1) {
      selectedTerm = terms[0];
    }

    return {
      ...state,
      terms: action.payload,
      selectedTerm,
    };
  })
  .add(changeCenter, (state, action) => {
    analytics.event('filter_centre', {
      centre: action.payload,
    });
    const selectedCenter = state.centers.data.find(x => x.id === action.payload);
    return {
      ...state,
      selectedCenter,
    };
  })
  .add(changeTerm, (state, action) => {
    analytics.event('filter_term', {
      term: action.payload,
    });
    const selectedTerm = state.terms.data.find(x => x.id === action.payload);
    return {
      ...state,
      selectedTerm,
    };
  })
  .toReducer();

export default reducer;
