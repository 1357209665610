import analytics from '@app/helpers/analytics.helper';
import { selectCenters, selectedCenter, selectedTerm, selectTerms } from '@app/selectors/auth';
import { IAppState } from '@app/store';
import { ICenter, ITerm, IWeeklyProgram } from '@app/types';
import classNames from 'classnames';
import Moment from 'moment-timezone';
import { IProgram } from 'pages/lesson-attendance/lesson-attendance.types';
import React from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { changeCenter, changeTerm, fetchWeeklyPrograms, ignoreChildAgeWarning, selectProgram, setChildAgeError } from '../manual-enrolment.actions';
import { selectState } from '../manual-enrolment.selectors';

interface IDispatchProps {
  changeCenter: (id: number) => void;
  changeTerm: (id: number) => void;
  fetchPrograms: () => void;
  selectProgram: (program: IWeeklyProgram) => void;
  onCloseChildAgeError: () => void;
  ignoreChildAgeWarning: () => void;
}

interface IStateProps {
  centers: ICenter[];
  terms: ITerm[];
  selectedCenter: ICenter;
  selectedTerm: ITerm;
  weeklyProgramsLoading: boolean;
  weeklyPrograms: IWeeklyProgram[];
  selectedProgram: IWeeklyProgram;
  programAvailableState?: boolean;
  childAgeError: string;
}

const getWeekdays = () => {
  const today = Moment();
  const weekDays = [
    { weeknumber: 1, date: today.weekday(1).toDate() },
    { weeknumber: 2, date: today.weekday(2).toDate() },
    { weeknumber: 3, date: today.weekday(3).toDate() },
    { weeknumber: 4, date: today.weekday(4).toDate() },
    { weeknumber: 5, date: today.weekday(5).toDate() },
    { weeknumber: 6, date: today.weekday(6).toDate() },
    { weeknumber: 7, date: today.weekday(7).toDate() },
  ];

  return weekDays;
};

const transformPrograms = (programs: IWeeklyProgram[]) => {
  const weekdays = getWeekdays();

  return weekdays.map(x => ({
    ...x,
    programs: programs.filter(y => y.weekday === x.weeknumber && !y.isDeleted).map(y => ({
      ...y,
      startAt: Moment(y.startTime, 'HH:mm:ss').weekday(x.weeknumber).toDate(),
      endAt: Moment(y.endTime, 'HH:mm:ss').weekday(x.weeknumber).toDate(),
    })).sort((a, b) => a.startAt.getTime() - b.startAt.getTime()) as IProgram[],
  }));
};

const getPerc = (count: number, total: number) => {
  if (!total) {
    return 0;
  }

  return Math.ceil((count * 100) / total);
};

class ChooseClass extends React.Component<IDispatchProps & IStateProps> {
  componentDidMount() {
    this.props.fetchPrograms();
  }

  handleChangeCenter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();

    const value = e.target.value;

    this.props.changeCenter(Number(value));
  }

  handleChangeTerm = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    const value = e.target.value;
    this.props.changeTerm(Number(value));
  }

  renderPrograms() {
    const weeklyPrograms = transformPrograms(this.props.weeklyPrograms);
    const { selectedProgram, programAvailableState } = this.props;

    const showSelected = programAvailableState !== null && Boolean(selectedProgram);

    return (
      <div className="card card-body mt-3" style={{ overflowY: 'auto' }}>
        <h5 className="text-uppercase text-muted">My classes</h5>
        <div className="d-flex flex-row justify-content-between ml-n1 mr-n1m mt-1">
          {weeklyPrograms.map((item, index) => {
            return (
              <div key={String(item.weeknumber)} className="d-flex flex-column justify-content-start bg-light-lighten flex-grow-1 ml-1 mr-1 rounded" style={{ minWidth: 180 }}>
                <div className="d-flex flex-row justify-content-center p-1">
                  <h6 className="text-uppercase text-muted">{Moment(item.date).format('ddd')}</h6>
                </div>
                <hr className="m-0" />
                {item.programs.map((program) => {
                  const fillPerc = getPerc(program.attendingChildrenCount, program.maxChildrenRest);
                  return (
                    <button
                      key={String(program.id)}
                      className={classNames(
                        `m-1 mt-2 card card-body p-2 flex-grow-0 text-secondary d-flex flex-row position-relative border`,
                        (!showSelected || !selectedProgram || program.id !== selectedProgram.id) ? 'border-white' : programAvailableState ? 'border-success' : 'border-secondary',
                      )}
                      style={{ backgroundColor: fillPerc >= 100 ? '#f2dede' : fillPerc >= 50 ? '#fcf8e3' : '' }}
                      onClick={() => this.props.selectProgram(program)}
                    >
                      <div className="text-left flex-grow-1">
                        <h4 className="font-weight-normal mt-0 mb-1">{Moment(program.startAt).format('HH:mm')} - {Moment(program.endAt).format('HH:mm')}</h4>
                        <small>{program.grade.name}</small>
                        <div className="mt-1">
                          <span className="badge badge-success">{program.attendingChildrenCount}/{program.maxChildrenRest || 0} Paid</span>&nbsp;
                        <span className="badge badge-danger ml-1">{program.attendingTrialChildrenCount}/{program.maxTrialChildrenRest || 0} Trial</span>&nbsp;
                      </div>
                      </div>
                      {showSelected && Boolean(selectedProgram) && program.id === selectedProgram.id && (
                        <div
                          className={classNames(
                            'text-white rounded-circle d-flex align-items-center justify-content-center position-absolute',
                            programAvailableState ? 'bg-success' : 'bg-secondary',
                          )}
                          style={{ width: 24, height: 24, bottom: -12, left: 'calc(50% - 12px)' }}
                        >
                          {programAvailableState ? <i className="h4 mdi mdi-check m-0"></i> : <i className="h4 mdi mdi-information-outline m-0"></i>}
                        </div>
                      )}
                    </button >
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  renderLoading() {
    return (
      <div className="card card-body d-flex align-items-center justify-content-center mt-3" style={{ minHeight: 360 }}>
        <Spinner animation="border" />
      </div>
    );
  }

  renderChildAgeError() {
    const { childAgeError, onCloseChildAgeError } = this.props;
    return (
      <Modal show={Boolean(childAgeError)} onHide={onCloseChildAgeError} centered className="zoom">
        <Modal.Header className="modal-colored-header bg-danger" closeButton>
          <Modal.Title className="mt-0 mb-0">Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div dangerouslySetInnerHTML={{ __html: childAgeError }} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={onCloseChildAgeError}>
            Close
          </Button>
          <Button variant="warning" onClick={this.props.ignoreChildAgeWarning}>
            Ignore warning
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  render() {
    // tslint:disable-next-line:no-shadowed-variable
    const { selectedCenter, selectedTerm, centers, terms, weeklyProgramsLoading } = this.props;

    return (
      <React.Fragment>
        <Helmet>
          <title>Select class - Manual Enrolments - Booking Autopilot</title>
        </Helmet>
        <div className="d-flex flex-row align-items-center">
          <div className="d-flex flex-row align-items-center flex-grow-1 mt-3 mb-2">
            <h4 className="mr-1 flex-grow-1">Choose Class</h4>
            <div className="d-flex flex-row">
              <select className="form-control form-control-sm" value={selectedCenter.id} onChange={this.handleChangeCenter}>
                {centers.map(center => <option value={center.id} key={String(center.id)}>{center.name}</option>)}
              </select>
              <select className="form-control form-control-sm ml-1" value={selectedTerm.id} onChange={this.handleChangeTerm}>
                {terms.map(term => <option value={term.id} key={String(term.id)}>{term.name}</option>)}
              </select>
            </div>
          </div>
        </div>

        {weeklyProgramsLoading && this.renderLoading()}
        {!weeklyProgramsLoading && this.renderPrograms()}
        {this.renderChildAgeError()}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  fetchPrograms: () => dispatch(fetchWeeklyPrograms()),
  changeCenter: (id: number) => dispatch(changeCenter(id)),
  changeTerm: (id: number) => dispatch(changeTerm(id)),
  selectProgram: (program) => {
    analytics.event('select_class', {
      programId: program.id,
    });
    dispatch(selectProgram(program));
  },
  onCloseChildAgeError: () => dispatch(setChildAgeError('')),
  ignoreChildAgeWarning: () => {
    dispatch(setChildAgeError(''));
    dispatch(ignoreChildAgeWarning());
  },
});

const mapStateToProps = (state: IAppState): IStateProps => {
  const screenState = selectState(state).screens.chooseClass;
  return {
    centers: selectCenters(state),
    terms: selectTerms(state),
    selectedCenter: selectedCenter(state),
    selectedTerm: selectedTerm(state),
    weeklyProgramsLoading: screenState.weeklyProgramsLoading,
    weeklyPrograms: screenState.weeklyPrograms,
    selectedProgram: selectState(state).selectedProgram,
    programAvailableState: selectState(state).programAvailableState,
    childAgeError: screenState.childAgeError,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseClass);
