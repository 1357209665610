import { IAppState } from 'store';
import { IParentSummary, IChild, IEnrolment } from './parent-details.types';

export const selectState = (state: IAppState) => state.screen.parentDetails;

export const getSelectedParentSummary = (state: IAppState) => {
    const defaultParentSummary: IParentSummary = {
        parentId: 0,
        totalRevenue: 0,
        numberOfSales: 0,
        bookedMakeupCurrentTerm: 0,
        bookedTrial: 0,
        firstTermAttended: null,
        lastTermAttended: null,
    };

    const selectedParent = selectState(state).selectedParent;

    if (!selectedParent) {
        return defaultParentSummary;
    }

    const selectedParentSummary = selectState(state).selectedParentSummary;

    if (!selectedParentSummary) {
        return defaultParentSummary;
    }

    if (selectedParentSummary.parentId !== selectedParent.id) {
        return defaultParentSummary;
    }

    return selectedParentSummary;
};


export const getParentChilds = (state: IAppState): IChild[] => {
    const selectedParent = selectState(state).selectedParent;

    if (!selectedParent) {
        return [];
    }

    return selectState(state).childs.filter(x => x.parentId === selectedParent.id);
};

export const getCurrentEnrolments = (state: IAppState): IEnrolment[] => {
    const selectedParent = selectState(state).selectedParent;

    if (!selectedParent) {
        return [];
    }

    return selectState(state).currentEnrolments.filter(x => x.parent.id === selectedParent.id);
};