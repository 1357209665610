import { selectLookup } from '@app/selectors/root';
import { IAppState } from '@app/store';
import { ILookup } from '@app/types';
import classNames from 'classnames';
import { Button, ButtonGroup, Card } from 'components';
import { Formik, FormikErrors, FormikTouched } from 'formik';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IFormValueCreateChild } from '../../shared-components/create-parent/create-parent.types';
import * as Yup from 'yup';
import { IParent } from '../../shared-components/parent-search/parent-search.types';

const initFormValue: IFormValueCreateChild = {
  parentId: 0,
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  genderTypeId: 1,
};

const formValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Please enter firstname'),
  lastName: Yup.string()
    .required('Please enter lastname'),
  dateOfBirth: Yup.date()
    .required('Please enter birth date'),
});

interface IProps {
  onCancel: () => void;
  onCreateChild: (formValue: IFormValueCreateChild) => void;
  isSubmitting: boolean;
  selectedParent: IParent;
}

interface IDispatchProps {
}

interface IStateProps {
  genders: ILookup[];
}

const shouldControlShowError = <T extends {}>(errors: FormikErrors<T>, touched: FormikTouched<T>, submitCount: number, fieldName: keyof T) => {
  return Boolean(errors[fieldName]) && (submitCount > 0 || touched[fieldName]);
};

const ControlError: React.FC<{ errorMessage: string }> = ({ errorMessage }) => (
  <span className="invalid-feedback">{errorMessage}</span>
);

class CreateChild extends Component<IProps & IDispatchProps & IStateProps> {
  render() {
    const { genders, isSubmitting } = this.props;

    return (
      <Formik
        initialValues={{ ...initFormValue }}
        onSubmit={(values) => this.props.onCreateChild(values)}
        validationSchema={formValidationSchema}
      >
        {({
          values,
          submitCount,
          errors,
          isValid,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          touched,

        }) => (
          <form onSubmit={handleSubmit}>
            <Card>
              <Card.Body>
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <label>First Name</label>
                    <input
                      className={classNames('form-control', { 'is-invalid': shouldControlShowError(errors, touched, submitCount, 'firstName') })}
                      name="firstName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.firstName}
                    />
                    <ControlError errorMessage={errors.firstName} />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Last Name</label>
                    <input
                      className={classNames('form-control', { 'is-invalid': shouldControlShowError(errors, touched, submitCount, 'lastName') })}
                      name="lastName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.lastName}
                    />
                    <ControlError errorMessage={errors.lastName} />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Child Birth Date</label>
                    <input
                      className={classNames('form-control', { 'is-invalid': shouldControlShowError(errors, touched, submitCount, 'dateOfBirth') })}
                      type="date"
                      name="dateOfBirth"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.dateOfBirth as string}
                    />
                    <ControlError errorMessage={errors.dateOfBirth} />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Gender</label>
                    <ButtonGroup className="d-block">
                      {genders.map((gender) => (
                        <Button
                          key={String(gender.id)}
                          onClick={() => setFieldValue('genderTypeId', gender.id)}
                          variant={values.genderTypeId === gender.id ? 'primary' : 'light'}
                        >
                          {gender.displayName}
                        </Button>
                      ))}
                    </ButtonGroup>
                  </div>
                </div>
              </Card.Body>
            </Card>

            <div className="mt-2 mb-3 text-right">
              <Button
                variant="light"
                className="ml-2"
                disabled={isSubmitting}
                onClick={this.props.onCancel}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="primary"
                className="ml-2"
                disabled={isSubmitting || (!isValid && submitCount > 0)}
              >
                {isSubmitting && (
                  <>
                    <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                    Creating...
                  </>
                )}
                {!isSubmitting && 'Create & Enrol'}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
});

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    genders: selectLookup(state, 'genderTypes')
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateChild);
