import { IAppState } from '@app/store';
import { Formik } from 'formik';
import React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as yup from 'yup';
import { searchParent } from './parent-search.actions';
import { selectState } from './parent-search.selectors';
import { IFormValueParentSearch } from './parent-search.types';

const formValidationSchema = yup.object().shape<IFormValueParentSearch>({
  fullName: yup.string().min(2),
  phoneNumber: yup.string().min(4),
  child: yup.string().min(2),
}).test('at-least-one-enter', 'Please enter search criteria', (value: IFormValueParentSearch) => {
  return !!(value.fullName || value.phoneNumber || value.child);
});

interface IStateProps {
  initialValues: IFormValueParentSearch;
  loading: boolean;
}

interface IDispatchProps {
  onSubmit: (values: IFormValueParentSearch) => void;
}

const ParentSearchForm: React.FC<IStateProps & IDispatchProps> = (props) => {
  const { loading } = props;
  const initialValues: IFormValueParentSearch = { ...props.initialValues };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formValidationSchema}
      isInitialValid={(f: any) => {
        return formValidationSchema.isValidSync(f.initialValues);
      }}
      onSubmit={(values) => {
        props.onSubmit(values);
      }}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        isValid,
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <div className="row">
            <div className="col-md-4 mb-3">
              <label>Name</label>
              <input className="form-control"
                name="fullName"
                onChange={handleChange}
                value={values.fullName}
              />
            </div>
            <div className="col-md-4 mb-3">
              <label>Phone Number</label>
              <input
                className="form-control"
                name="phoneNumber"
                onChange={handleChange}
                value={values.phoneNumber}
              />
            </div>
            <div className="col-md-4 mb-3">
              <label>Child name</label>
              <input
                className="form-control"
                name="child"
                onChange={handleChange}
                value={values.child}
              />
            </div>
            <div className="col-md-12 mb-3">
              <label>&nbsp;</label>
              <div className="text-right">
                <Button
                  type="submit"
                  variant="primary"
                  disabled={loading || !isValid}
                >
                  {loading && (
                    <>
                      <span className="spinner-border spinner-border-sm mr-1"></span>
                      Searching...
                    </>
                  )}
                  {!loading && 'Search'}
                </Button>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  onSubmit: (values) => dispatch(searchParent(values)),
});

const mapStateToProps = (state: IAppState): IStateProps => {
  const screenState = selectState(state);
  return {
    initialValues: screenState.formValue,
    loading: screenState.loading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ParentSearchForm);
