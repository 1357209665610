import { put, takeLatest } from 'redux-saga/effects';

import { fetchFranchisorSuccess } from 'actions/auth';
import { appInitialize, appInitializeSuccess, changeLanguage, fetchLanguagesSuccess, fetchLookupsSuccess, reloadLanguages, setSelectedLanguage } from 'actions/root';
import Config from 'config';
import { getExpirableData, getIsDataExpired } from 'helpers/common.helpers';
import httpHelpers from 'helpers/http.helpers';
import { store } from 'store';
import { IAppLookups, ILanguage } from 'types';

import analytics from '@app/helpers/analytics.helper';
import { setCurrencyOptions } from '@app/helpers/number.helpers';
import { fetchCentersSaga, fetchTermsSaga } from 'effects/auth.effects';

function* appInitializeSaga() {
  try {
    yield httpHelpers.refreshToken();

    const stateData = store.getState();

    if (stateData.auth.franchisor && getIsDataExpired(stateData.auth.franchisor)) {
      const franchisor = yield httpHelpers.get(`${Config.SERVER_URL}/api/franchisors?domain=${encodeURIComponent(stateData.auth.franchisor.data.domain)}`);
      yield put(fetchFranchisorSuccess(getExpirableData({
        ...franchisor,
        domain: stateData.auth.franchisor.data.domain,
      }, 1, 'h')));
      setCurrencyOptions(franchisor.currency);
    } else if (stateData.auth.franchisor) {
      setCurrencyOptions(stateData.auth.franchisor.data.currency);
    }

    if (getIsDataExpired(stateData.root.languages)) {
      const languages: ILanguage[] = yield httpHelpers.get<ILanguage[]>(`${Config.SERVER_URL}/api/languages`);
      yield put(fetchLanguagesSuccess(getExpirableData(languages, 1, 'h')));

      const selectedLanguage = store.getState().root.selectedLanguage;
      const defaultLang = languages.find(x => x.isDefault) || languages[0];
      if (!selectedLanguage || !languages.find(x => x.id === selectedLanguage.id)) {
        yield put(setSelectedLanguage(defaultLang));
      }
    }

    if (getIsDataExpired(stateData.root.lookups)) {
      const lookups = yield httpHelpers.get<IAppLookups>(`${Config.SERVER_URL}/api/lookups`);
      yield put(fetchLookupsSuccess(getExpirableData(lookups, 1, 'h')));
    }

    if (stateData.auth.authenticated) {
      analytics.setUser({ userId: stateData.auth.authInfo.user.id });
      yield fetchTermsSaga();
      yield fetchCentersSaga();
    }

    yield put(appInitializeSuccess());
  } catch (err) {
    //
  }
}

function* reloadLanguagesSaga() {
  try {
    const languages: ILanguage[] = yield httpHelpers.get<ILanguage[]>(`${Config.SERVER_URL}/api/languages`);
    yield put(fetchLanguagesSuccess(getExpirableData(languages, 1, 'h')));

    const selectedLanguage = store.getState().root.selectedLanguage;
    const defaultLang = languages.find(x => x.isDefault) || languages[0];

    if (!selectedLanguage || !languages.find(x => x.id === selectedLanguage.id)) {
      yield put(changeLanguage(defaultLang));
    }

  } catch (err) {
    //
  }
}

function* changeLanguageSaga() {
  try {
    const lookups = yield httpHelpers.get<IAppLookups>(`${Config.SERVER_URL}/api/lookups`);
    yield put(fetchLookupsSuccess(getExpirableData(lookups, 1, 'h')));
  } catch (err) {
    //
  }
}

export default [
  takeLatest(appInitialize.toString(), appInitializeSaga),
  takeLatest(reloadLanguages.toString(), reloadLanguagesSaga),
  takeLatest(changeLanguage.toString(), changeLanguageSaga),
];
