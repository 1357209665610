import React, { Component, FormEvent } from 'react';

import Config from '@app/config';
import { SVGImage } from 'components';
import { Link, Redirect } from 'react-router-dom';
import { IDispatchProps, IStateProps } from './types';

interface IState {
  username: string;
  password: string;
  rememberMe: boolean;
}

class LoginComponent extends Component<IDispatchProps & IStateProps, IState> {
  state: IState = {
    username: '',
    password: '',
    rememberMe: true,
  };

  handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    this.props.login({
      username: this.state.username,
      password: this.state.password,
    });
  }

  handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      username: e.target.value,
    });
  }

  handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      password: e.target.value,
    });
  }

  handleChangeRememberMe = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      rememberMe: e.target.checked,
    });
  }

  render() {
    const { isAuthenticating, franchisor } = this.props;
    const { username: email, password, rememberMe } = this.state;

    if (!franchisor) {
      return (
        <Redirect to="organisation" />
      );
    }

    return (
      <div className="authentication-bg">
        <div className="account-pages pt-5 pb-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5">
                <div className="card">
                  <div className="card-header pt-4 pb-4 text-center bg-primary">
                    <a href="/">
                      <span>
                        <SVGImage src={require('assets/images/logo.svg')} alt="" id="side-main-logo" />
                      </span>
                    </a>
                  </div>

                  <div className="card-body p-4">

                    <div className="text-center w-75 m-auto">
                      <h4 className="text-dark-50 text-center mt-0 font-weight-bold">Sign In</h4>
                      <p className="text-muted mb-4">Enter your email address and password to access admin panel.</p>
                    </div>

                    <form onSubmit={this.handleSubmit}>

                      <div className="form-group">
                        <label htmlFor="emailaddress">Username</label>
                        <input
                          className="form-control"
                          type="text"
                          required
                          id="emailaddress"
                          tabIndex={1}
                          autoFocus
                          placeholder="Enter your email"
                          value={email}
                          onChange={this.handleChangeEmail}
                        />
                      </div>

                      <div className="form-group">
                        <Link to="/forgot-password" className="text-muted float-right"><small>Forgot your password?</small></Link>
                        <label htmlFor="password">Password</label>
                        <input
                          className="form-control"
                          type="password"
                          required
                          id="password"
                          tabIndex={2}
                          placeholder="Enter your password"
                          value={password}
                          onChange={this.handleChangePassword}
                        />
                      </div>

                      <div className="form-group mb-3">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="checkbox-signin"
                            tabIndex={3}
                            checked={rememberMe}
                            onChange={this.handleChangeRememberMe}
                          />
                          <label className="custom-control-label" htmlFor="checkbox-signin">Remember me</label>
                        </div>
                      </div>

                      <div className="form-group mb-0 text-center d-flex align-items-center flex-column">
                        <button tabIndex={4} className="btn btn-primary d-flex flex-row align-items-center" type="submit" disabled={isAuthenticating}>
                          {!isAuthenticating && 'Log In'}
                          {isAuthenticating && (
                            <>
                              <span>Logging in...&nbsp;</span>
                              <div className="spinner-border spinner-border-sm"></div>
                            </>
                          )}
                        </button>
                      </div>

                      <div className="mt-3">
                        <div className="text-muted" style={{ color: '#adb5bd' }}><small>Organisation: {franchisor.domain}</small></div>
                        <button type="button" className="btn btn-link" style={{ padding: 0 }} onClick={this.props.changeFranchisor}>Change organisation</button>
                      </div>

                    </form>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-12 text-center">
                    <p className="text-muted">Don't have an account? <a href="pages-register.html" className="text-muted ml-1"><b>Sign Up</b></a></p>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>

        <footer className="footer footer-alt">
          {Config.FOOTER_COPYRIGHT}
          <br />
          {Config.VERSION}
        </footer>
      </div>
    );
  }
}

export default LoginComponent;
