import { EventIcon } from '@app/assets/images/icons';
import { ICenter, IChildLesson, IWeeklyProgram } from '@app/types';
import classNames from 'classnames';
import Moment from 'moment-timezone';
import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router';
import { IProgram, ViewMode } from './lesson-attendance.types';
import ProgramAttendace from './program-attendance.component';
import ProgramCard from './program-card.component';

export interface IDispatchProps {
  fetchPrograms: () => void;
  changeViewMode: (viewMode: ViewMode) => void;
  setSelectedProgram: (program: IWeeklyProgram) => void;
  onToggleAttendance: (program: IChildLesson) => void;
  changeCenter: (id: number) => void;
}

export interface IStateProps {
  weeklyProgramsLoading: boolean;
  weeklyPrograms: IWeeklyProgram[];
  viewMode: ViewMode;
  selectedProgram: IWeeklyProgram;
  attendanceLoading: boolean;
  attendance: IChildLesson[];
  centers: ICenter[];
  selectedCenter: ICenter;
}

const getWeekdays = () => {
  const today = Moment();
  const weekDays = [
    { weeknumber: 1, date: today.weekday(1).toDate() },
    { weeknumber: 2, date: today.weekday(2).toDate() },
    { weeknumber: 3, date: today.weekday(3).toDate() },
    { weeknumber: 4, date: today.weekday(4).toDate() },
    { weeknumber: 5, date: today.weekday(5).toDate() },
    { weeknumber: 6, date: today.weekday(6).toDate() },
    { weeknumber: 7, date: today.weekday(7).toDate() },
  ];

  return weekDays;
};

const transformPrograms = (programs: IWeeklyProgram[]) => {
  const weekdays = getWeekdays();

  return weekdays.map(x => ({
    ...x,
    programs: programs.filter(y => y.weekday === x.weeknumber).map(y => ({
      ...y,
      startAt: Moment(y.startTime, 'HH:mm:ss').weekday(x.weeknumber).toDate(),
      endAt: Moment(y.endTime, 'HH:mm:ss').weekday(x.weeknumber).toDate(),
    })).sort((a, b) => a.startAt.getTime() - b.startAt.getTime()) as IProgram[],
  }));
};

class LessionAttendanceComponent extends Component<IDispatchProps & IStateProps & RouteComponentProps> {
  componentDidMount() {
    this.props.fetchPrograms();
  }

  handleChangeCenter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();

    const value = e.target.value;

    this.props.changeCenter(Number(value));
  }

  handleOpenAttenancePage = (program: IWeeklyProgram) => {
    this.props.history.push(`/manage-attendance/${program.id}`);
  }

  renderDaily() {
    const todayPrgrams = transformPrograms(this.props.weeklyPrograms).find(x => x.weeknumber === (Moment().weekday() + 6) % 7 + 1);
    const { selectedProgram, attendanceLoading } = this.props;

    const attendance = this.props.attendance.filter(x => x.lessonDate === Moment().format('YYYY-MM-DDT00:00:00'));

    if (!todayPrgrams.programs.length) {
      return (
        <div className="card mt-3 d-flex flex-row" style={{ height: 480 }}>
          <div className="card-body d-flex flex-column align-items-center justify-content-center">
            <EventIcon fill="#e4ebf0" className="mb-4" />
            <h5 className="text-uppercase font-weight-normal">No class for today.</h5>
            <small>Enjoy your day or Add a new class</small>
          </div>
        </div>
      );
    }

    return (
      <div className="card mt-3 d-flex flex-row" style={{ height: 480 }}>
        <div className="card-body bg-light-lighten d-flex flex-column" style={{ width: 360, maxWidth: 360 }}>
          <h6 className="text-uppercase text-muted">My classes</h6>
          <div className="d-flex flex-row justify-content-between ml-n1 mr-n1 overflow-auto">
            <div className="d-flex flex-column justify-content-start flex-grow-1 ml-1 mr-1 rounded" style={{ minWidth: 180 }}>
              {todayPrgrams.programs.map((program) => (
                <ProgramCard
                  key={String(program.id)}
                  program={program}
                  showRightArrow={selectedProgram && selectedProgram.id === program.id}
                  onClick={() => this.props.setSelectedProgram(program)}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="d-flex flex-grow-1 overflow-auto">
          {Boolean(selectedProgram) && (
            <ProgramAttendace
              isLoading={attendanceLoading}
              attendance={attendance}
              onToggleAttendance={this.props.onToggleAttendance}
            />
          )}
        </div>
      </div>
    );
  }

  renderWeekly() {
    const weeklyPrograms = transformPrograms(this.props.weeklyPrograms);

    return (
      <div className="card card-body mt-3" style={{ overflowY: 'auto' }}>
        <h5 className="text-uppercase text-muted">My classes</h5>
        <div className="d-flex flex-row justify-content-between ml-n1 mr-n1">
          {weeklyPrograms.map((item, index) => {
            return (
              <div key={String(item.weeknumber)} className="d-flex flex-column justify-content-start bg-light-lighten flex-grow-1 ml-1 mr-1 rounded" style={{ minWidth: 180 }}>
                <div className="d-flex flex-row justify-content-between p-1">
                  <h6 className="text-uppercase text-muted">{Moment(item.date).format('ddd')}</h6>
                  <h6 className="text-uppercase text-muted">{Moment(item.date).format('DD MMM')}</h6>
                </div>
                <hr className="m-0" />
                {item.programs.map((program) => (
                  <ProgramCard
                    key={String(program.id)}
                    program={program}
                    onClick={() => this.handleOpenAttenancePage(program)}
                  />
                ))}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  renderLoading() {
    return (
      <div className="card card-body d-flex align-items-center justify-content-center mt-3" style={{ minHeight: 360 }}>
        <Spinner animation="border" />
      </div>
    );
  }

  render() {
    const { viewMode, changeViewMode, centers, selectedCenter, weeklyProgramsLoading } = this.props;

    return (
      <div className="container-fluid pt-2">
        <h5 className="text-uppercase text-muted">Today</h5>
        <div className="d-flex flex-row align-items-center">
          <div className="d-flex flex-row align-items-center flex-grow-1">
            <h4 className="mr-1">{Moment().format('DD MMM YYYY')}</h4>
            <button className={classNames('ml-2 btn btn-sm', viewMode !== 'daily' ? 'btn-light text-muted' : 'btn-primary')} onClick={() => changeViewMode('daily')}>Daily</button>
            <button className={classNames('ml-2 btn btn-sm', viewMode !== 'weekly' ? 'btn-light text-muted' : 'btn-primary')} onClick={() => changeViewMode('weekly')}>Weekly</button>
          </div>
          <div className="d-flex flex-row align-items-center">
            <select className="form-control form-control-sm" value={selectedCenter.id} onChange={this.handleChangeCenter}>
              {centers.map(center => <option value={center.id} key={String(center.id)}>{center.name}</option>)}
            </select>
            <button className="ml-2 btn btn-sm btn-primary" onClick={this.props.fetchPrograms}><i className="mdi mdi-reload h4 m-0"></i></button>
            <button className="ml-2 btn btn-sm btn-primary"><i className="mdi mdi-filter-variant h4 m-0"></i></button>
          </div>
        </div>
        {weeklyProgramsLoading && this.renderLoading()}
        {!weeklyProgramsLoading && viewMode === 'daily' && this.renderDaily()}
        {!weeklyProgramsLoading && viewMode === 'weekly' && this.renderWeekly()}
      </div>
    );
  }
}

export default LessionAttendanceComponent;
