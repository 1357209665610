import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { IGlobalMessageBox } from 'types/common';

export interface IStateProps {
  data: IGlobalMessageBox;
}

export interface IDispatchProps {
  onClose: () => void;
}

const MessageBox: React.FC<IDispatchProps & IStateProps> = (props) => {
  const { data, onClose } = props;

  let headerClass = '';

  if (data) {
    switch (data.type) {
      case 'error':
        headerClass = 'modal-colored-header bg-danger';
        break;
      case 'warning':
        headerClass = 'modal-colored-header bg-warning';
        break;
      case 'info':
        headerClass = 'modal-colored-header bg-info';
        break;
      case 'success':
        headerClass = 'modal-colored-header bg-success';
        break;
    }
  }

  return (
    <Modal show={Boolean(data)} onHide={onClose} centered className="zoom">
      <Modal.Header className={headerClass} closeButton>
        <Modal.Title className="mt-0 mb-0">{Boolean(data) && data.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {Boolean(data) && (
          !data.isHTML ? data.message : <div dangerouslySetInnerHTML={{ __html: data.message }} />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MessageBox;
