import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Config from './config';
import Sentry from './helpers/sentry.helper'

if (!Config.DEBUG && Config.SENTRY_URL) {
    Sentry.init({dsn: Config.SENTRY_URL});
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
