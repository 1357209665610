import { createAction } from 'redux-actions';
import { IAppData, IAppLookups, IGlobalMessageBox } from 'types';
import { ILanguage } from 'types/ILanguage';

interface IAppInit {
  version: number;
}

// export const INIT = '@root/INIT';
export const init = createAction<IAppInit>('@root/INIT');
export const changeDataExpireBreakpoint = createAction<number>('@root/CHANGE_DATA_EXPIRE_BREAKPOINT');
export const appInitialize = createAction('@root/APP_INITIALIZE');
export const appInitializeSuccess = createAction('@root/APP_INITIALIZE_SUCCESS');

export const setGlobalMessageBox = createAction<IGlobalMessageBox>('@root/SET_GLOBAL_MESSAGE_BOX');
export const closeGlobalMessageBox = createAction('@root/CLOSE_GLOBAL_MESSAGE_BOX');

export const fetchLanguages = createAction('@root/FETCH_LANGUAGES');
export const fetchLanguagesSuccess = createAction<IAppData<ILanguage[]>>('@root/FETCH_LANGUAGES_SUCCESS');
export const reloadLanguages = createAction('@root/REFRESH_LANGUAGES');

export const fetchLookups = createAction('@root/FETCH_LOOKUPS');
export const fetchLookupsSuccess = createAction<IAppData<IAppLookups>>('@root/FETCH_LOOKUPS_SUCCESS');

export const changeLanguage = createAction<ILanguage>('@root/CHANGE_LANGUAGE');
export const setSelectedLanguage = createAction<ILanguage>('@root/SET_SELECTED_LANGUAGE');
